import React, { useRef, useEffect } from "react";
import { useControls } from "leva";
import { PointLightHelper } from "three";

const Lights = () => {
  const planeSize = 100;
  const defaultLightIntensity = 2000;
  const defaultLightHeight = 50;

  //@ts-ignore
  // leva controls
  // leva controls
  const { lightColor, lightIntensity, lightHeight, middleLightPosition, showLightHelpers } = {
        lightColor: "#ffffff",
        lightIntensity: defaultLightIntensity,
        lightHeight: defaultLightHeight,
        middleLightPosition: { x: 70, y: 20, z: 50 },
        showLightHelpers: false,
      };

  // Creating an array of refs for the lights
  const lightRefs = useRef([]);
  lightRefs.current = [];

  // Add lights to the refs array
  //@ts-ignore
  const addToRefs = (el) => {
    //@ts-ignore
    if (el && !lightRefs.current.includes(el)) {
      //@ts-ignore
      lightRefs.current.push(el);
    }
  };

  useEffect(() => {
    if (showLightHelpers) {
      lightRefs.current.forEach((light) => {
        const helper = new PointLightHelper(light, 1);
        //@ts-ignore
        light.parent.add(helper);
        return () => {
          if (helper) {
            //@ts-ignore
            light.parent.remove(helper);
            helper.dispose();
          }
        };
      });
    }
  }, [showLightHelpers]);

  return (
    <>
      <ambientLight intensity={0.6} />
      {/* Four Point Lights at each corner */}
      {[0, planeSize].map((x) => {
        return [0, planeSize].map((z) => (
          <pointLight
            ref={addToRefs}
            key={`corner-${x}-${z}`}
            position={[x, lightHeight, z]}
            intensity={lightIntensity}
            castShadow
            color={lightColor}
          />
        ));
      })}
      {/* Additional Point Light in the middle */}
      <pointLight
        ref={addToRefs}
        key="middle"
        color={lightColor}
        position={[
          middleLightPosition.x,
          middleLightPosition.y,
          middleLightPosition.z,
        ]}
        intensity={lightIntensity}
        castShadow
      />
    </>
  );
};

export default Lights
