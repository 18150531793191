import {debounce} from 'lodash'
import {useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {deleteProduct, getAllProducts} from '../../app/modules/auth/core/Requests/_requestsProduct'
import {KTSVG} from '../../_metronic/helpers'
import PaginationControls from '../components/PaginationControls'
import {Form, Formik} from 'formik'
import {useAuth} from '../../app/modules/auth'
import './style/DepositSelectProd.scss'

interface Deposit {
  _id: string
  id: string
  name: string
}

interface Product {
  _id: string
  name: string
  sku: string
  price: number
  stock_data: {stock: number}
}

interface DeleteInfo {
  name: string
  id: string
}

const MyButton: any = Button

const DepositSelectProd = () => {
  const [allDeposits, setAllDeposits] = useState<Deposit[]>([])
  const [flag, setFlag] = useState(false)
  const [loading, setLoading] = useState(false)
  const [depositID, setDepositID] = useState('default')
  const [showModal, setShowModal] = useState(false)
  const [selectedProductDetails, setSelectedProductDetails] = useState<DeleteInfo>({
    name: '',
    id: '',
  })
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const [allProducts, setAllProducts] = useState([])

  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const selectedGroup = localStorage.getItem('group')

  useEffect(() => {
    getAllProducts({page, limit, full: true, currentUser: currentUser}).then((res) => {
      console.log('res in DepositsPage.tsx', res)
      if (res && res.data && res.data.products.length !== 0) {
        setTotalPages(res.data.pages)
        setAllProducts(res.data.products)
      } else {
        setFlag(true)
      }
    })
  }, [page, limit])

  useEffect(() => {
    getAllProducts({page, limit, full: true, currentUser: currentUser}).then((res) => {
      console.log('res in DepositsPage.tsx', res)
      if (res && res.data && res.data.products.length !== 0) {
        setTotalPages(res.data.pages)
        setAllProducts(res.data.products)
      } else {
        setFlag(true)
      }
    })
  }, [page, limit])

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value
    console.log(value, 'name', event.target.name)

    if (value === 'null') {
      setDepositID(value)
    } else {
      const selectedDep = allDeposits.find((dep) => dep.name === value)
      selectedDep?.id !== undefined && setDepositID(selectedDep.id)
    }
  }

  function handleDropdownSubmit(deposit: any) {
    console.log('depozit selectat ', deposit)
    if (deposit === 'default') {
      return
    } else if (deposit == 'null') {
      navigate('/products')
    } else navigate(`/products/${deposit}`)
  }

  function handleModalAppearance(prodName: string, prodId: string) {
    console.log(`Opening modal for product: ${prodName}`)
    setSelectedProductDetails({name: prodName, id: prodId})
    setShowModal(true)
  }

  function handleDeleteProd() {
    deleteProduct(selectedProductDetails.id)
      .then((res) => {
        console.log('RESULT OF PROD DELETE', res)
      })
      .catch((err) => {
        console.log('ERR OF PROD DELETE', err)
      })

    const productId = allProducts.findIndex(
      (product: Product) => product._id.toString() === selectedProductDetails.id
    )

    if (productId !== -1) {
      allProducts.splice(productId, 1)
    }

    setShowModal(false)
  }

  function showStockAvailable(stockData: any) {
    let totalQty = 0
    stockData.map((stock: any) => {
      totalQty = totalQty + stock.qty
    })
    return totalQty
  }

  const handleProductSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    console.log(searchTerm)
    setFlag(false)

    getAllProducts({search: searchTerm, full: true, currentUser: currentUser}).then((res) => {
      console.log('res in DEPOSIT SELECT PROD.tsx', res)
      setAllProducts(res.data.products)
      if (res.data.products.length == 0) {
        setFlag(true)
      }
    })
  }

  const debouncedHandleDropdownSubmit = debounce(handleDropdownSubmit, 500)
  const debouncedHandleProductSearch = debounce(handleProductSearch, 1000)

  return (
    <>
      <div className='products'>
        <div className='products__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>Produse</h1>
          <div className='products__title--search'>
            <button
              onClick={() => navigate('/add-product')}
              className='products__title--search-button'
              style={{backgroundColor: '#CE8D3B', display: 'flex', alignItems: 'center'}}
              disabled={currentUser.role === 'super_admin' && !selectedGroup}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              <p>Produs nou</p>
            </button>
            {currentUser.role === 'super_admin' && !selectedGroup && (
              <p className='disabledNote'>Nici un grup selectat.</p>
            )}
          </div>
        </div>
        <div className='products__separator' />
        <div className='filters'>
          <h2 className='filters-title'>Filtre</h2>
          <div className='filter-container'>
            <div className='filter'>
              <input
                onChange={(event) => debouncedHandleProductSearch(event)}
                type='text'
                placeholder='Caută produs'
              />
            </div>
          </div>
          <div className='filter-container'>
            <div className='filter'>
              <select
                defaultValue={''}
                name='deposit'
                onChange={(event) => debouncedHandleDropdownSubmit(event.target.value)}
              >
                <option value='' disabled>
                  Vezi toate produsele
                </option>
                <option value='null' className='p-5'>
                  Vezi produsele fără depozite
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className='products__separator' />
        <div className='products__container'>
          <div className='products__subtitle'>
            <h2>Listă produse</h2>
          </div>
          <div className='products__table'>
            {allProducts.length ? (
              <>
                <div className='products__details--row products__table--row'>
                  <p className='products__details--row-header'>SKU</p>
                  <p className='products__details--row-header'>Nume</p>
                  <p className='products__details--row-header'>Stoc</p>
                  <p className='products__details--row-header'>Preț</p>
                  <p className='products__details--row-header'>Acțiuni</p>
                </div>
                {allProducts.map((product: Product, id) => {
                  return (
                    <div
                      key={`sku-${product.sku}-product-name-${product.name}`}
                      className='products__details--row products__details--product products__table--row'
                    >
                      <p className='products__details--row-info'>{product.sku}</p>
                      <p className='products__details--row-info'>{product.name}</p>
                      <p className='products__details--row-info'>
                        {showStockAvailable(product.stock_data)}
                      </p>
                      <p className='products__details--row-info'>
                        {product.price ? product.price : 0}
                      </p>
                      <div className='products__details--row-info'>
                        <Link
                          to={`/edit-product/${product._id}`}
                          className='btn btn-icon btn-light btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/pencil.svg'
                            className='svg-icon-3'
                          />
                        </Link>
                        <button
                          onClick={() => {
                            handleModalAppearance(product.name, product._id)
                          }}
                          className='btn btn-icon btn-danger btn-sm button-left-margin'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
            )}
          </div>
          {allProducts.length ? (
            <PaginationControls
              currentPage={page}
              totalPages={totalPages}
              limit={limit}
              setCurrentPage={(number: any) => setPage(number)}
            />
          ) : (
            ''
          )}
        </div>
        {showModal && (
          <DeleteModal
            active={true}
            prodName={selectedProductDetails.name}
            handleClose={() => setShowModal(false)}
            handleDeleteProd={() => handleDeleteProd()}
          />
        )}
      </div>
    </>
  )
}

export default DepositSelectProd

interface DeleteProps {
  active: boolean
  prodName: string
  handleClose: () => void
  handleDeleteProd: () => void
}

const DeleteModal = ({active, prodName, handleClose, handleDeleteProd}: DeleteProps) => {
  //   const [show, setShow] = useState(true)

  //   const handleClose = () => setShow(false)
  //   const handleShow = () => setShow(true)

  return (
    <>
      <Modal show={active} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Șterge produs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Sigur ștergi {prodName}?</div>
        </Modal.Body>

        <Modal.Footer>
          <MyButton variant='secondary' onClick={handleClose}>
            Închide
          </MyButton>
          <MyButton variant='primary' onClick={handleDeleteProd}>
            Șterge
          </MyButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}
