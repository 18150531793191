/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getMyDetails, login} from '../core/Requests/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {AuthModel, UserModel} from '../core/_models'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formatul emailului este incorect')
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de carctere')
    .required('Emailul este obligatoriu'),
  password: Yup.string()
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de carctere')
    .required('Parola este obligatorie'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        // const {data: auth} = await login(values.email, values.password)
        // console.log('data in login.tsx', auth)
        // // saveAuth()

        const response = await login(values.email, values.password, 'web')
        console.log('Login response:', response)

        const {accessToken, refreshToken} = response.data.data

        const authModel: AuthModel = {
          accessToken,
          refreshToken,
          // response: null,
          // data: null,
        }
        saveAuth(authModel)
        if (response.data.data.user.role === 'super_admin') {
          localStorage.setItem('group', '')
        }
        setCurrentUser(response.data.data.user)
      } catch (error: any) {
        console.error(error)
        saveAuth(undefined)
        if (error.message === 'Incorrect email or password') {
          setStatus('Emailul sau parola nu sunt corecte. Încearcă din nou.')
        } else if (error.message === 'This account is not accepted on this device') {
          setStatus('Acest cont nu este acceptat pe acest tip de device.')
        } else if (error.message === 'You do not belong to a valid group') {
          setStatus('Nu aparții unui grup valid.')
        } else if (error.status === 429) {
          setStatus('Serviciu momentan indisponibil.')
        }
        setSubmitting(false)
        setLoading(false)
        return error
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='fw-bolder mb-3' style={{color: 'black'}}>
          Sign In
        </h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Login options */}

      {formik.status ? (
        <div
          className='lg-15 my-5'
          style={{background: 'white', boxShadow: ' 4px 4px 28px -14px rgba(241,65,108,0.5)'}}
        >
          <div className='alert-text text-danger' style={{fontWeight: 'bold'}}>
            {formik.status}
          </div>
        </div>
      ) : (
        ''
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder' style={{color: 'black'}}>
          Email
        </label>
        <input
          placeholder='exemplu@email.com'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder fs-6 mb-0' style={{color: 'black'}}>
          Parolă
        </label>
        <input
          placeholder='Introdu parola aici'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' style={{color: 'black'}}>
          Ai uitat parola?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          style={{backgroundColor: '#656330'}}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continuă</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Așteaptă...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
