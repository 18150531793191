import axiosConf from './_axiosInstance'

export const deleteUser = async (user: any) => {
  try {
    const response = await axiosConf.delete(`/users/${user}`)
    return response.data
  } catch (err) {
    console.log('deleteUser err', err)
    return err
  }
}
