import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {AddNewProduct} from '../../customComponents/Products/AddNewProduct'
import {AddNewClient} from '../../customComponents/Client/AddNewClient'
import {Products} from '../../customComponents/Products/Products'
import {UsersList} from '../modules/profile/components/UsersList'
import {Users} from '../../customComponents/Users/Users'
import {AddUser} from '../../customComponents/Users/AddUser'
import EditUsers from '../../customComponents/Users/EditUsers'
import DepositsPage from '../../customComponents/Deposits/DepositsPage'
import DepositDetails from '../../customComponents/Deposits/DepositDetails'
import EditDeposit from '../../customComponents/Deposits/EditDeposit'
import CreateDeposit from '../../customComponents/Deposits/CreateDeposit'
import {Logs} from '../../customComponents/Logs/Logs'
import {Receptii} from '../../customComponents/Receptii/Receptii'
import Reception from '../../customComponents/Receptii/Reception'
// import {ProdList} from '../../customComponents/ProdList'
import DepositSelectProd from '../../customComponents/Products/DepositSelectProd'
import EditProduct from '../../customComponents/Products/EditProduct'
import OrderList from '../../customComponents/Orders/OrderList'
import OrderDetails from '../../customComponents/Orders/OrderDetails'
import ClientList from '../../customComponents/Client/ClientList'
import {EditReception} from '../../customComponents/Receptii/EditReception'
import PreviewDeposits from '../../customComponents/Deposits/PreviewDeposits/PreviewDeposits'
import ModalDeposits from '../../customComponents/Deposits/PreviewDeposits/ModalDeposits'
import CreateDepositSecond from '../../customComponents/DepositsSecond/CreateDepositSecond'
import EditDepositSecond from '../../customComponents/DepositsSecond/EditDepositSecond'
import Reports from '../../customComponents/Reports/Reports'
import {Groups} from '../../customComponents/Groups/Groups'
import {AddGroup} from '../../customComponents/Groups/AddGroup'
import {EditGroup} from '../../customComponents/Groups/EditGroup'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='add-product' element={<AddNewProduct />} />
        <Route path='users' element={<Users />} />
        <Route path='add-client' element={<AddNewClient />} />
        <Route path='add-user' element={<AddUser />} />
        <Route path='edit-user/:id' element={<EditUsers />} />
        <Route path='clients' element={<ClientList />} />
        <Route path='logs' element={<Logs />} />
        <Route path='reports' element={<Reports />} />
        <Route path='products' element={<Products />} />
        <Route path='products/:id' element={<Products />} />
        <Route path='deposits' element={<DepositsPage />} />
        {/*<Route path='product-list' element={<ProdList />} /> */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='receptions' element={<Receptii />} />
        <Route path='deposit/:id' element={<DepositDetails />} />
        <Route path='edit-deposit/:id' element={<EditDepositSecond />} />
        <Route path='edit-deposit-second/:id' element={<EditDepositSecond />} />
        <Route path='reception/:id' element={<Reception />} />
        <Route path='edit-reception/:id' element={<EditReception />} />
        <Route path='create-deposit' element={<CreateDepositSecond />} />
        <Route path='create-deposit-second' element={<CreateDepositSecond />} />
        <Route path='select-deposit' element={<DepositSelectProd />} />
        <Route path='edit-product/:id' element={<EditProduct />} />
        <Route path='orders' element={<OrderList />} />
        <Route path='groups' element={<Groups />} />
        <Route path='add-group' element={<AddGroup />} />
        <Route path='edit-group/:id' element={<EditGroup />} />
        <Route path='view-order/:id' element={<OrderDetails />} />
        <Route path='preview-deposits/:id' element={<PreviewDeposits />} />
        <Route
          path='modal-deposits/:id'
          element={<ModalDeposits isOpen={false} onClose={() => {}} children={undefined} />}
        />

        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
