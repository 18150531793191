import React from 'react'
import styled from 'styled-components'



// Styled components for better layout and design
const StyledContainer = styled.div`
  background-color: #f5f5f5;
  color: #333;
  font-family: Arial, sans-serif;

  h1 {
    font-size: 24px;
    font-weight: bold;
    color: #1a1a1a;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
    margin-top: 10px;
    margin-bottom: 10px;
    color: black;
  }

  strong {
    font-weight: bold;
  }

  .container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }

  .section-header {
    text-align: center;
    margin-bottom: 30px;
  }
`;

const Terms = () => {
  return (
    <StyledContainer>
      <div>
        <section
          id='wrapper'
          className='columns-container d-flex flex-column flex-lg-row-fluid p-10 order-2 order-lg-1'
        >
          <div id='columns' className='container d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='text-center mb-11'>
              <h1 className='fw-bolder mb-3' style={{color: 'black'}}>
                Termeni și condiții de utilizare
              </h1>
            </div>

            <section id='content' className='page-content page-cms page-cms-3'>
              <div className='style_content cms_content'>
                <p>
                  <strong>TERMENI</strong>
                </p>
                <p></p>
                <p>
                  Termenii si conditiile generale de vanzare se vor aplica tuturor vanzarilor de
                  bunuri si servicii de catre Incom prin intermediul magazinului virtual
                  https://www.nexloc.ro catre Cumparator si pot fi modificate numai cu acordul
                  expres scris al ambelor parti. Acest continut a fost facut in conformitate cu
                  urmatoarele reglementari guvernamentale care privesc comertul electronic
                  (e-commerce).
                </p>
                <p></p>
                <p>
                  Legea nr 365/2002 privind comertul electronic, cu modificarile aduse prin legea
                  121/2006
                </p>
                <p>OUG 34/2014&nbsp;</p>
                <p></p>
                <p></p>
                <p>
                  <strong>In acest document, urmatorii termeni definiti vor fi utilizati:</strong>
                </p>
                <p></p>
                <p>
                  Cumparator: persoana, firma, companie sau alta entitate juridica care emite o
                  Comanda.
                </p>
                <p>
                  Vanzator: societate comerciala Incom., avand sediul in Galati, Aleea Lalelelor, Nr
                  10, numar registru comertului J17/1962/2006, CUI RO 19302026.
                </p>
                <p>
                  Bunuri: orice proiect la cheie, echipament, material si/sau parte a acestora sau
                  serviciu, inclusiv documentele si serviciile mentionate in Comanda sau privitoare
                  in alt mod la acestea care urmeaza a fi furnizate de catre Vanzator
                  Cumparatorului.
                </p>
                <p>
                  Comanda: un document electronic ce intervine ca forma de comunicare intre Vanzator
                  si Cumparator prin care Vanzatorul este de acord sa livreze Bunurile si Serviciile
                  si Cumparatorul este de acord sa primeasca aceste Bunuri si Servicii si sa faca
                  plata acestora.
                </p>
                <p>
                  Drepturi de proprietate intelectuala (in cele ce urmeaza DPI): toate drepturile
                  imateriale cum ar fi know-how, dreptul de autor si drepturi in natura de autor,
                  drepturile de baza de date, drepturi de proiectare, drepturi de model, patente,
                  marci inregistrate si inregistrari ale numelor de domenii pentru oricare din cele
                  de mai sus.
                </p>
                <p>
                  Specificatii: toate specificatiile si/sau descrierile Bunurilor si Serviciilor asa
                  cum sunt precizate in comanda.
                </p>
                <p></p>
                <p></p>
                <p>
                  <strong>Continut</strong>
                </p>
                <p></p>
                <p>
                  Toate informatiile de pe Site care pot fi vizitate, vizualizate sau altfel
                  accesate prin utilizarea unui echipament electronic;
                </p>
                <p>
                  Continutul oricarui e-mail trimis Cumparatorilor de catre Vanzator prin mijloace
                  electronice si/sau orice alt mijloc de comunicare disponibil;
                </p>
                <p>
                  Orice informatie comunicata prin orice mijloc de catre un angajat/colaborator al
                  Vanzatorului, Cumparatorului, conform informatiilor de contactare, specificate sau
                  nu de catre acesta;
                </p>
                <p>
                  Informatii legate de Bunurile si/sau Serviciile si/sau tarifele practicate de
                  Vanzator intr-o anumita perioada;
                </p>
                <p>
                  Informatii legate de Bunurile si/sau Serviciile si/sau tarifele practicate de
                  catre un tert cu care Vanzatorul are incheiate contracte de parteneriat, intr-o
                  anumita perioada; date referitoare la Vanzator, sau alte date privilegiate ale
                  acestuia.
                </p>
                <p></p>
                <p>
                  <strong>DOCUMENTE CONTRACTUALE</strong>
                </p>
                <p></p>
                <p>
                  Prin lansarea unei comenzi electronice sau telefonice pe site-ul
                  https://www.nexloc.ro, Cumparatorul este de acord cu forma de comunicare
                  (telefonic sau e-mail) prin care Vanzatorul isi deruleaza operatiunile. Comanda va
                  fi compusa din urmatoarele documente, in ordinea importantei:
                </p>
                <p>Comanda (impreuna cu mentiunile clare asupra datelor de livrare si facturare)</p>
                <p>Specificatiile Cumparatorului (acolo unde este cazul)</p>
                <p></p>
                <p>
                  <strong>CESIONAREA SI SUBCONTRACTAREA</strong>
                </p>
                <p></p>
                <p>
                  Vanzatorul poate cesiona si/sau subcontracta o terta parte pentru Servicii ce tin
                  de onorarea Comenzii, cu informarea Cumparatorului, nefiind necesar acordul
                  acestuia. Vanzatorul va fi intotdeauna responsabil fata de Cumparator pentru toate
                  obligatiile contractuale.
                </p>
                <p></p>
                <p>
                  <strong>COMANDA</strong>
                </p>
                <p></p>
                <p>
                  Comanda minimă este de 20RON. Toate prețurile afișate pe site sunt în RON și
                  conțin TVA. Prețurile afișate nu conțin taxele de transport. Aceste taxe vor fi
                  trecute separat pe factura fiscală.
                </p>
                <p></p>
                <p>
                  Factura fiscală se emite conform legislației în vigoare din România și se trimite
                  pe email împreună cu produsele comandate la adresa de livrare. La cerere, factura
                  poate fi oferită și în format fizic. Comenzile făcute în zilele lucrătoare, până
                  la ora 14:00, ora României, se expediază în cel mult 2 zile lucrătoare în cazul în
                  care produsele apar pe site marcate cu expediere în 24h.
                </p>
                <p></p>
                <p>
                  Conform legilor în vigoare, comanda făcută pe acest website se consideră un
                  contract între utilizator și Incom și trebuie executată de către ambele părți.
                  Aceasta înseamnă că Incom se obligă să livreze produsele și serviciile comandate,
                  iar utilizatorul se obligă să plătească contravaloarea comenzii.
                </p>
                <p></p>
                <p>
                  Pentru a plasa o comandă, este necesar să se accepte termenii și condițiile. Nu se
                  poate finaliza o comandă fără să se bifeze căsuța de acceptare a acestor termeni.
                </p>
                <p></p>
                <p>
                  Ne rezervăm dreptul de a modifica prețurile afișate pe website atunci când
                  considerăm că acest lucru este necesar. În cazul în care în urma unor importuri de
                  oferte pe site apar produse cu preț 0 sau cu prețuri derizorii, clienții care au
                  comandat acele produse vor fi anunțați că li se va modifica sau anula comanda,
                  după caz. La bază stă "Art 1665 Prețul fictiv și prețul derizoriu" din Codul
                  Civil.
                </p>
                <p></p>
                <p>
                  Incom folosește serviciile altor companii, de exemplu pentru transport, plată,
                  etc. Responsabilitatea pentru aceste servicii revine integral firmelor prestatoare
                  de servicii. Este responsabilitatea utilizatorului ca înainte de folosirea acestor
                  servicii să cunoască și să accepte condițiile și termenii serviciilor.
                </p>
                <p></p>
                <p>
                  <strong>CONFIDENTIALITATE</strong>
                </p>
                <p></p>
                <p>
                  Incom va pastra confidentialitatea informatiilor de orice natura pe care le
                  furnizati. Dezvaluirea informatiilor furnizate se va putea face doar in conditiile
                  mentionate in prezentul Document.
                </p>
                <p>
                  Nicio declaratie publica, promovare, comunicat de presa sau orice alt mod de
                  dezvaluire catre terte parti nu va fi facuta de Cumparator/Client cu privire la
                  Comanda/Contract fara consimtamantul prealabil scris al Vanzatorului.
                </p>
                <p>
                  Prin transmiterea de informatii sau materiale prin intermediul acestui site,
                  oferiti Vanzatorului acces nerestrictionat si irevocabil la acestea, dreptul de a
                  utiliza, reproduce, afisa, modifica, transmite si distribui aceste materiale sau
                  informatii. Sunteti, de asemenea, de acord ca Vanzatorul sa poata utiliza liber,
                  in interes propriu, aceste informatii, idei, concepte, know-how-uri sau tehnici pe
                  care ni le-ati trimis prin intermediul Site-ului. Incom nu va constitui subiect de
                  obligatii referitoare la confidentialitatea informatiilor trimise, daca legislatia
                  in vigoare nu prevede alte specificari in acest sens.
                </p>
                <p>
                  Prin inscrierea in baza de date a Incom, Clientul/Cumparatorul isi ofera
                  consimtamantul expres, in limitele legislatiei in vigoare, de a fi contactat de
                  catre terti, parteneri ai Incom: furnizori de servicii de marketing, alti
                  furnizori de servicii in vederea indeplinirii obiectului Contractului incheiat
                  intre Cumparator si Vanzator, precum si de agentii de stat, guvernamentale, atunci
                  cand legislatia specifica prevede acest lucru; cat si de alte companii cu care
                  Incom poate dezvolta programe comune de ofertare pe piata a Bunurilor si/sau a
                  Serviciilor etc.
                </p>
                <p></p>
                <p>
                  <strong>GARANTII</strong>
                </p>
                <p></p>
                <p>
                  Toate Bunurile comercializate de catre Incom beneficiaza de conditii de garantie
                  conforme legislatiei in vigoare si politicilor comerciale ale producatorilor.
                  Bunurile sunt noi, in ambalajele originale si provin din surse autorizate de
                  fiecare producator in parte.
                </p>
                <p></p>
                <p>
                  Garantia se ofera in baza facturii și este de 24 luni pentru persoane fizice,
                  respectiv 12 luni pentru persoane juridice.
                </p>
                <p></p>
                <p>
                  <strong>LIVRAREA BUNURILOR</strong>
                </p>
                <p></p>
                <p>
                  Vanzatorul se obliga sa livreze Bunurile in sistem de curierat door-to-door catre
                  Cumparator.
                </p>
                <p>
                  Vanzatorul va asigura ambalarea corespunzatoare a Bunurilor si va asigura
                  transmiterea documentelor insotitoare.
                </p>
                <p>
                  Vanzatorul va efectua livrarea Bunurilor si Serviciilor numai pe teritoriul
                  Romaniei.
                </p>
                <p></p>
                <p>
                  Cumpărătorii care doresc să achiziționeze produse de pe www.nexloc.ro au la
                  dispoziție două opțiuni de livrare. Prima opțiune este utilizarea lockerelor
                  Sameday, cu un cost începând de la 11 RON, opțiune ideală pentru cei care își
                  doresc să-și ridice comanda personal și să economisească în același timp.
                  Lockerele&nbsp; sunt situate strategic, pentru a facilita accesul rapid și simplu
                  la comenzi.
                </p>
                <p></p>
                <p>
                  Alternativ, cumpărătorii pot opta pentru livrarea prin curier rapid, la un cost de
                  17 RON. Acest serviciu oferă comoditate și rapiditate, comanda ajungând direct la
                  adresa furnizată de cumpărător.
                </p>
                <p></p>
                <p>
                  În ceea ce privește greutatea coletelor, există câteva reguli importante. Dacă
                  comanda depășește 3kg, se va aplica o taxă de 1.2 lei (fără TVA) pentru fiecare kg
                  suplimentar. Mai mult, pentru comenzile care depășesc 32kg, taxa de transport se
                  ridică la 100 lei, iar pentru fiecare kg în plus peste acest prag se va adăuga o
                  taxă de 2.5 lei. Aceste tarife suplimentare asigură gestionarea eficientă a
                  comenzilor de dimensiuni mari, garantând în același timp că produsele sunt livrate
                  în siguranță.
                </p>
                <p></p>
                <p>
                  În cazul în care se solicită expres livrarea într-o zi de sâmbătă, se va percepe o
                  taxă în plus și o să fiți notificați în legătură cu valoarea acesteia. În acest
                  caz, Nexloc va expedia coletul cu un AWB special cu specificația de "livrare
                  sâmbăta", dar nu își asumă răspunderea pentru nelivrarea coletelor la timp de
                  către firmele de curierat.&nbsp;
                </p>
                <p></p>
                <p>
                  Comenzile plasate până la ora 14:00 sunt predate curierului deobicei în aceași zi,
                  urmând a fi livrate a doua sau a treia zi lucrătoare. Cele plasate după 14:00 sunt
                  predate în următoarea zi lucrătoare. Acestea sunt datele estimate, în funcție de
                  volumul de comenzi expedierea coletului poate întârzia maxim o zi lucrătoare față
                  de estimare.
                </p>
                <p></p>
                <p>
                  Timpul de livrare: în mod normal este de 1-2 zile lucrătoare, dar pot apărea
                  întârzieri din motive obiective care în cele mai multe cazuri nu țin de noi.
                </p>
                <p></p>
                <p>
                  Nu ne asumăm raspunderea pentru produsele deteriorate sau distruse de catre
                  transportator. Pentru coletele ce prezintă deteriorări la primirea prin curier, vă
                  recomandăm să refuzați recepția lor. Coletul poate fi fotografiat și se poate
                  întocmi un proces verbal împreună cu agentul curier, în care să fie menționată
                  starea coletului în momentul livrării la dumneavoastră. Riscul de pierdere sau de
                  deteriorare a coletului vă este transferat în momentul în care intrați în posesia
                  fizica a coletului. Orice reclamație ulterioara nu va fi luată în considerare.
                </p>
                <p></p>
                <p>
                  <strong>TRANSFERUL PROPRIETATII BUNURILOR</strong>
                </p>
                <p></p>
                <p>
                  Proprietatea asupra Bunurilor va fi transferata la livrare, dupa efectuarea platii
                  din partea Cumparatorului in locatia indicata in Comanda (intelegand prin livrare
                  – semnarea de primire a documentului de transport furnizat de curier sau semnarea
                  de primire pe factura fiscala in cazul livrarilor efectuate de personalul
                  Vanzatorului).
                </p>
                <p></p>
                <p>
                  <strong>POLITICA DE RETUR</strong>
                </p>
                <p></p>
                <p>
                  Produsele achizitionate la distanta se pot returna conform O.U.G. 34/2014.
                  Consumatorul are dreptul sa notifice in scris comerciantul ca renunta la
                  cumparare, fara penalitati si fara invocarea unui motiv, in termen de 30 zile de
                  la primirea produsului sau, in cazul prestarilor de servicii, de la incheierea
                  contractului. Returnarea produselor se va face pe cheltuiala clientului, in termen
                  de 30 zile de la denuntarea contractului. Se recomanda utlizarea aceluiasi
                  serviciu de transport utilizat pentru receptionarea produselor.
                </p>
                <p></p>
                <p>
                  <strong>PRELUCRAREA DATELOR CU CARACTER PERSONAL</strong>
                </p>
                <p></p>
                <p>
                  Conform cerintelor Legii nr. 677/2001 pentru protectia persoanelor cu privire la
                  prelucrarea datelor cu caracter personal si libera circulatie a acestor date,
                  modificata si completata, Nexloc are obligatia de a administra in conditii de
                  siguranta si numai pentru scopurile specificate, datele personale pe care ni le
                  furnizezi.
                </p>
                <p>Scopul colectarii datelor este:</p>
                <p>
                  informarea Clientilor/Cumparatorilor privind situatia Contului lor inclusiv
                  validarea, expedierea si facturarea Comenzilor, rezolvarea anularilor sau a
                  problemelor de orice natura referitoare la o Comanda, la Bunurile si sau
                  serviciile achizitionate,
                </p>
                <p>
                  trimiterea de Newslettere si/sau alerte periodice, prin folosirea postei
                  electronice (e-mail, SMS)
                </p>
                <p>
                  de cercetare de piata, de urmarire si monitorizare a vanzarilor si comportamentul
                  Clientului/Cumparatorului.
                </p>
                <p>
                  Prin citirea Documentului ati luat la cunostinta faptul ca va sunt garantate
                  drepturile prevazute de lege, respectiv dreptul la informare, dreptul de acces la
                  date, dreptul de interventie, dreptul de opozitie, dreptul de a nu fi supus unei
                  decizii individuale, dreptul de va adresa justitiei in caz de incalcare a
                  drepturilor garantate de Legea 677/2001 pentru protectia persoanelor cu privire la
                  prelucrarea datelor cu caracter personal si libera circulatie a acestor date.
                </p>
                <p></p>
                <p>
                  <strong>LEGEA APLICABILA – JURISDICTIA</strong>
                </p>
                <p></p>
                <p>
                  Prezentul Contract este supus legii romane. Eventualele litigii aparute intre
                  Incom si Clienti / Cumparatori se vor rezolva pe cale amiabila sau, in cazul in
                  care aceasta nu va fi posibila, litigiile vor fi solutionate de instantele
                  judecatoresti romane competente din Municipiul Bucuresti.
                </p>
                <p>http://www.anpc.ro/</p>
                <p></p>
                <p>
                  <strong>DREPTUL DE PROPRIETATE INTELECTUALA SI INDUSTRIALA</strong>
                </p>
                <p></p>
                <p>
                  Continutul, astfel cum este definit in preambul, incluzand dar nelimitandu-se la
                  logo-uri, reprezentari stilizate, simboluri comerciale, imagini statice, imagini
                  dinamice, text si/sau continut multimedia prezentate pe Site, sunt proprietatea
                  exclusiva a Incom, acestuia fiindu-i rezervate toate drepturile obtinute in acest
                  sens in mod direct sau indirect (prin licente de utilizare si/sau publicare).
                </p>
                <p>
                  Clientului/Cumparatorului nu ii este permisa copierea, distribuirea, publicarea,
                  transferul catre terte parti, modificarea si/sau altfel alterarea, utilizarea,
                  legarea la, expunerea, includerea oricarui Continut in orice alt context decat cel
                  original intentionat de Incom, includerea oricarui Continut in afara Site-ului,
                  indepartarea insemnelor care semnifica dreptul de autor al Incom asupra
                  Continutului precum si participarea la transferul, vanzarea, distributia unor
                  materiale realizate prin reproducerea, modificarea sau afisarea Continutului,
                  decat cu acordul scris expres al Incom.
                </p>
                <p></p>
              </div>
            </section>
          </div>
        </section>
      </div>
    </StyledContainer>
  )
}


export default Terms