import axios from 'axios'
import axiosConf from './_axiosInstance'
import {GET_USERS} from '../_requests_user'
export const API_URL = process.env.REACT_APP_API_URL
export const GET_RECEPTIONS = `${API_URL}/receptions`

export const getAllReceptions = async ({page, limit, currentUser}: any) => {
  let url = GET_RECEPTIONS
  const selectedGroup = localStorage.getItem('group')

  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (currentUser?.role === 'super_admin' && selectedGroup)
    queryParams.append('group', selectedGroup)

  const queryString = queryParams.toString()
  if (queryString) url += `?${queryString}`

  try {
    const response = await axiosConf.get(url)
    return response.data
  } catch (err) {
    console.log('get ALL LOGS err', err)
    return err
  }
}


export const getReceptionById = async (id: string | undefined) => {
  try {
    const response = await axiosConf.get(`${GET_RECEPTIONS}/${id}`)
    return response.data
  } catch (err) {
    console.log('get RECEPTION BY ID err', err)
    return err
  }
}
export const editReception = async (id: string, data: { checks: any; products: any[] }) => {
  try {
    const response = await axiosConf.patch(`${GET_RECEPTIONS}/${id}/admin`, data);
    return response.data;
  } catch (err) {
    console.error('EDIT RECEPTION err', err);
    throw err; 
  }
};

