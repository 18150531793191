/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {getAllReceptions} from '../../app/modules/auth/core/Requests/_requestsReceptions'
import PaginationControls from '../components/PaginationControls'
import {useAuth} from '../../app/modules/auth'
import './style/ReceptionList.scss'

export const API_URL = process.env.REACT_APP_API_URL

interface ReceptionData {
  _id: string
  id: string
  user: {
    name: string
  }
  no: number
  deposit: {
    name: string
  }
  checks: {
    packing: boolean
    labeling_integrity: boolean
    products_identification: boolean
    seals_integrity: boolean
    marking_integrity: boolean
  }
  products: [
    {
      sku: string
      qty: number
    }
  ]
  products_status: {
    new_products: {
      sku: string
      qty: number
    }
    existing_products: {
      sku: string
      name: string
      qty: number
      old_qty: number
      new_qty: number
    }
  }
  finished: boolean
  createdAt: string
}

type Props = {
  className: string
}

interface FancyLinkProps {
  navigate?: () => void // Define the type of navigate function or any other props
  // ... other props
}

const FancyLink = React.forwardRef<HTMLAnchorElement, FancyLinkProps>(
  ({navigate, ...props}, ref) => {
    return (
      <a ref={ref} {...props}>
        <KTSVG path='/media/icons/duotune/arrows/eye.svg' className='svg-icon-3' />
      </a>
    )
  }
)

const ReceptionList: React.FC<Props> = ({className}) => {
  const {currentUser} = useAuth()
  const [receptions, setReceptions] = useState<ReceptionData[]>([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    getAllReceptions({page, limit, currentUser}).then((res) => {
      console.log('res in RECEPTIONS.tsx', res)
      if (res && res.data && res.data.receptions.length !== 0) {
        setTotalPages(res.data.pages)
        setReceptions(res.data.receptions)
      }
    })
  }, [page, limit]) // Re-run the effect if these values change

  const handleClickRow = (recId: string) => {
    navigate(`/reception/:${recId}`)
  }
  return (
    <>
      <div className='receptions'>
        <div className='receptions__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>Recepții marfă</h1>
        </div>
        <div className='receptions__separator' />
        <div className='receptions__container'>
          <div className='receptions__subtitle'>
            <h2>Listă recepții</h2>
          </div>
          <div className='receptions__table'>
            <div className='receptions__details--row receptions__table--row'>
              <p className='receptions__details--row-header'>Nr</p>
              <p className='receptions__details--row-header'>Nume</p>
              <p className='receptions__details--row-header'>Depozit</p>
              <p className='receptions__details--row-header'>Total produse</p>
              <p className='receptions__details--row-header'>Finalizat</p>
              <p className='receptions__details--row-header'>Acțiune</p>
            </div>
            {receptions.map((rec: ReceptionData, index) => {
              return (
                <div
                  key={rec.no}
                  className='receptions__details--row receptions__details--reception receptions__table--row'
                >
                  <p className='receptions__details--row-info'>{rec.no}</p>
                  <p className='receptions__details--row-info'>{rec.user?.name}</p>
                  <p className='receptions__details--row-info'>{rec.deposit.name}</p>
                  <p className='receptions__details--row-info'>{rec.products.length}</p>
                  <p className='receptions__details--row-info'>{rec.finished ? 'Da' : 'Nu'}</p>
                  <div className='receptions__details--row-info'>
                    <Link to={`/reception/:${rec._id}`} className='btn btn-icon btn-light btn-sm'>
                      <KTSVG path='/media/icons/duotune/arrows/pencil.svg' className='svg-icon-3' />
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
          <PaginationControls
            currentPage={page}
            totalPages={totalPages}
            limit={limit}
            setCurrentPage={(number: any) => setPage(number)}
          />
        </div>
      </div>
    </>
  )
}

export {ReceptionList}
