import {AuthModel} from './_models'

// Define a key for storing authentication data in localStorage.
// The key is retrieved from environment variables or set to a default value.
const AUTH_LOCAL_STORAGE_KEY = process.env.REACT_APP_AUTH_LOCAL_STORAGE_KEY || 'kt-auth-react-v'

// Function to retrieve the authentication data (AuthModel) from localStorage.
const getAuth = (): AuthModel | undefined => {
  // Check if localStorage is available in the current environment.
  if (!localStorage) {
    return
  }

  // Retrieve the stored authentication data as a string.
  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  // If there is no stored data, return undefined.
  if (!lsValue) {
    return
  }

  try {
    // Parse the stored string to an object of type AuthModel.
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    // If parsing is successful and the auth object exists, return it.
    if (auth) {
      // Additional checks like auth token expiration could be performed here.
      return auth
    }
  } catch (error) {
    // Log any errors that occur during parsing.
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

// Function to save authentication data (AuthModel) to localStorage.
const setAuth = (auth: AuthModel) => {
  // Check if localStorage is available in the current environment.
  if (!localStorage) {
    return
  }

  try {
    // Convert the AuthModel object to a string.
    const lsValue = JSON.stringify(auth)
    // Store the string in localStorage under the defined key.
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    // Log any errors that occur during saving.
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
    localStorage.removeItem('group')
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
