import React, {useState, useEffect} from 'react'
import './style/EntityOrder.scss'
import {getOrderById} from '../../../../app/modules/auth/core/Requests/_requestsOrders'
import {Link} from 'react-router-dom'

interface Order {
  _id: string
  reference: string
  createdAt: string
  carrier: string
  completed: boolean
  payment: string
  total_paid_tax_incl: number
}

const EntityOrder = ({entity}) => {
  const [order, setOrder] = useState<Order>()

  async function fetchOrder() {
    await getOrderById(entity).then((res) => {
      setOrder(res.data.order)
      console.log('order', res.data.order)
    })
  }

  function showConvertedDate(dateParam: string) {
    // Create a new Date object with the given parameter
    const date = new Date(dateParam)

    // Get the UTC date and time in the desired format
    const formattedDate = date.toLocaleDateString('ro-RO', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    const formattedTime = date.toLocaleTimeString('ro-RO', {
      timeZone: 'UTC',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    // Return the formatted date and time as a string
    return `${formattedDate} \n @${formattedTime}`
  }

  useEffect(() => {
    fetchOrder()
  }, [entity])

  return (
    <>
      {order && (
        <div className='entityOrder'>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'>Referință comandă:</p>
            <p className='entityOrder__row--description'>{order?.reference}</p>
          </div>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'>Dată:</p>
            <p className='entityOrder__row--description'>{showConvertedDate(order?.createdAt)}</p>
          </div>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'>Curier:</p>
            <p className='entityOrder__row--description'>{order?.carrier}</p>
          </div>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'>Comandă finalizată:</p>
            <p className='entityOrder__row--description'>{order?.completed ? 'Da' : 'Nu'}</p>
          </div>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'>Metodă de plată:</p>
            <p className='entityOrder__row--description'>{order?.payment}</p>
          </div>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'>Preț total:</p>
            <p className='entityOrder__row--description'>{order?.total_paid_tax_incl} lei</p>
          </div>
          <div className='entityOrder__row'>
            <p className='entityOrder__row--title'></p>
            <Link
              to={`/view-order/${order?._id}`}
              className='entityOrder__row--description entityOrder__row--description-link'
            >
              Vezi comandă
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default EntityOrder
