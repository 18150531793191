import {useEffect, useState, useRef} from 'react'
import {useNavigate, useParams} from 'react-router'
import {debounce, isEqual} from 'lodash'
import {Button, Form, Modal} from 'react-bootstrap'
import {
  editGroup,
  getGroup,
  addGroupLogo,
  getUsers,
  addGroupUsers,
  deleteGroupUsers,
} from '../../app/modules/auth/core/Requests/_requestsGroups'
import {KTSVG} from '../../_metronic/helpers'
import './style/AddGroup.scss'
import {API_URL} from '../Users/UsersList'

interface Group {
  name: string
  store_domain: string
  store_ip: string
  location: {
    latitude: number
    longitude: number
  }
  public: boolean
}
interface User {
  _id: string
  name: string
  role: string
  group: {
    id: string
    name: string
  }
}
interface GroupImage {
  imageFile: string
  imagePreview: string
}

interface UserInfo {
  name: string
  id: string
  group: string
}

const MyButton: any = Button

export function EditGroup() {
  const groupImageRef = useRef(null)
  const navigate = useNavigate()
  const {id} = useParams()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  const [totalPages, setTotalPages] = useState(0)

  const [showModal, setShowModal] = useState('')
  const [userToAdd, setUserToAdd] = useState<UserInfo>()
  const [userToDelete, setUserToDelete] = useState<UserInfo>()
  const [usersModified, setUsersModified] = useState(false)

  const [allUsers, setAllUsers] = useState<User[]>([])
  const [allUsersFilterRole, setAllUsersFilterRole] = useState('')
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [groupImage, setGroupImage] = useState<GroupImage>()
  const [loading, setLoading] = useState(false)
  const [originalGroup, setOriginalGroup] = useState<Group>({
    name: '',
    store_domain: '',
    store_ip: '',
    location: {
      latitude: 0,
      longitude: 0,
    },
    public: false,
  })
  const [formData, setFormData] = useState<Group>({
    name: '',
    store_domain: '',
    store_ip: '',
    location: {
      latitude: 0,
      longitude: 0,
    },
    public: false,
  })

  const isSelected = (id) => selectedUsers.some((selectedUser) => selectedUser._id === id)

  const handleToggleUser = (id) => {
    const user = allUsers.find((user) => user._id === id)

    setSelectedUsers((prevUsers) => {
      if (isSelected(id)) {
        return prevUsers.filter((prevUser) => prevUser._id !== id)
      } else {
        return [...prevUsers, user]
      }
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value, type} = event.target

    if (type === 'checkbox') {
      setFormData({...formData, [name]: (event.target as HTMLInputElement).checked})
    } else if (name.startsWith('location-')) {
      const locationProperty = name.split('-')[1]
      setFormData({
        ...formData,
        location: {
          ...formData.location,
          [locationProperty]: value,
        },
      })
    } else {
      setFormData({...formData, [name]: value})
    }
  }

  function handleAddUser({userName, userId}) {
    setUserToAdd({name: userName, id: userId, group: id})
    setShowModal('add')
  }
  function handleDeleteUser({userName, userId}) {
    setUserToDelete({name: userName, id: userId, group: id})
    setShowModal('delete')
  }
  function handleUploadImage(e) {
    setGroupImage({
      imageFile: e.target.files[0],
      imagePreview: URL.createObjectURL(e.target.files[0]),
    })
  }

  function handleDeleteImage() {
    setGroupImage({imageFile: '', imagePreview: ''})
    groupImageRef.current.value = ''
  }
  function fetchGroup() {
    getGroup(id).then((res) => {
      const group = res.data.group
      setFormData({
        name: group.name || '',
        store_domain: group.store_domain || '',
        store_ip: group.store_ip || '',
        location: {
          latitude: group.location?.latitude || 0,
          longitude: group.location?.longitude || 0,
        },
        public: group.public || false,
      })
      setOriginalGroup({
        name: group.name || '',
        store_domain: group.store_domain || '',
        store_ip: group.store_ip || '',
        location: {
          latitude: group.location?.latitude || 0,
          longitude: group.location?.longitude || 0,
        },
        public: group.public || false,
      })
      setGroupImage({
        imageFile: group.logo?.fileName,
        imagePreview: `${API_URL.replace('v1', '')}${group.logo?.filePath}`,
      })
    })
  }
  function fetchUsers() {
    getUsers({page, limit, role: allUsersFilterRole, group: 'none'}).then((res) => {
      if (res && res.data && res.data.users.length) {
        setTotalPages(res.data.pages)
        setAllUsers(res.data.users)
      } else {
        setTotalPages(1)
        setAllUsers([])
      }
    })
  }
  function fetchExistentUsers() {
    getUsers({page, limit, role: allUsersFilterRole, group: id}).then((res) => {
      if (res && res.data && res.data.users.length) {
        setTotalPages(res.data.pages)
        setSelectedUsers(res.data.users)
      } else {
        setTotalPages(1)
        setSelectedUsers([])
      }
    })
  }
  async function addUser() {
    await addGroupUsers({groupId: id, users: [userToAdd.id]}).then(() => {
      fetchUsers()
      fetchExistentUsers()
      setShowModal('')
      setUsersModified(true)
    })
  }
  async function deleteUser() {
    await deleteGroupUsers({groupId: id, users: [userToDelete.id]}).then(() => {
      fetchExistentUsers()
      fetchUsers()
      setShowModal('')
      setUsersModified(true)
    })
  }
  function handleUsersRole(role: any) {
    setAllUsersFilterRole(role)
    setPage(1)
  }
  const debouncedHandleUsersRole = debounce(handleUsersRole, 1000)

  async function handleEditGroup() {
    setLoading(true)
    if (!isEqual(formData, originalGroup)) {
      await editGroup({id: id, data: formData}).then((res) => {
        const group = res.data.group
        setOriginalGroup({
          name: group.name || '',
          store_domain: group.store_domain || '',
          store_ip: group.store_ip || '',
          location: {
            latitude: group.location?.latitude || 0,
            longitude: group.location?.longitude || 0,
          },
          public: group.public || false,
        })
      })
    }

    if (groupImageRef.current.value) {
      let logo = new FormData()
      logo.append('picture', groupImage.imageFile)
      await addGroupLogo({groupId: id, image: logo})
    }
    setLoading(false)
    navigate('/groups')
  }

  useEffect(() => {
    fetchGroup()
    fetchExistentUsers()
  }, [])
  useEffect(() => {
    fetchUsers()
  }, [page, limit, allUsersFilterRole])

  return (
    <>
      <div className='groupsAdd'>
        <div className='groupsAdd__title'>
          <h1 className='card-title align-items start flex-column mb-0'>Editează grup</h1>
        </div>
        <div className='groupsAdd__separator' />
        <div className='groupsAdd__container'>
          <div className='groupsAdd__subtitle'>
            <h2>Informații generale</h2>
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              handleEditGroup()
            }}
          >
            {/* Start group form here */}
            {/* NAME input start */}
            <div className='groupsAdd__section'>
              <div className='mb-6'>
                <label className='form-label groupsAdd__input--label'>Nume *</label>
                <input
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  type='text'
                  className='form-control groupsAdd__input'
                  placeholder='Incom Store'
                />
              </div>
              {/* NAME input end */}
              {/* STORE_DOMAIN input start */}
              <div className='mb-6'>
                <label className='form-label groupsAdd__input--label'>Domeniu magazin *</label>
                <input
                  onChange={handleChange}
                  name='store_domain'
                  type='text'
                  value={formData.store_domain}
                  className='form-control groupsAdd__input'
                  placeholder='domeniulmeu.ro'
                />
              </div>
              {/* STORE_DOMAIN input end */}
              {/* STORE_IP input start */}
              <div className='mb-6'>
                <label className='form-label groupsAdd__input--label'>IP magazin *</label>
                <input
                  onChange={handleChange}
                  value={formData.store_ip}
                  name='store_ip'
                  type='text'
                  className='form-control groupsAdd__input'
                  placeholder='0.0.0.1'
                />
              </div>
              {/* STORE_IP input end */}
              {/* LOCATION input start */}
              <div className='mb-6'>
                <label className='form-label groupsAdd__input--label'>Locație</label>
                <div className='groupsAdd__input--container'>
                  <input
                    onChange={handleChange}
                    value={formData.location.latitude}
                    name='location-latitude'
                    type='text'
                    className='form-control groupsAdd__input'
                    placeholder='Latitudine'
                  />
                  <input
                    onChange={handleChange}
                    value={formData.location.longitude}
                    name='location-longitude'
                    type='text'
                    className='form-control groupsAdd__input'
                    placeholder='Longitudine'
                  />
                </div>
              </div>
              {/* LOCATION input end */}
              <div className='mb-6'>
                <label className='form-label groupsAdd__input--label'>Adaugă/Schimbă logo</label>
                <div style={{display: 'flex', gap: '10px'}}>
                  <img
                    src={groupImage?.imagePreview || '/media/icons/duotune/general/photo.png'}
                    alt='group-image'
                    className='groupsAdd__input--preview'
                  />

                  <input
                    type='file'
                    name='group-image'
                    id='group-image'
                    ref={groupImageRef}
                    className='groupsAdd__input'
                    onChange={(e) => handleUploadImage(e)}
                  />
                  <button
                    className='groupsAdd__input--danger'
                    onClick={(event) => {
                      event.preventDefault()
                      handleDeleteImage()
                    }}
                  >
                    Șterge
                  </button>
                </div>
                <div className='form-label mt-5 text-gray-600' style={{fontStyle: 'italic'}}>
                  Format suportat: jpg, jpeg, png{' '}
                </div>
              </div>
              {/* PUBLIC input start */}
              <div className='mb-6 groupsAdd__input--container-checkbox'>
                <label className='form-label groupsAdd__input--label groupsAdd__input--label-checkbox'>
                  Vreau să fie grupul public
                </label>
                <input
                  onChange={handleChange}
                  checked={formData.public}
                  name='public'
                  type='checkbox'
                  className='form-check-input groupsAdd__input--checkbox'
                />
              </div>
              {/* PUBLIC input end */}
            </div>

            <div className='groupsAdd__input--container'>
              <div style={{width: '100%'}}>
                <div className='groupsAdd__subtitle'>
                  <h2>Utilizatori disponibili</h2>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className='filter' style={{marginRight: '1.75rem'}}>
                      <select
                        defaultValue={'unselected'}
                        name='userRole'
                        onChange={(event) => debouncedHandleUsersRole(event.target.value)}
                        style={{height: '2.25rem', padding: '0 0.5rem'}}
                      >
                        <option value='unselected' disabled>
                          Rol
                        </option>
                        <option value='' className='p-5'>
                          Toate
                        </option>
                        <option value='super_admin' className='p-5'>
                          Super Admin
                        </option>
                        <option value='admin' className='p-5'>
                          Admin
                        </option>
                        <option value='user' className='p-5'>
                          Utilizator
                        </option>
                      </select>
                    </div>
                    <div>
                      <button
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1}
                        className='groupsAdd__subtitle--pagination'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr022.svg'
                          className='svg-icon-3'
                        />
                      </button>
                      <button
                        onClick={() => setPage(page + 1)}
                        disabled={page === totalPages}
                        className='groupsAdd__subtitle--pagination'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr023.svg'
                          className='svg-icon-3'
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className='groupsAdd__table'>
                  {allUsers?.length ? (
                    <>
                      <div className='groupsAdd__details--row groupsAdd__table--row'>
                        <p className='groupsAdd__details--row-header'>Nume</p>
                        <p className='groupsAdd__details--row-header'>Rol</p>
                        <p className='groupsAdd__details--row-header'>Acțiune</p>
                      </div>
                      {allUsers.map((user: User) => {
                        return (
                          <div
                            key={`user-${user._id}`}
                            className='groupsAdd__details--row groupsAdd__details--user groupsAdd__table--row'
                          >
                            <p className='groupsAdd__details--row-info'>{user.name}</p>
                            <p className='groupsAdd__details--row-info'>{user.role}</p>
                            <div className='groupsAdd__details--row-info'>
                              <a
                                onClick={() => {
                                  handleAddUser({userName: user.name, userId: user._id})
                                }}
                                className={`btn btn-icon btn-light groupsAdd__details--row-button`}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr075.svg'
                                  className='svg-icon-2'
                                />
                              </a>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
                  )}
                </div>
              </div>
              <div style={{width: '100%'}}>
                <div className='groupsAdd__subtitle'>
                  <h2>Utilizatori adăugați în grup</h2>
                </div>
                <div className='groupsAdd__table'>
                  {selectedUsers.length ? (
                    <>
                      <div className='groupsAdd__details--row groupsAdd__table--row'>
                        <p className='groupsAdd__details--row-header'>Nume</p>
                        <p className='groupsAdd__details--row-header'>Rol</p>
                        <p className='groupsAdd__details--row-header'>Acțiune</p>
                      </div>
                      {selectedUsers.map((user: User) => {
                        return (
                          <div
                            key={`user-${user._id}`}
                            className='groupsAdd__details--row groupsAdd__details--user groupsAdd__table--row'
                          >
                            <p className='groupsAdd__details--row-info'>{user.name}</p>
                            <p className='groupsAdd__details--row-info'>{user.role}</p>
                            <div className='groupsAdd__details--row-info'>
                              <a
                                onClick={() => {
                                  handleDeleteUser({userName: user.name, userId: user._id})
                                }}
                                className='btn btn-icon btn-danger groupsAdd__details--row-button'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </a>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <p style={{padding: '2rem', margin: '0'}}>Nu au fost adăugați utilizatori.</p>
                  )}
                </div>
              </div>
            </div>

            {/* begin::Action */}
            <div className='d-grid'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='groupsAdd__action'
                style={{background: '#CE8D3B'}}
                disabled={
                  !!(
                    !formData.name ||
                    !formData.store_domain ||
                    !formData.store_ip ||
                    (isEqual(formData, originalGroup) &&
                      !groupImageRef.current.value &&
                      !usersModified)
                  )
                }
              >
                {!loading && <span className='indicator-label'>Editează grup</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
      </div>
      {showModal === 'add' && (
        <UserModal
          actionType={showModal}
          active={true}
          userName={userToAdd?.name}
          handleClose={() => {
            setShowModal('')
            setUserToAdd({name: '', id: '', group: id})
          }}
          handleAction={() => {
            addUser()
          }}
        />
      )}
      {showModal === 'delete' && (
        <UserModal
          actionType={showModal}
          active={true}
          userName={userToDelete?.name}
          handleClose={() => {
            setShowModal('')
            setUserToDelete({name: '', id: '', group: id})
          }}
          handleAction={() => {
            deleteUser()
          }}
        />
      )}
    </>
  )
}

const UserModal = ({active, userName, handleClose, handleAction, actionType}) => {
  return (
    <>
      <Modal show={active} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {actionType === 'add' ? 'Adaugă utilizator' : 'Șterge utilizator'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              {actionType === 'add' ? `Dorești să adaugi ` : 'Sigur ștergi '}
              {userName}?
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <MyButton variant='secondary' onClick={handleClose}>
            Închide
          </MyButton>
          <MyButton variant='primary' onClick={handleAction}>
            {actionType === 'add' ? 'Adaugă' : 'Șterge'}
          </MyButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}
