import {Form} from 'react-bootstrap'

const WarningMessage = () => {
  return (
    <>
      <Form.Text style={{color: 'red', fontSize: ' 15px'}}>
        Toate câmpurile sunt obligatorii!
      </Form.Text>
    </>
  )
}

export default WarningMessage
