import axios from 'axios'
import axiosConf from './_axiosInstance'
import {setAuth} from '../AuthHelpers'

export const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/users/login`
export const REGISTER_URL = `${API_URL}/users/signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/users/forgotPassword`
export const RESET_PASSWORD = `${API_URL}/users/resetPassword`
export const PATCH_TOKEN_URL = `${API_URL}/users/refreshToken`
export const ADD_NEW_USER = `${API_URL}/users/signupAdmin`
export const ADD_NEW_SUPER_USER = `${API_URL}/users/signupSuperAdmin`
export const UPDATE_USER_DETAILS_URL = `${API_URL}/users/me`

export async function login(email: string, password: string, device: string) {
  try {
    const response = await axios.post(`${API_URL}/users/login`, {
      email,
      password,
      device,
    })

    setAuth(response.data.data)

    return response
  } catch (err: any) {
    console.log('Error in login API:', err.response)
    // throw err
    throw err.response.data
  }
}

// export default axiosInstance

// Server should return AuthModel
export function register(
  name: string,
  email: string,
  password: string,
  passwordConfirm: string,
  role: string
) {
  return axiosConf
    .post(REGISTER_URL, {
      name,
      email,
      password,
      passwordConfirm,
      role,
    })

    .then((response) => {
      return response
    })
    .catch((err) => {
      console.log('err in registration api', err)
      throw err
    })
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axiosConf.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

export function requestPassword(email: string) {
  return axios
    .post(REQUEST_PASSWORD_URL, {email})
    .then((response) => {
      if (response.data.status === 'success') {
        return {success: true, message: response.data.message}
      } else {
        return {success: false, message: 'Unexpected response format.'}
      }
    })
    .catch((error) => {
      return {
        success: false,
        message: error.response?.data.message || 'Network error or server not responding.',
      }
    })
}

const RESET_PASSWORD_URL = '/users/resetPassword'

// Use the RESET_PASSWORD constant that includes the API_URL
export async function resetPassword(
  email: string,
  pin: string,
  password: string,
  passwordConfirm: string
) {
  try {
    const response = await axios.patch(RESET_PASSWORD, {email, pin, password, passwordConfirm})
    return {success: true, message: response.data.message}
  } catch (err) {
    console.error('Error in api of resetPass', err)
    return {
      success: false,
      message: err.response?.data.message || 'Network error or server not responding.',
    }
  }
}

export const getMyDetails = async () => {
  try {
    const response = await axiosConf.get(`${API_URL}/users/me`)
    return response.data.data.user
  } catch (err: any) {
    console.log('getMyDetails err -requests', err.response)
    return err.response
  }
}

export const getDashboardData = async () => {
  try {
    const response = await axiosConf.get(`${API_URL}/dashboard`)
    return response.data.data
  } catch (err: any) {
    console.log('Error in EP getDashboardDetails', err.response)
    return err.response
  }
}

export async function addNewUser(
  name: string,
  phone: string,
  role: string,
  email: string,
  password: string,
  passwordConfirm: string
) {
  try {
    const response = await axiosConf.post(ADD_NEW_USER, {
      name,
      phone,
      role,
      email,
      password,
      passwordConfirm,
    })
    return response
  } catch (err) {
    console.log('err in registration api', err)
    throw err
  }
}

export async function addNewSuperUser(
  name: string,
  phone: string,
  role: string,
  email: string,
  password: string,
  passwordConfirm: string,
  group: string
) {
  try {
    const response = await axiosConf.post(ADD_NEW_SUPER_USER, {
      name,
      phone,
      role,
      email,
      password,
      passwordConfirm,
      group,
    })
    return response
  } catch (err) {
    console.log('err in registration api', err)
    throw err
  }
}

export const updateProfilePicture = async (profilePicture: File) => {
  try {
    const formData = new FormData()
    formData.append('picture', profilePicture, profilePicture.name)

    const response = await axiosConf.put(`${API_URL}/users/me/profilePicture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response.data
  } catch (err: any) {
    console.error('Error in updateProfilePicture:', err.response)
    throw err.response
  }
}

export async function updateUserDetails(name: string, phone: string) {
  try {
    const response = await axiosConf.patch(UPDATE_USER_DETAILS_URL, {
      name,
      phone,
    })
    return response.data
  } catch (err: any) {
    console.error('Error in updateUserDetails:', err.response)
    throw err.response
  }
}

//create a new fuction to delete the profile picture by using this endpoint DELETE/users/me/profilePicture
export const deleteProfilePicture = async () => {
  try {
    const response = await axiosConf.delete(`${API_URL}/users/me/profilePicture`)
    return response.data
  } catch (err: any) {
    console.error('Error in deleteProfilePicture:', err.response)
    throw err.response
  }
}

