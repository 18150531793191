import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {editUsers} from '../../app/modules/auth/core/Requests/_requests_editUser'
import {getUserById} from '../../app/modules/auth/core/_requests_user'
import {getAllDeposits} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {useAuth} from '../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import './style/EditUser.scss'

interface UserData {
  name: string
  email: string
  phone: string
  deposit: string
  role: string
}

interface DepositData {
  id: string
  name: string
}

const MyButton: any = Button

const EditUsers = () => {
  const {currentUser} = useAuth()
  const {id} = useParams<{id: string}>()
  const navigate = useNavigate()
  const userID = id!.substring(0)
  const [deposits, setDeposits] = useState<DepositData[]>([])
  const [formData, setFormData] = useState<UserData>({
    name: '',
    email: '',
    phone: '',
    deposit: '',
    role: '',
  })

  useEffect(() => {
    getUserById(userID)
      .then((res) => {
        if (!res || !res.data || !res.data.user) {
          return
        }

        const userData = res.data.user
        setFormData({
          name: userData.name || '',
          email: userData.email || '',
          phone: userData.phone || '',
          deposit: userData.deposit?._id || '',
          role: userData.role || '',
        })
      })
      .catch((e) => console.error('Error in getUserById:', e))
  }, [userID])

  useEffect(() => {
    getAllDeposits({currentUser: currentUser}).then((res) => {
      setDeposits(res.data.deposits)
    })
  }, [])

  //  console.log(userID)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({...formData, [event.target.name]: event.target.value})
  }

  function handleEditUserSubmit() {
    editUsers(
      userID,
      formData.name,
      formData.email,
      formData.phone,
      formData.deposit,
      formData.role
    )
      .then((res) => {
        if (res.status === 'success') {
          // Redirect to UsersList page
          navigate('/users') // Update '/users' to the actual path where UsersList is rendered
          // Optionally, show a success notification/message to the user
        } else {
          // Handle scenarios where the update is not successful
          console.error('Update failed:', res)
          // Optionally, show an error notification/message to the user
        }
      })
      .catch((err) => {
        console.error('Error in Update User', err)
        // Handle the error scenario, show error message/notification
      })
  }

  return (
    <>
      <div className='users'>
        <div className='users__title'>
          <h1 className='card-title align-items start flex-column mb-0'>Utilizatori</h1>
        </div>
        <div className='users__separator' />
        <div className='users__container'>
          <div className='users__subtitle'>
            <h2>Editează utilizator</h2>
          </div>
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              handleEditUserSubmit()
            }}
          >
            <div className='users__section'>
              <Form.Group controlId='formName' className='mb-5'>
                <Form.Label className='form-label users__input--label'>Name</Form.Label>
                <input
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  className='form-control users__input'
                />
              </Form.Group>

              <Form.Group controlId='formEmail' className='mb-5'>
                <Form.Label className='form-label users__input--label'>Email address</Form.Label>
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  className='form-control users__input'
                  disabled
                />
              </Form.Group>

              <Form.Group controlId='formPhone' className='mb-5'>
                <Form.Label className='form-label users__input--label'>Phone</Form.Label>
                <input
                  type='text'
                  name='phone'
                  value={formData.phone}
                  onChange={handleChange}
                  className='form-control users__input'
                />
              </Form.Group>
              <Form.Group controlId='formPhone' className='mb-5'>
                <Form.Label className='form-label users__input--label'>Deposit</Form.Label>
                <select
                  onChange={handleChange}
                  value={formData.deposit}
                  name='deposit'
                  className='form-select users__input'
                >
                  <option>Selectează depozitul</option>
                  {deposits.map((deposit, i) => (
                    <option value={deposit.id} key={i}>
                      {deposit.name}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Group controlId='formPhone' className='mb-5'>
                <Form.Label className='form-label users__input--label'>Rol</Form.Label>
                <select
                  onChange={handleChange}
                  value={formData.role}
                  name='role'
                  className='form-select users__input'
                >
                  <option>Selectează rolul</option>
                  <option value='user'>User</option>
                  <option value='admin'>Admin</option>
                </select>
              </Form.Group>
            </div>
            <button className='users__action' type='submit'>
              Actualizează date
            </button>
          </Form>
        </div>
      </div>
    </>
  )
}

export default EditUsers
