import React, {useState, useEffect} from 'react'
import './style/EntityDeposit.scss'
import {getDepositById} from '../../../../app/modules/auth/core/Requests/_requestsDeposits'
import {Link} from 'react-router-dom'
import IFrame from '../../../Deposits/PreviewDeposits/IFrame'
import {useAuth} from '../../../../app/modules/auth'

interface Deposit {
  _id: string
  name: string
  address: string
  height: number
  width: number
}

const EntityDeposit = ({entity}) => {
  const {auth} = useAuth()

  const [adminStatus, setAdminStatus] = useState(false)
  const [iframeToken, setIframeToken] = useState('')
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const [selectedDepositId, setSelectedDepositId] = useState('')

  const loadDeposit = async (id: string) => {
    try {
      const token = auth?.accessToken
      const response = await fetch(`https://api.incom.ai/v1/deposits/${id}?token=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const data = await response.json()
      if (response.ok) {
        console.log('API Response:', data)
        setAdminStatus(data.data.admin)
        setIframeToken(data.data.token)
        setShowFullScreenModal(true)
        setSelectedDepositId(id)
      } else {
        console.error('API Error:', data.message)
      }
    } catch (error) {
      console.error('Request failed:', error)
    }
  }

  const [deposit, setDeposit] = useState<Deposit>()

  async function fetchDeposit() {
    await getDepositById(entity).then((res) => {
      setDeposit(res.data)
      console.log('deposit', res.data)
    })
  }

  useEffect(() => {
    fetchDeposit()
  }, [entity])

  return (
    <>
      {deposit && (
        <>
          <div className='entityDeposit'>
            <div className='entityDeposit__row'>
              <p className='entityDeposit__row--title'>Nume depozit:</p>
              <p className='entityDeposit__row--description'>{deposit?.name}</p>
            </div>
            <div className='entityDeposit__row'>
              <p className='entityDeposit__row--title'>Adresă:</p>
              <p className='entityDeposit__row--description'>{deposit?.address}</p>
            </div>
            <div className='entityDeposit__row'>
              <p className='entityDeposit__row--title'>Dimensiuni (WxH):</p>
              <p className='entityDeposit__row--description'>
                {deposit?.width + 'x' + deposit?.height}
              </p>
            </div>
            <div className='entityDeposit__row'>
              <p className='entityDeposit__row--title'></p>
              <Link
                onClick={() => loadDeposit(deposit?._id)}
                to=''
                className='entityDeposit__row--description entityDeposit__row--description-link'
              >
                Vezi depozit
              </Link>
            </div>
          </div>

          <IFrame
            show={showFullScreenModal}
            depositId={selectedDepositId}
            accessToken={auth?.accessToken}
            adminStatus={adminStatus}
            iframeToken={iframeToken}
            onClose={() => setShowFullScreenModal(false)}
          />
        </>
      )}
    </>
  )
}

export default EntityDeposit
