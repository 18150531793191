import React, {FC} from 'react'
import {LogsList} from './LogsList'

const Logs: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div>
        <div>
          <LogsList className='card-xl-stretch mb-xl-8' />
        </div>
      </div>
    </>
  )
}

export {Logs}
