import axiosConf from './_axiosInstance'

export const API_URL = process.env.REACT_APP_API_URL
export const GET_CUSTOMERS = `${API_URL}/customers`

export const getAllCustomers = async ({page, limit, email, group, currentUser}: any) => {
  let url = GET_CUSTOMERS
  const selectedGroup = localStorage.getItem('group')
  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (email) queryParams.append('email', email.toString())
  if (group) queryParams.append('group', group.toString())
  if (currentUser?.role === 'super_admin' && selectedGroup)
    queryParams.append('group', selectedGroup)

  const queryString = queryParams.toString()
  if (queryString) url += `?${queryString}`

  try {
    const res = await axiosConf.get(`${url}`)
    return res.data
  } catch (err) {
    console.log('error of get all customers EP', err)
    return err
  }
}

export const getGroups = async () => {
  try {
    const response = await axiosConf.get('/groups?status=active')
    return response.data
  } catch (err) {
    console.log('getGroups err', err)
    return err
  }
}
