import React from 'react'
import {Text} from '@react-three/drei'
import * as THREE from 'three'

interface StartPoint {
  position: [number, number, number]
  label: string
  color: string
}

const StartPoint: React.FC<StartPoint> = ({position, label, color}) => {
  return (
    <>
      <mesh receiveShadow
        position={[position[0], position[1] + 2.4, position[2]]} // Adjust mesh height above cylinder
      >
        {/* Mesh position on top of the deposit plane */}

        <cylinderGeometry args={[1, 0.1, 6, 32]} />
        <meshStandardMaterial color={color} />
      </mesh>
      <Text
        position={[position[0], position[1] + 6.1, position[2]]} // Adjust text height above cylinder
        color='green'
        fontSize={2}
        maxWidth={200}
        lineHeight={5}
        letterSpacing={0.02}
        textAlign='center'
        font='/path-to-your-font-file.ttf' // Make sure you have the font file in your public directory
        anchorX='center'
        anchorY='middle'
      >
        {label}
      </Text>
    </>
  )
}

export default StartPoint
