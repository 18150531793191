import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {Form, Button} from 'react-bootstrap'
import {getAllDeposits} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../app/modules/auth'
import {
  editReception,
  getReceptionById,
} from '../../app/modules/auth/core/Requests/_requestsReceptions'

interface RecData {
  id: string
  user: {
    name: string
    email: string
    phone: string
  }
  deposit: string
  checks: {
    packing: boolean
    labeling_integrity: boolean
    products_identification: boolean
    seals_integrity: boolean
    marking_integrity: boolean
  }

  products_status: {
    new_products: {
      sku: string
      qty: number
    }
    existing_products: {
      sku: string
      name: string
      qty: number
      old_qty: number
      new_qty: number
    }
  }
  // documents: [
  //   {

  //     fileName: string
  //     filePath: string
  //   }
  // ],
}

interface DepositData {
  id: string
  name: string
}

const MyButton: any = Button

export function EditReception() {
  const {currentUser} = useAuth()
  const {id} = useParams<{id: string}>()
  const navigate = useNavigate()
  const recID = id!.substring(1)
  const [deposits, setDeposits] = useState<DepositData[]>([])
  const [editData, setEditData] = useState<RecData>({
    id: '',
    user: {
      name: '',
      email: '',
      phone: '',
    },
    deposit: '',
    checks: {
      labeling_integrity: false,
      packing: false,
      products_identification: false,
      seals_integrity: false,
      marking_integrity: false,
    },
    products_status: {
      new_products: {
        sku: '',
        qty: 0,
      },
      existing_products: {
        sku: '',
        qty: 0,
        name: '',
        old_qty: 0,
        new_qty: 0,
      },
    },
    // documents:[
    //     {

    //         fileName:'',
    //         filePath:''
    //     }
    // ],
  })

  useEffect(() => {
    getReceptionById(recID)
      .then((res) => {
        console.log('res in ViewRec', res)
        setEditData(res.data.reception)
      })
      .catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    getAllDeposits({currentUser: currentUser}).then((res) => {
      console.log('DEPOSITSSSSSSSSSSSSSS', res)
      setDeposits(res.data.deposits)
    })
  }, [])

  console.log(recID)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setEditData({...editData, [event.target.name]: event.target.value})
    console.log(editData, 'blaaa')
  }

  // function handleEditRecSubmit() {
  //   editReception(recID, editData.user, editData.deposit, editData.checks, editData.products_status)
  //     .then((res) => {
  //       console.log('RES IN UPDATE RECEPTION', res)
  //       if (res.status == 'success') {
  //         navigate(`/reception/:${recID}`)
  //       }
  //     })
  //     .catch((err) => console.log('ERR IN UPDATE RECEPTION', err))
  // }

  return (
    <div className='container mt-4'>
      <h2 className='mb-10 text-light'>Editează recepție</h2>
      <Form
        onSubmit={(event) => {
          event.preventDefault()
          // handleEditRecSubmit()
          console.log('FORM DATA ON SUBMIT', editData)
        }}
      >
        <Form.Group controlId='formName'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Nume</Form.Label>
          <input
            type='text'
            name='name'
            value={editData.user.name}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>

        <Form.Group controlId='formEmail'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Email</Form.Label>
          <input
            type='email'
            name='email'
            value={editData.user.email}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>

        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Telefon</Form.Label>
          <input
            type='text'
            name='phone'
            value={editData.user.phone}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Depozit</Form.Label>
          <select
            onChange={handleChange}
            value={editData.deposit}
            name='deposit'
            className='form-select  bg-transparent'
          >
            <option>Selectează depozitul</option>
            {deposits.map((deposit, i) => (
              <option value={deposit.id} key={i}>
                {deposit.name}
              </option>
            ))}
          </select>
        </Form.Group>
        <div className='mb-10 mt-10'>
          <h2 className='text-light'>Status produse</h2>
        </div>
        <div className='mb-10'>
          <h3 className='text-light'>New products</h3>
        </div>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Sku</Form.Label>
          <input
            type='text'
            name='sku'
            value={editData.products_status.new_products.sku}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Quantity</Form.Label>
          <input
            type='text'
            name='quantity'
            value={editData.products_status.new_products.qty}
            onChange={handleChange}
            className='form-control form-control-solid  bg-transparent'
          />
        </Form.Group>
        <div className='mb-10 mt-10'>
          <h3>Existing products</h3>
        </div>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Sku</Form.Label>
          <input
            type='text'
            name='sku'
            value={editData.products_status.existing_products.sku}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Name</Form.Label>
          <input
            type='text'
            name='name'
            value={editData.products_status.existing_products.name}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Quantity</Form.Label>
          <input
            type='text'
            name='quantity'
            value={editData.products_status.existing_products.qty}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>Old quantity</Form.Label>
          <input
            type='text'
            name='old quantity'
            value={editData.products_status.existing_products.old_qty}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>
        <Form.Group controlId='formPhone'>
          <Form.Label className='form-label fw-bolder text-light fs-6'>New Quantity</Form.Label>
          <input
            type='text'
            name='new quantity'
            value={editData.products_status.existing_products.new_qty}
            onChange={handleChange}
            className='form-control form-control-solid bg-transparent'
          />
        </Form.Group>

        {/* <Link to='/users'>  */}
        <MyButton className='mt-5' type='submit' style={{background: '#CE8D3B'}}>
          Actualizează recepție
        </MyButton>
        {/* </Link> */}
      </Form>
    </div>
  )
}
