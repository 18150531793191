/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getMyDetails, register} from '../core/Requests/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
// const Yup = require('yup');
const initialValues = {
  name: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
  role: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de carctere')
    .required('Numele este obligatoriu'),
  email: Yup.string()
    .email('Formatul emailului este incorect')
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de carctere')
    .required('Emailul este obligatoriu'),
  password: Yup.string()
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de carctere')
    .required('Parola este obligatorie'),
  changepassword: Yup.string().required('Confirmarea parolei este obligatorie'),
  // .when('password', {
  //   is: (password: string | undefined) => !!password,
  //   then: Yup.string().oneOf([Yup.ref('password')], 'Parolele nu coincid'),
  //   otherwise: Yup.string(),
  // }),
  acceptTerms: Yup.bool().required('Trebuie sa accepti termenii si conditiile'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  // const {saveAuth, setCurrentUser} = useAuth()
  const [showModal, setShowModal] = useState(false)

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setShowModal(true)
      try {
        const {data: auth} = await register(
          values.name,
          values.email,
          values.password,
          values.changepassword,
          values.role
        )
        // saveAuth(auth)
        console.log('AUTH IN REGISTER.tsx INFO', auth, values)
        // const {data: user} = await getUserByToken(auth.token)
        // const data = await getMyDetails()
        // navigate('/auth/login')

        // setCurrentUser(auth)
        console.log('====testing for registration.tsx redirect====')
      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        setStatus('Datele nu sunt corecte')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          {/* begin::Title */}
          <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
          {/* end::Title */}
        </div>
        {/* end::Heading */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Nume complet</label>
          <input
            placeholder='Nume'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Parola</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Parola'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Folosește 8 sau mai multe caractere cu litere, numere și simboluri.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirmă parola</label>
          <input
            type='password'
            placeholder='Parola'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <span>
              Accept{''}
              <a href='#' target='_blank' className='ms-1 link-primary'>
                termenii și condițiile
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            onClick={() => console.log('testing in registration.tsx')}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Înscrie-te</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Așteaptă...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Contul a fost creat</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <p>
                Pentru a te loga, activează-ți contul in maxim 15 minute din link-ul trimis pe mail.
                Dacă nu l-ai găsit verifică și folderul 'Spam'.
              </p>
            </div>
            <div className='modal-footer'>
              <button
                data-bs-dismiss='modal'
                type='button'
                className='btn btn-primary'
                onClick={() => {
                  setLoading(false)
                  navigate('/auth/login')
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
