import './PaginationControls.scss'

// @ts-ignore
const PaginationControls = ({currentPage, totalPages, limit, setCurrentPage}) => {
  // Function to generate the range of page numbers to display
  const generatePageNumberRange = () => {
    const visiblePages = 4
    let start = Math.max(1, currentPage - visiblePages)
    let end = Math.min(totalPages, currentPage + visiblePages)

    // Ensure that the first 4 and last 4 pages are always included
    if (currentPage <= visiblePages) {
      end = visiblePages * 2
    } else if (currentPage > totalPages - visiblePages) {
      start = totalPages - (visiblePages * 2 - 1)
    }

    // Adjust start and end if they go out of bounds
    start = Math.max(1, start)
    end = Math.min(totalPages, end)

    const range = []
    for (let i = start; i <= end; i++) {
      range.push(i)
    }
    return range
  }

  const pageNumbers = generatePageNumberRange()

  return (
    <div style={{display: 'flex', justifyContent: 'center', gap: '8px'}}>
      <button
        onClick={() => setCurrentPage(1)}
        disabled={currentPage === 1}
        className='backgroundPrimary'
      >
        First
      </button>
      <button
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
        className='backgroundPrimary'
      >
        Previous
      </button>

      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => setCurrentPage(number)}
          disabled={number === currentPage}
          className='backgroundPrimary'
        >
          {number}
        </button>
      ))}

      <button
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className='backgroundPrimary'
      >
        Next
      </button>
      <button
        onClick={() => setCurrentPage(totalPages)}
        disabled={currentPage === totalPages}
        className='backgroundPrimary'
      >
        Last
      </button>
    </div>
  )
}

export default PaginationControls
