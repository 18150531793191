/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold fs-base'>
            {/* <a href='/terms' className='px-5' style={{color: 'black'}} target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' style={{color: 'black'}} target='_blank'>
              Plans
            </a> */}
            <a
              href='/terms'
              style={{
                color: '#ce8d3b',
                fontWeight: '500',
              }}
            >
              Termeni și condiții
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundColor: '#656330'}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <div className='mb-12'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/admin_logo_incom.png')}
              className='h-170px'
            />
          </div>
          {/* end::Logo */}
          <div className='d-flex'>
            <div className='mb-12'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/fonduri-ue.png')}
                style={{
                  height: '120px',
                  background: '#fff',
                }}
              />
            </div>
          </div>
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
