import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import Cookies from 'js-cookie'
import * as authHelper from '../AuthHelpers'

export const baseUrl = 'https://api.incom.ai/v1'

// Create an instance of axios with a baseURL
export const axiosConf: AxiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${authHelper.getAuth()?.accessToken}`,
  },
})

const refreshTokenFn = async () => {
  // Get the refresh token from cookies

  // Assuming getAuth() is a function that retrieves your authentication
  const refreshToken = authHelper.getAuth()?.refreshToken
  // console.log('refreshToken', refreshToken);

  try {
    // Send a PATCH request to the refreshToken endpoint to obtain a new access token
    const response = await axiosConf.patch('/users/refreshToken', {refreshToken: refreshToken})
    console.warn(response)
    const data = response.data.data

    // console.log('refreshTokenFn', data);
    authHelper.setAuth(data)

    const accessToken = data?.accessToken
    const newRefreshToken = data?.refreshToken!

    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;

    // console.log('ACCES TOKEN', accessToken)
    // console.log('REFRESH TOKEN', newRefreshToken)

    return data
  } catch (error) {
    console.warn('refresh Error: ', error)


    // @ts-ignore
    if (error?.response?.status === 400) {
      // @ts-ignore
      console.error(error?.response)
    }
    console.log('step error')

    if (error?.response?.status === 401) {
      console.log('redirect to /auth');
      window.location.href = '/auth'
    }
  }
}

// Add a request interceptor to add the authentication token to the request headers
axiosConf.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const accessToken = authHelper.getAuth()?.accessToken
  // console.log('axiosConf.interceptors accesToken', accessToken);

  config.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  }
  return config
})

// Add a response interceptor to retrieve the response data from the server
axiosConf.interceptors.response.use(
  (response: AxiosResponse) => {
    // If the request was successful, return the response
    return response
  },
  async function (error: any) {
    // If the request failed, get the original request configuration
    const originalRequest = error?.config

    // If the error status is 401 (unauthorized) and the request hasn't been retried yet
    if (error?.response?.status === 401 && !originalRequest._retry) {
      // Mark the request as retried
      originalRequest._retry = true
      // console.log('retry refresh token');

      const result = await refreshTokenFn()
      // console.log('STEP8', result)
      return axiosConf(originalRequest)
    }

    // Return the error response if the request can't be retried
    return Promise.reject(error)

  }
)

export default axiosConf
