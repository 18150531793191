import axios from 'axios'
import axiosInstance from './_axiosInstance'
import axiosConf from './_axiosInstance'

export const API_URL = process.env.REACT_APP_API_URL

export const GET_DEPOSITS = `${API_URL}/deposits`
export const CREATE_DEPOSITS = `${API_URL}/deposits`

export const getAllDeposits = async ({page, limit, search, currentUser}: any) => {
  let url = GET_DEPOSITS
  const selectedGroup = localStorage.getItem('group')

  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (search) queryParams.append('search', search)
  if (currentUser?.role === 'super_admin' && selectedGroup)
    queryParams.append('group', selectedGroup)

  const queryString = queryParams.toString()
  if (queryString) url += `?${queryString}`

  try {
    const response = await axiosConf.get(url)

    return response.data
  } catch (err: any) {
    console.log('get ALL DEPOSITS err', err.response)
    return err
  }
}

export const getDepositById = async (id: any) => {
  try {
    const response = await axiosConf.get(`${GET_DEPOSITS}/${id}?tree=true`)
    return response.data
  } catch (err) {
    console.log('get DEPOSIT BY ID err', err)
    return err
  }
}

export const editDeposit = async (id: any, name: string, address: string) => {
  try {
    const response = await axiosConf.patch(`${GET_DEPOSITS}/${id}`, {name, address})
    return response.data
  } catch (err) {
    console.log('EDIT DEPOSIT err', err)
    return err
  }
}

export const createDeposit = async (
  name: string,
  address: string,
  width: number,
  height: number,
  startPoint: {x: number; y: number}
) => {
  try {
    const response = await axiosConf.post(CREATE_DEPOSITS, {
      name,
      address,
      width,
      height,
      startPoint,
    })
    return response.data
  } catch (err: any) {
    console.log('CREATE_DEPOSITS err', err)
    return err
  }
}

export const deleteDepositById = async (id: string) => {
  try {
    const response = await axiosConf.delete(`${GET_DEPOSITS}/${id}`)
    return response.data
  } catch (err) {
    console.log('delete DEPOSIT BY ID err', err)
    return err
  }
}
