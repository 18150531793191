import React, {useEffect, useState} from 'react'
import * as THREE from 'three'
import concrete_0019_color from '../assets/textures/deposit/concrete_0019_color.jpg'
import concrete_0019_normal_opengl from '../assets/textures/deposit/concrete_0019_normal_opengl.png'
import concrete_0019_roughness from '../assets/textures/deposit/concrete_0019_roughness.jpg'
import concrete_0019_ao_1k from '../assets/textures/deposit/concrete_0019_ao_1k.jpg'
import concrete_0019_height from '../assets/textures/deposit/concrete_0019_height.png'


const Material = () => {
  const [material, setMaterial] = useState<THREE.MeshStandardMaterial | null>(
    null
  );

  useEffect(() => {
    const textureLoader = new THREE.TextureLoader();

    // Define the type for the function that loads textures
    const loadTexture = (path: string): Promise<THREE.Texture> => {
      return new Promise((resolve, reject) => {
        textureLoader.load(path, resolve, undefined, (error) => {
          console.error(`Failed to load texture: ${path}`, error);
          reject(error);
        });
      });
    };

    const planeTexturePaths: string[] = [
      "../assets/textures/deposit/concrete_0019_ao_1k.jpg",
      "../assets/textures/deposit/concrete_0019_color_1k.jpg",
      "../assets/textures/deposit/concrete_0019_height_1k.png",
      "../assets/textures/deposit/concrete_0019_normal_directx_1k.png",
      "../assets/textures/deposit/concrete_0019_roughness_1k.jpg",
    ];

    // Async function to load textures and create material
    const createMaterial = async () => {
      try {
        console.log('Loading textures...');
        // Load all textures asynchronously
        const textures = await Promise.all(planeTexturePaths.map(path => {
            console.log('texture path:', path);
            return loadTexture(path)
          }
        ));
        console.log('Loaded textures:', textures);

        // Adjust the texture repeat for zooming out effect
        const textureRepeat = new THREE.Vector2(16, 16);
        textures.forEach(texture => {
          if (texture) {
            texture.repeat.copy(textureRepeat);
            texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
          }
        });

        const [aoMap, map, displacementMap, normalMap, roughnessMap] = textures;

        const newMaterial = new THREE.MeshStandardMaterial({
          map,
          aoMap,
          displacementMap,
          normalMap,
          roughnessMap,
        });

        setMaterial(newMaterial);
      } catch (error) {
        console.error("Error loading textures:", error);
      }
    };

    // Call the async function
    createMaterial();

  }, []); // Empty dependency array means this effect runs once on mount


  return material;
};


export default Material
