import React, {FC} from 'react'
import {GroupsList} from './GroupsList'

const Groups: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div>
        <div>
          <GroupsList className='card-xl-stretch mb-xl-8' />
        </div>
      </div>
    </>
  )
}

export {Groups}
