import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../core/Requests/_requests'
import {useAuth} from '../core/Auth'

const userResetEmail = localStorage.getItem('resetPassEmail') || ''

const initialValues = {
  email: userResetEmail,
  pin: '',
  password: '',
  passwordConfirm: '',
}

const resetPasswordSchema = Yup.object().shape({
  pin: Yup.string()
    .required('PIN-ul este obligatoriu')
    .min(8, 'PIN-ul are prea puține caractere')
    .max(8, 'PIN-ul are prea multe caractere'),
  password: Yup.string()
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de carctere')
    .required('Parola este obligatorie'),
  passwordConfirm: Yup.string()
    .required('Confirmarea parolei este obligatorie')
    .oneOf([Yup.ref('password')], 'Parolele nu coincid'),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const {setCurrentUser} = useAuth()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      resetPassword(values.email, values.pin, values.password, values.passwordConfirm)
        .then((response) => {
          if (response.success) {
            navigate('/dashboard')
          } else {
            // Handle failure based on the message from the response
            throw new Error(response.message)
          }
        })
        .catch((error) => {
          setHasErrors(true)
          setStatus(error.message || 'An error occurred during password reset.')
          setSubmitting(false)
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Resetează parola</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Introdu PIN-ul trimis pe adresa ta de e-mail și noua parolă.
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Datele sunt incorecte. Încearcă din nou.
          </div>
        </div>
      )}
      {/* end::Title */}


      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder='exemplu@domeniu.com'
          autoComplete='email'
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.email && formik.errors.email,
            'is-valid': formik.touched.email && !formik.errors.email,
          })}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>PIN</label>
        <input
          type='text'
          placeholder='ex: ABCDEFGH'
          autoComplete='off'
          {...formik.getFieldProps('pin')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.pin && formik.errors.pin,
            'is-valid': formik.touched.pin && !formik.errors.pin,
          })}
        />
        {formik.touched.pin && formik.errors.pin && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.pin}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Parola</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Parola'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Folosește 8 sau mai multe caractere cu litere, numere și simboluri.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmă parola</label>
        <input
          type='password'
          placeholder='Parola'
          autoComplete='off'
          {...formik.getFieldProps('passwordConfirm')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.passwordConfirm && formik.errors.passwordConfirm,
            },
            {
              'is-valid': formik.touched.passwordConfirm && !formik.errors.passwordConfirm,
            }
          )}
        />
        {formik.touched.passwordConfirm && formik.errors.passwordConfirm && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.passwordConfirm}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-primary me-4'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          <span className='indicator-label'>Trimite</span>
          {loading && (
            <span className='indicator-progress'>
              Așteaptă...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Înapoi
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
