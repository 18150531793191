import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import RangeItem from './components/RangeItem'
import axiosConf from '../../app/modules/auth/core/Requests/_axiosInstance'
import {CREATE_DEPOSITS} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {useNavigate, useParams} from 'react-router'
import RangeItemShelf from './components/RangeItemShelf'
import RangeItemColumn from './components/RangeItemColumn'
import './style/CreateDepositSecond.scss'

export const API_URL = process.env.REACT_APP_API_URL

interface Point {
  row: number
  col: number
}

interface Range {
  start: Point
  end: Point
  name: string
  id: string
  // fata: any
}

// Define an interface for the component's props
interface CreateColumnSecondProps {
  scaleData: any // Replace 'any' with a more specific type if possible
  widthData: any
  heightData: any
  areaData: any
  depositId: any
}

const CreateColumnSecond: React.FC<CreateColumnSecondProps> = ({
                                                                 scaleData,
                                                                 widthData,
                                                                 heightData,
                                                                 areaData,
                                                                 depositId,
                                                               }) => {
  const areaId = areaData?._id

  // console.log('areaData CreateShelfSecond', areaData, areaId)
  const areaheight = areaData.coordinates.second.y - areaData.coordinates.first.y
  const areawidth = areaData.coordinates.second.x - areaData.coordinates.first.x

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [height, setHeight] = useState(areaheight)
  const [width, setWidth] = useState(areawidth)
  const [scale, setScale] = useState(1)

  const [startPoint, setStartPoint] = useState<Point | null>(null)
  const [endPoint, setEndPoint] = useState<Point | null>(null)
  const [ranges, setRanges] = useState<Range[]>([])

  const [columnCreated, setColumnCreated] = useState(false)

  useEffect(() => {
    // if (areaData.columns.length > 0) {
    const newRanges = areaData.columns.map((area: any) => {
      const start: Point = {row: area.coordinates.first.x, col: area.coordinates.first.y}
      const end: Point = {row: area.coordinates.second.x, col: area.coordinates.second.y}
      return {
        start,
        end,
        name: area.name,
        id: area._id,
        // fata: 1,
      }
    })


    setRanges(newRanges)
    // }

    setWidth(areawidth)
    setHeight(areaheight)
  }, [areaData])

  const updateRangeName = (index: number, newName: string) => {
    const newRanges = [...ranges]
    newRanges[index] = {...newRanges[index], name: newName}
    setRanges(newRanges)
  }

  const handleWidthChange = (e: any) => {
    setWidth(Number(e.target.value))
  }

  const handleHeightChange = (e: any) => {
    setHeight(Number(e.target.value))
  }
  const handleScaleChange = (e: any) => {
    setScale(Number(e.target.value))
  }

  // handleChangeInputByName is used to update the state of the input fields by name of input
  const handleChangeInputByName = (e: any) => {
    const inputName = e.target.name
    if (inputName === 'name') {
      setName(e.target.value)
    } else if (inputName === 'address') {
      setAddress(e.target.value)
    }
  }

  const handleCellClick = (row: number, col: number) => {
    let rowScale = row * scale
    let colScale = col * scale
    if (!startPoint) {
      setStartPoint({row: rowScale, col: colScale})
    } else if (!endPoint) {
      // Generate a default name for the range, e.g., "Range 1", "Range 2", etc.
      const defaultName = `Coloana ${ranges.length + 1}`
      setEndPoint({row: row + scale, col: col + scale})
      setRanges([
        ...ranges,
        {
          start: startPoint,
          end: {row: rowScale + scale, col: colScale + scale},
          name: defaultName,
          id: null,
        },
      ])
      setStartPoint(null)
      setEndPoint(null)
    }
  }

  const isCellInRange = (row: number, col: number) => {
    return ranges.some((range) => {
      const rowInRange = row >= range.start.row && row < range.end.row
      const colInRange = col >= range.start.col && col < range.end.col
      return rowInRange && colInRange
    })
  }

  // get cell range index
  const getCellRangeIndex = (row: number, col: number) => {
    return ranges.findIndex((range) => {
      const rowInRange = row >= range.start.row && row < range.end.row
      const colInRange = col >= range.start.col && col < range.end.col
      return rowInRange && colInRange
    })
  }

  const isStartPoint = (row: number, col: number) => {
    return startPoint && !endPoint && startPoint.row === row && startPoint.col === col
  }

  const deleteRange = async (index: number, id: string) => {
    const newRanges = [...ranges]
    const updatedRanges = newRanges.filter(item => item.id !== id);
    setRanges(updatedRanges)

    if (id !== null) {
      try {
        const response = await axiosConf.delete(`${API_URL}/locations/${id}`)
        // console.log('DELETE_RANGE res', response)
        if (response.status === 204) {

          alert('Coloana stearsa cu succes!')
        }
      } catch (err: any) {
        // console.log('DELETE_RANGE err ' + id + ': ', err)
        alert('Eroare la stergerea coloanei ' + id)
      }
    }
  }

  const colorsArray = [
    '#FCAB64',
    '#FCD29F',
    '#A1FCDF',
    '#7FD8BE',
    '#FCEFEF',
    '#CDB4DB',
    '#FFC8DD',
    '#FFAFCC',
    '#BDE0FE',
    '#A2D2FF',
  ]

  const renderTable = () => {
    let table = []
    const columnWidth = width > 0 ? `${100 / width}%` : 'auto'

    let key = 0
    let scaleVal = scale > 1 ? scale : 1
    let scaleWidth = width / scaleVal
    let scaleHeight = height / scaleVal
    for (
      let i = areaData.coordinates.first.x / scaleVal;
      i < areaData.coordinates.second.x / scaleVal;
      i++
    ) {
      let row = []
      for (
        let j = areaData.coordinates.first.y / scaleVal;
        j < areaData.coordinates.second.y / scaleVal;
        j++
      ) {
        key++

        const inRange = isCellInRange(i * scaleVal, j * scaleVal)
        const isStart = isStartPoint(i * scaleVal, j * scaleVal)
        const indexRange = getCellRangeIndex(i * scaleVal, j * scaleVal)

        row.push(
          <td
            key={`cell-${i}-${j}`}
            className=' font-size-xs p-0 text-center'
            onClick={() => handleCellClick(i, j)}
            style={{
              backgroundColor: inRange
                ? colorsArray[indexRange]
                : isStart
                  ? 'lightblue'
                  : 'transparent',
              width: columnWidth,
              minHeight: `100 - ${scaleHeight}vw`,
              fontSize: '9px',
            }}
          >{`C-F(${i * scaleVal}-${j * scaleVal})-S(${i * scaleVal + scaleVal}-${
            j * scaleVal + scaleVal
          })`}</td>
        )
      }
      table.push(
        <tr key={`row-${i}`} className=''>
          {row}
        </tr>
      )
    }

    return <tbody>{table}</tbody>
  }

  const grid = () => {
    let scaleVal = 1
    let scaleHeight = height / scaleVal
    let scaleWidth = width / scaleVal

    // Calculate the square size based on the viewport width and the number of columns
    let squareSize = Math.floor((window.innerWidth - (window.innerWidth * 25) / 100) / scaleHeight)
    squareSize = squareSize > 200 ? squareSize / 4 : squareSize
    squareSize = squareSize > 50 ? squareSize / 1.5 : squareSize

    // Generate the grid's cells
    const cells = []

    for (let i = 0; i < scaleHeight; i++) {
      cells.push(i)
    }

    // Generate grid squares
    const squares = []
    for (let y = 0; y < scaleWidth; y++) {
      squares.push(cells)
    }

    return (
      <div>
        {squares.map((row, indexR) => {
          return (
            <div
              className='grid-container'
              key={indexR}
              style={{maxWidth: `${scaleHeight * squareSize}px`}}
            >
              {row.map((cell, indexC) => {
                const indecRCoordonate = indexR + areaData.coordinates.first.x / scaleVal
                const indecCCoordonate = indexC + areaData.coordinates.first.y / scaleVal

                const inRange = isCellInRange(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )
                const isStart = isStartPoint(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )
                const indexRange = getCellRangeIndex(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )

                return (
                  <div
                    key={indexC}
                    className='grid-cell'
                    onClick={() => handleCellClick(indecRCoordonate, indecCCoordonate)}
                    style={{
                      backgroundColor: inRange
                        ? colorsArray[indexRange]
                        : isStart
                          ? 'lightblue'
                          : 'transparent',
                      width: `${squareSize}px`,
                      height: `${squareSize}px`,
                      fontSize: `0`,
                      color: inRange ? 'black' : 'white',
                    }}
                  >
                    {/*{indexR + indexS * scaleHeight + 1}*/}
                    {`x(${indecRCoordonate * scaleVal}-${indecCCoordonate * scaleVal})-y(${
                      indecRCoordonate * scaleVal + scaleVal
                    }-${indecCCoordonate * scaleVal + scaleVal})`}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const createColumns = () => {
    const areas = {
      ...ranges,
      width,
      height,
    }

    ranges.forEach(async (column, index) => {

      // add only column with id null
      let rangeEndRow = column.end.row * scale
      let rangeEndCol = column.end.col * scale
      let widthMax = areaData.coordinates.second.x
      let heightMax = areaData.coordinates.second.y
      let ShelfData = {
        name: column.name,
        type: 'column',
        parent: areaId,
        // face: Number(column.fata),
        coordinates: {
          first: {
            x: column.start.row * scale,
            y: column.start.col * scale,
          },
          second: {
            x: rangeEndRow > widthMax + scale ? rangeEndRow - scale : rangeEndRow,
            y: rangeEndCol > heightMax + scale ? rangeEndCol - scale : rangeEndCol,
          },
        },
      }

      // if column have same name and coordonates with areaData, then ignore them
      // if column have different name and coordonates with areaData, then update/patch range with new name and
      // coordonates if column have new name and coordonates, then create new range
      if (column.id !== null) {
        try {
          const response = await axiosConf.patch(`${API_URL}/locations/${column.id}`, ShelfData)
          if (response) {
            console.log('res', response.data)
          }
        } catch (err: any) {
          alert(
            'Eroare la actualizarea coloanei ' +
            column.name +
            '! Coloane intersectate sau dimensiuni depasite!'
          )
        }

      } else {

        try {
          const response = await axiosConf.post(`${API_URL}/locations`, ShelfData)
          if (response) {
            console.log('res', response.data)
          }
        } catch (err: any) {
          alert(
            'Eroare la crearea coloanei ' +
            column.name +
            '! Coloane intersectate sau dimensiuni depasite!'
          )
        }
      }
    })

    setColumnCreated(true)
  }

  return (
    <div>
      <div className='deposit__section--title'>
        <h2>
          Aspect raft: {areaData.name}{' '}
          <span>(Coloanele raftului se selectează de sus în jos, de la stânga la dreapta)</span>
        </h2>
      </div>

      <div className='deposit__section'>
        <div className='mb-3'>
          <label htmlFor='scaleInput' className='form-label deposit__input--label'>
            Scalare (lungime sau latime / scalare) pentru reprezentare grafica
          </label>
          <input
            type='text'
            className='form-control deposit__input'
            id='scaleInput'
            value={1}
            // value={scale}
            onChange={handleScaleChange}
            readOnly={true}
          />
        </div>
        <div style={{overflowX: 'auto'}}>
          <div className='d-flex justify-content-between'>
            <h4 className=' font-weight-bold'>0</h4>
            <h4 className=' font-weight-bold'>{height}</h4>
          </div>
          {/*<table*/}
          {/*  className='table table-bordered invisible-text'*/}
          {/*  style={{*/}
          {/*    tableLayout: 'fixed',*/}
          {/*    width: '100%',*/}
          {/*    height: `${height / scale}vw`,*/}
          {/*    minHeight: '500px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {renderTable()}*/}
          {/*</table>*/}
          {grid()}
          <h4 className=' font-weight-bold'>{width}</h4>
        </div>
      </div>
      {ranges.length > 0 && (
        <div>
          <div className='deposit__section--title'>
            <h2>Zonele depozitului: {areaData.name}</h2>
          </div>
          <div className='deposit__section deposit__table'>
            <div className='deposit__table--header'>
              <p className='deposit__table--header-cell'>Nume</p>
              <p className='deposit__table--header-cell'>Arie (rand/coloana)</p>
              <p className='deposit__table--header-cell'>Acțiune</p>
            </div>
            {ranges.map((range, index) => {
              // console.log(range, index);
              return (
              <RangeItemColumn
                key={range.id}
                range={range}
                scale={scale}
                width={width}
                height={height}
                onDelete={() => deleteRange(index, range.id)}
                onNameChange={(val) => updateRangeName(index, val.name)}
              />
            )
            })}
          </div>
        </div>
      )}
      {!columnCreated && ranges.length > 0 && (
        <div className='deposit__create'>
          <div>
            <button className='deposit__action' onClick={createColumns}>
              Salvare Coloane
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(CreateColumnSecond)
