import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router'
import {
  addLocalImage,
  deletePicture,
  editProduct,
  getProductById,
  setStockAvailable,
} from '../../app/modules/auth/core/Requests/_requestsProduct'
import axiosConf from '../../app/modules/auth/core/Requests/_axiosInstance'
import {KTSVG} from '../../_metronic/helpers'
import WarningMessage from '../Misc/WarningMessage'
import './style/EditProduct.scss'

interface Barcode {
  fileName: string
  filePath: string
}
interface FormData {
  name: string
  sku: string
  price_wt: number | undefined
  remote_pictures: {fileName: string; filePath: string; _id: string}[]
  ean: string
  isbn: string
  barcode: Barcode | null // Adjust this to nullable object
}
interface Input {
  id: number
  name: string
  value: string
}
type FileObject = {
  uri: string
  name: string
  type: string
  file: File
}

interface Stock {
  length: number
  map(arg0: (prod: any) => void): import('react').ReactNode
  stock: [deposit: {id: string}, qty: string]
}

interface ProdPerDeposit {
  deposit: {id: string; name: string}
  qty: number
}
interface ProdPerDepositProps {
  productID: string
  deposit: {id: string; name: string}
  qty: number
}

interface RemotePictures {
  [x: string]: any
  fileName: string
  filePath: string
  _id: string
}

interface NewPicturesSchema {
  fileName: string
  filePath: string
  _id: string
}

interface NewPicturesPayload extends Omit<NewPicturesSchema, '_id'> {}

const MyButton: any = Button

const EditProduct = () => {
  const [productStock, setProductStock] = useState<Stock>()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({type: '', content: ''})
  const [inputs, setInputs] = useState<Input[]>([{id: 0, name: '', value: ''}])
  const [formData, setFormData] = useState<FormData>({
    name: '',
    sku: '',
    price_wt: 0,
    remote_pictures: [{fileName: 'remoteImg', filePath: '', _id: ''}],
    ean: '',
    isbn: '',
    barcode: {fileName: '', filePath: ''},
  })

  const [selectedFiles, setSelectedFiles] = useState<FileObject[]>([])
  const [remotePictures, setRemotePictures] = useState<RemotePictures[]>([])
  const [editProdInput, setEditProdInput] = useState<NewPicturesSchema[]>([])
  const [showModal, setShowModal] = useState(false)
  const baseUrl = 'https://api.incom.ai'
  const [localPictures, setLocalPictures] = useState([])
  const [selectedDepositDetails, setSelectedDepositDetails] = useState<ProdPerDepositProps>({
    productID: '',
    deposit: {id: '', name: ''},
    qty: 0,
  })
  const [dummyRender, setDummyRender] = useState(false)

  const {id} = useParams<{id: string}>()
  const productID = id!.substring(0)
  const navigate = useNavigate()

  useEffect(() => {
    getProductById(productID)
      .then((res) => {
        setFormData({
          ...res.data.product,
          barcode: res.data.product.barcode || null,
        })
        setProductStock(res.data.stock)
        const fetchedRemotePictures =
          res.data.product.remote_pictures.length > 0
            ? res.data.product.remote_pictures
            : [{fileName: '', filePath: '', _id: 'new-0'}]
        setRemotePictures(fetchedRemotePictures)
        setLocalPictures(res.data.product.local_pictures || [])
      })
      .catch((err) => console.log('ERR IN editPRODUCT', err))
  }, [productID, dummyRender])
  console.log('FORM DATA', formData)
  useEffect(() => {
    if (message.content) {
      const timer = setTimeout(() => setMessage({type: '', content: ''}), 5000)
      return () => clearTimeout(timer)
    }
  }, [message])

  function checkFormRequirements() {
    if (formData.name == '') {
      return true
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const {name, value} = event.target
    setFormData((prevState) => ({...prevState, [name]: value}))

    console.log(formData)
  }

  const handleInputChange = (id: number, value: string) => {
    const newInputs = inputs.map((input) => {
      if (input.id === id) {
        return {...input, value}
      }
      return input
    })

    console.log('NEW INPUTS', newInputs)

    const newAddInput = newInputs.map((input) => ({
      fileName: 'remoteImg',
      filePath: input.value,
      _id: '',
    }))

    // console.log('SIUUUUUUUUUUUUUU', newAddInput)
    setEditProdInput(newAddInput)

    setInputs(newInputs)
  }

  const handleAddInput = () => {
    const newPicture = {fileName: 'remoteImg', filePath: '', _id: `new-${Date.now()}`}
    setRemotePictures([...remotePictures, newPicture])
  }

  const handleExistentField = (id, value) => {
    const updatedPictures = remotePictures.map((picture) =>
      picture._id === id ? {...picture, filePath: value} : picture
    )
    setRemotePictures(updatedPictures)
  }

  const handleDeleteInput = (id) => {
    const updatedPictures = remotePictures.filter((picture) => picture._id !== id)
    // Ensure there's always at least one input field for adding a new link
    if (updatedPictures.length === 0) {
      updatedPictures.push({fileName: 'remoteImg', filePath: '', _id: `new-${Date.now()}`})
    }
    setRemotePictures(updatedPictures)
  }

  const handleRemoveInput = (inputId) => {
    setInputs(inputs.filter((input) => input.id !== inputId))
  }

  const handleClear = () => {
    const fileInput = document.getElementById('myFile') as HTMLInputElement
    if (fileInput) {
      fileInput.value = ''
    }
  }
  function fetchProductData() {
    getProductById(productID)
      .then((response) => {
        console.log('Fetched product data successfully:', response.data)
        setFormData(response.data.product)
        setProductStock(response.data.stock)
        setRemotePictures(response.data.product.remote_pictures)
        setLocalPictures(response.data.product.local_pictures || [])
      })
      .catch((error) => {
        console.error('Failed to fetch product data:', error)
      })
  }

  function localImages(e: React.ChangeEvent<HTMLInputElement>): void {
    const files = Array.from(e.target.files || [])
    const fileObjects = files.map((file) => ({
      uri: URL.createObjectURL(file),
      name: file.name,
      type: file.type,
      file: file,
    }))

    setSelectedFiles((prevFiles) => [...prevFiles, ...fileObjects])
  }

  function showStockAvailable() {
    let totalQty = 0
    if (productStock !== undefined && Array.isArray(productStock)) {
      productStock.map((stock: any) => {
        totalQty = totalQty + stock.qty
      })
      return totalQty
    }
  }

  const handleEditProduct = async () => {
    setLoading(true)
    const updatedFormData = {
      ...formData,
      remote_pictures: remotePictures.map(({fileName, filePath}) => ({
        fileName,
        filePath,
      })),
    }

    console.log('Sending updated data to edit product:', updatedFormData)

    try {
      const editResponse = await editProduct(
        productID,
        updatedFormData.name,
        updatedFormData.sku,
        Number(updatedFormData.price_wt),
        updatedFormData.remote_pictures,
        updatedFormData.ean,
        updatedFormData.isbn,

      )

      console.log('Edit Product Response:', editResponse)
      setMessage({type: 'success', content: 'Modificarile au fost facute cu success!'})
      setLoading(false)

      if (selectedFiles.length > 0) {
        const files = selectedFiles.map((fileObject) => fileObject.file)
        const uploadResponse = await addLocalImage(productID, files)
        console.log('Upload Response:', uploadResponse)
      }

      // navigate('/path-to-redirect');
    } catch (error) {
      console.error('Error in handleEditProduct:', error)
      setMessage({type: 'error', content: 'Something went wrong!'})
      setLoading(false)
    }
  }

  const handleModalAppearance = (id: string, name: string, qty: number) => {
    setSelectedDepositDetails({
      productID: productID,
      deposit: {id: id, name: name},
      qty: qty,
    })
    setShowModal(true)
  }

  function dummyRenderHandler() {
    setDummyRender((prevState) => !prevState)
  }

  const handleRemoveImage = (index: number): void => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }

  const handleDeletePicture = async (productId, pictureId) => {
    console.log(`Attempting to delete picture ${pictureId} for product ${productId}`)
    const result = await deletePicture(productId, pictureId)
    if (result.success) {
      console.log('Picture deleted successfully')
      setLocalPictures((prev) => prev.filter((picture) => picture._id !== pictureId))
    } else {
      console.error('Failed to delete picture:', result.error)
    }
  }

  // Generate barcode PDF if it doesn't exist
  const generateBarcodePDF = async () => {
    if (!formData.barcode) {
      try {
        const response = await axiosConf.get(`${baseUrl}/v1/products/${productID}/barcode`)
        const data = await response.data
        if (data.status === 'success') {
          setFormData((prevState) => ({
            ...prevState,
            barcode: {
              fileName: `Apasa pentru a vizualiza codul de bare`,
              filePath: data.url,
            },
          }))
        }
      } catch (error) {
        console.error('Error fetching barcode PDF:', error)
      }
    }
  }

  return (
    <>
      <div className='products'>
        <div className='products__title'>
          <h1 className='card-title align-items start flex-column mb-0'>Produse</h1>
        </div>
        <div className='products__separator' />
        <div className='products__container'>
          <div className='products__subtitle'>
            <h2>Editează produs</h2>
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              handleEditProduct()
              // console.log('FORM DATA ON SUBMIT', formData)
              // console.log(formData, '---------------------------------------')
            }}
          >
            {/* start product form here */}
            {/* NAME input start */}
            <div className='products__section'>
              <div className='mb-6'>
                <label className='form-label products__input--label'>Nume</label>
                <input
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  type='text'
                  className='form-control products__input'
                  placeholder='Senzor'
                />
              </div>

              {/* NAME input end */}

              {/* SKU input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>Sku</label>
                <input
                  onChange={handleChange}
                  name='sku'
                  type='text'
                  value={formData.sku}
                  className='form-control products__input'
                  placeholder='CL10877'
                />
              </div>
              {/* SKU input end */}

              {/* PRICE WT input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>Preț fără TVA</label>
                <input
                  onChange={handleChange}
                  value={formData.price_wt}
                  name='price_wt'
                  type='number'
                  className='form-control products__input'
                  placeholder='Senzor'
                />
              </div>
            </div>
            {/* PRICE WT input end */}
            {/* REMOTE Pictures input start */}
            <div className='mb-6'>
              <label className='form-label products__input--label'>Imagini</label>

              {remotePictures.map((picture, index) => (
                <div
                  key={picture._id || index}
                  style={{display: 'flex', gap: '10px', alignItems: 'center'}}
                >
                  <label className='form-label products__input--label'>{index + 1}</label>
                  <input
                    style={{marginBottom: '4px', marginTop: '4px'}}
                    className='form-control products__input'
                    type='text'
                    value={picture.filePath}
                    onChange={(e) =>
                      handleExistentField(picture._id || `new-${index}`, e.target.value)
                    }
                    placeholder='Adauga link imagine'
                  />
                  {index === remotePictures.length - 1 && (
                    <button className='products__input--add' type='button' onClick={handleAddInput}>
                      +
                    </button>
                  )}
                  {(remotePictures.length > 1 || picture.filePath) && (
                    <button
                      type='button'
                      className='products__input--danger'
                      onClick={() => handleDeleteInput(picture._id || `new-${index}`)}
                    >
                      -
                    </button>
                  )}
                </div>
              ))}
            </div>
            {/* REMOTE Pictures input end */}

            {remotePictures.length > 0 && (
              <div className='mb-6'>
                <label className='form-label products__input--label'>Previzualizare imagini</label>
                <div className='products__image-preview'>
                  {remotePictures.map((picture, index) => (
                    <div key={index} className='image-container'>
                      <img src={picture.filePath} />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* REMOTE Pictures input end */}

            {/* Local Pictures display start */}
            {localPictures.length > 0 && (
              <div className='mb-6'>
                <label className='form-label products__input--label'>
                  Imagini Locale Existente
                </label>
                <div className='products__image-preview'>
                  {localPictures.map((picture, index) => (
                    <div key={index} className='image-container'>
                      <img src={`${baseUrl}${picture.filePath}`} alt={`Local Image ${index}`} />
                      <button
                        type='button'
                        onClick={() => handleDeletePicture(productID, picture._id)}
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* Local Pictures display end */}

            {/* local Pictures input start */}
            <div className='mb-6'>
              <label className='form-label products__input--label'>Adauga Imagine</label>
              <div style={{display: 'flex', gap: '10px'}}>
                <input
                  type='file'
                  id='myFile'
                  name='filename'
                  onChange={localImages}
                  multiple
                  className='form-control products__input'
                />
              </div>
              <div className='form-label mt-5 text-gray-600' style={{fontStyle: 'italic'}}>
                Format suportat: jpg, jpeg, png{' '}
              </div>
            </div>
            {selectedFiles.length > 0 && (
              <div className='mb-6'>
                <label className='form-label products__input--label'>Imagini Adaugate</label>
                <div className='products__image-preview'>
                  {selectedFiles.map((file, index) => (
                    <div key={index}>
                      <img src={file.uri} alt={`Preview ${index}`} />
                      <button type='button' onClick={() => handleRemoveImage(index)}>
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* local Pictures input end */}
            {/* EAN input start */}
            <div className='mb-6'>
              <label className='form-label products__input--label'>EAN</label>
              <input
                onChange={handleChange}
                value={formData.ean}
                name='ean'
                type='text'
                className='form-control products__input'
                placeholder=''
              />
            </div>
            {/* EAN input end */}

            {/* ISBN input start */}
            <div className='mb-6'>
              <label className='form-label products__input--label'>ISBN</label>
              <input
                onChange={handleChange}
                value={formData.isbn}
                name='isbn'
                type='text'
                className='form-control products__input'
                placeholder=''
              />
            </div>
            {/* ISBN input end */}

            {/* BARCODE input start */}
            <div className='mb-6'>
              <label className='form-label products__input--label'>Cod de bare</label>
              {formData.barcode && formData.barcode.fileName && formData.barcode.filePath ? (
                <a
                  href={`${baseUrl}${formData.barcode.filePath}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='form-control products__input'
                >
                  <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
                  {formData.barcode.fileName}
                </a>
              ) : (
                <button className='products__input--add' type='button' onClick={generateBarcodePDF}>
                  Generează PDF
                </button>
              )}
            </div>

            <div className='products__subtitle'>
              {productStock && productStock.length > 0 && <h2>Disponibilitate depozite</h2>}
            </div>
            {productStock && productStock.length > 0 && (
              <div className='products__section'>
                <div className='products__details--row products__table--row'>
                  <p className='products__details--row-header'>Depozit</p>
                  <p className='products__details--row-header'>Stoc</p>
                  <p className='products__details--row-header'>Acțiuni</p>
                </div>
                {productStock?.map((prod: ProdPerDeposit) => {
                  return (
                    <div
                      key={`${prod.deposit.id}-${prod.deposit.name}-${prod.qty}`}
                      className='products__details--row products__details--product products__table--row'
                    >
                      <p className='products__details--row-info'>{prod.deposit.name}</p>
                      <p className='products__details--row-info'>{prod.qty}</p>
                      <div className='products__details--row-info'>
                        <a
                          className='btn btn-icon btn-light btn-sm'
                          onClick={() => {
                            handleModalAppearance(prod.deposit.id, prod.deposit.name, prod.qty)
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/pencil.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </div>
                  )
                })}
                <div
                  style={{visibility: 'hidden'}}
                  className='products__details--row products__details--product products__table--row'
                >
                  <p className='products__details--row-info'></p>
                  <p className='products__details--row-info'></p>
                  <div className='products__details--row-info'>
                    <a className='btn btn-icon btn-light btn-sm'>
                      <KTSVG path='/media/icons/duotune/arrows/pencil.svg' className='svg-icon-3' />
                    </a>
                  </div>
                </div>
                <div className='products__details--row products__details--product products__table--row'>
                  <p className='products__details--row-info'>Stoc total</p>
                  <p className='products__details--row-info'>
                    <b>{showStockAvailable()} buc</b>
                  </p>
                </div>
                {showModal && (
                  <StockModal
                    depositData={selectedDepositDetails}
                    active={true}
                    handleClose={() => setShowModal(false)}
                    dummyRender={() => dummyRenderHandler()}
                  />
                )}
              </div>
            )}
            {/* begin::Action */}
            <div className='d-grid'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='products__action'
                disabled={loading || checkFormRequirements()}
              >
                {!loading ? (
                  <span className='indicator-label'>Salvează Modificările</span>
                ) : (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Așteaptă...{''}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              {message.content && (
                <div
                  className={`alert alert-dismissible d-flex flex-column align-items-center mt-10 ${
                    message.type === 'success' ? 'bg-success' : 'bg-danger'
                  }`}
                  role='alert'
                >
                  <div className='d-flex flex-column align-items-center'>
                    <div className='fw-bold fs-4'>{message.content}</div>
                  </div>
                </div>
              )}
            </div>
            {/* end::Action */}
          </form>
        </div>
      </div>
    </>
  )
}

export default EditProduct

interface StockModalProps {
  depositData: {productID: string; deposit: {id: string; name: string}; qty: number}
  active: boolean
  handleClose: () => void
  dummyRender: () => void
}

interface StockDetails {
  qty: number
  reason: string
}

const StockModal = ({depositData, active, handleClose, dummyRender}: StockModalProps) => {
  const [depositDetails, setDepositDetails] = useState(depositData)
  const [stockDetails, setStockDetails] = useState<StockDetails>({
    qty: depositData.qty,
    reason: '',
  })
  const [warningText, setWarningText] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStockDetails({...stockDetails, [event.target.name]: event.target.value})
    console.log(stockDetails)
  }

  const handleSaveStockEdit = () => {
    setStockAvailable(
      depositDetails.deposit.id,
      depositDetails.productID,
      stockDetails.qty,
      stockDetails.reason
    )
      .then((res) => {
        if (res.status === 'success') {
          handleClose()
          dummyRender()
        }
      })
      .catch((err) => console.log('err in set stock', err))
  }

  function checkFormRequirements(): boolean {
    if (stockDetails.qty === 0 || stockDetails.reason === '') {
      setWarningText(true)
      return true
    } else {
      setWarningText(false)
      return false
    }
  }

  return (
    <>
      <Modal show={active} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modifică stocul în {depositDetails.deposit.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              console.log('stockDetails', stockDetails)
            }}
          >
            <Form.Group className='mb-3'>
              <Form.Label className='form-label products__input--label'>Cantitate</Form.Label>
              <Form.Control
                required={true}
                type='number'
                name='qty'
                value={stockDetails.qty}
                onChange={handleChange}
                autoFocus
                className='form-control products__input'
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='form-label products__input--label'>
                Motiv modificare stoc
              </Form.Label>
              <Form.Control
                name='reason'
                required={true}
                value={stockDetails?.reason}
                onChange={handleChange}
                as='textarea'
                rows={3}
                className='form-control products__input'
              />
            </Form.Group>
            {warningText && <WarningMessage />}
            {(stockDetails.qty === 0 || stockDetails.reason === '') && <WarningMessage />}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className='products__action--modal products__action--modal-secondary'
            onClick={handleClose}
          >
            Închide
          </button>
          <button
            className='products__action--modal products__action--modal-primary'
            onClick={handleSaveStockEdit}
            disabled={stockDetails.qty === 0 || stockDetails.reason === ''}
          >
            Salvează
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
