/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useState, useEffect, useRef} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  getReceptionById,
  editReception,
} from '../../app/modules/auth/core/Requests/_requestsReceptions'
import {KTSVG} from '../../_metronic/helpers'
import './style/Reception.scss'

export const API_URL = process.env.REACT_APP_API_URL

interface ReceptionData {
  checks: {
    reception: boolean
    labeling_integrity: boolean
    marking_integrity: boolean
    packing: boolean
    products_identification: boolean
    seals_integrity: boolean
  }
  documents: any[]
  deposit: {
    address: string
    id: string
    name: string
    _id: string
  }
  finished: boolean
  group: string
  id: string
  no: number
  products: [{sku: string; qty: number}]
  products_status: {
    existing_products: [{sku: string; qty: number}]
    new_products: [{sku: string; qty: number}]
  }
  new_products: any[]
  user: {
    id: string
    name: string
    _id: string
    email?: string
    phone?: string
  }
  __v: number
  _id: string
  createdAt: string
}

const initialReceptionData: ReceptionData = {
  checks: {
    reception: false,
    labeling_integrity: false,
    marking_integrity: false,
    packing: false,
    products_identification: false,
    seals_integrity: false,
  },
  documents: [],
  deposit: {
    address: '',
    id: '',
    name: '',
    _id: '',
  },
  finished: false,
  group: '',
  id: '',
  no: 0,
  products: [{sku: '', qty: 0}],
  products_status: {
    existing_products: [{sku: '', qty: 0}],
    new_products: [{sku: '', qty: 0}],
  },
  new_products: [],
  user: {
    id: '',
    name: '',
    _id: '',
  },
  __v: 0,
  _id: '',
  createdAt: '',
}

const Reception = () => {
  const {id} = useParams<{id: string}>()
  const recID = id.startsWith(':') ? id.substring(1) : id
  console.log('ViewReception', useParams())

  // Log the reception ID
  console.log('Reception ID:', recID)

  const [viewData, setViewData] = useState<ReceptionData>(initialReceptionData)

  const [responseMessage, setResponseMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const messageTimeoutRef = useRef(null)

  useEffect(() => {
    getReceptionById(recID)
      .then((res) => {
        // Log the entire response
        console.log('API Response:', res)

        // Log the specific data you're interested in
        console.log('Reception Data:', res.data.reception)

        setViewData(res.data.reception)
      })
      .catch((e) => {
        console.error('API Call Error:', e)
      })
  }, [recID])

  const downloadFile = (filePath: string, fileName: string) => {
    const baseURL = 'https://api.incom.ai'
    const fileURL = baseURL + filePath
    const win = window.open(fileURL, '_blank')
    win?.focus()
  }

  // Edit part
  const handleInputChange = (field: string, value: any, isCheckbox: boolean = false) => {
    setViewData((prevState) => {
      const [parent, child] = field.split('.')
      return {
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: isCheckbox ? !prevState[parent][child] : value,
        },
      }
    })
  }

  const handleProductChange = (index, type, value, isNewProduct) => {
    setViewData((prevState) => {
      const products = isNewProduct
        ? [...prevState.products_status.new_products]
        : [...prevState.products_status.existing_products]

      const updatedProduct = {...products[index], [type]: value}
      products[index] = updatedProduct

      return {
        ...prevState,
        products_status: {
          ...prevState.products_status,
          [isNewProduct ? 'new_products' : 'existing_products']: products,
        },
      }
    })
  }

  useEffect(() => {
    return () => {
      if (messageTimeoutRef.current) {
        clearTimeout(messageTimeoutRef.current)
      }
    }
  }, [])

  const handleUpdate = async () => {
    try {
      const {checks, products_status} = viewData

      const products = [...products_status.new_products, ...products_status.existing_products].map(
        ({sku, qty}) => ({sku, qty})
      )

      const dataToSend = {
        checks: {
          packing: checks.packing,
          products_identification: checks.products_identification,
          seals_integrity: checks.seals_integrity,
          marking_integrity: checks.marking_integrity,
          labeling_integrity: checks.labeling_integrity,
        },
        products,

      }

      // Now calling editReception with the correct parameters
      const response = await editReception(recID, dataToSend)
      console.log('Update Success:', response)
      setResponseMessage('Modificările au fost făcute cu succes!')
      setIsSuccess(true)
    } catch (error) {
      console.error('Update Error:', error)
      setResponseMessage('Verifica cantitatea sau condițiile pachetului')
      setIsSuccess(false)
    } finally {
      if (messageTimeoutRef.current) {
        clearTimeout(messageTimeoutRef.current)
      }
      messageTimeoutRef.current = setTimeout(() => {
        setResponseMessage('')
        setIsSuccess(false)
      }, 7000)
    }
  }

  return (
    <>
      <div className='reception'>
        <div className='reception__title'>
          <h1>{`Detalii Recepție #${viewData.no}`}</h1>
        </div>
        <div className='reception__separator' />
        <div className='reception__container'>
          <div className='reception__subtitle'>
            <h2>Documente</h2>
          </div>
          <div className='reception__table'>
            {viewData.documents && viewData.documents.length ? (
              <>
                {viewData.documents.map((document, index) => {
                  return (
                    <div
                      key={`${index}-${document.fileName}`}
                      onClick={() => downloadFile(document.filePath, document.fileName)}
                      className='reception__details--row reception__details--list'
                    >
                      <p className='reception__details--row-title'>{document.fileName}</p>
                      <p className='reception__details--row-description reception__details--list-link'>
                        Descarcă
                      </p>
                    </div>
                  )
                })}
              </>
            ) : (
              <p className='reception__message'>Nu există nici un document disponibil.</p>
            )}
          </div>
        </div>
        <div className='reception__flex'>
          <div className='reception__container reception__flex--inner'>
            <div className='reception__subtitle'>
              <h2>Informații generale</h2>
            </div>
            <div className='reception__details'>
              <div className='reception__details--row'>
                <p className='reception__details--row-title'>Nume complet</p>
                <p className='reception__details--row-description'>{viewData.user.name || 'N/A'}</p>
              </div>
              <div className='reception__details--row'>
                <p className='reception__details--row-title'>Dată recepție</p>
                <p className='reception__details--row-description'>
                  {new Date(viewData.createdAt).toLocaleDateString()}
                </p>
              </div>
              <div className='reception__details--row'>
                <p className='reception__details--row-title'>Depozit</p>
                <p className='reception__details--row-description'>
                  {viewData.deposit.name || 'N/A'}
                </p>
              </div>
              <div className='reception__details--row'>
                <p className='reception__details--row-title'>Adresă depozit</p>
                <p className='reception__details--row-description'>{viewData.deposit.address}</p>
              </div>
              <div className='reception__details--row'>
                <p className='reception__details--row-title'>Finalizat</p>
                <p className='reception__details--row-description'>
                  {viewData.finished ? 'Da' : 'Nu'}
                </p>
              </div>
            </div>
          </div>
          <div className='reception__container reception__flex--inner'>
            <div className='reception__subtitle'>
              <h2>Condiție colet</h2>
            </div>
            <div className='reception__details'>
              <div className='mb-4'>
                <input
                  className='form-check-input reception__checkbox'
                  type='checkbox'
                  checked={viewData.checks.packing}
                  onChange={(e) => handleInputChange('checks.packing', e.target.checked, true)}
                />
                <label className='form-label reception__checkbox--label'>Ambalaj</label>
              </div>
              <div className='mb-4'>
                <input
                  className='form-check-input reception__checkbox'
                  type='checkbox'
                  checked={viewData.checks.labeling_integrity}
                  onChange={(e) =>
                    handleInputChange('checks.labeling_integrity', e.target.checked, true)
                  }
                />
                <label className='form-label reception__checkbox--label'>Condiția etichetei</label>
              </div>
              <div className='mb-4'>
                <input
                  className='form-check-input reception__checkbox'
                  type='checkbox'
                  checked={viewData.checks.products_identification}
                  onChange={(e) =>
                    handleInputChange('checks.products_identification', e.target.checked, true)
                  }
                />
                <label className='form-label reception__checkbox--label'>
                  Identificare produse
                </label>
              </div>
              <div className='mb-4'>
                <input
                  className='form-check-input reception__checkbox'
                  type='checkbox'
                  checked={viewData.checks.seals_integrity}
                  onChange={(e) =>
                    handleInputChange('checks.seals_integrity', e.target.checked, true)
                  }
                />
                <label className='form-label reception__checkbox--label'>Condiția sigiliului</label>
              </div>
              <div className='mb-4'>
                <input
                  className='form-check-input reception__checkbox'
                  type='checkbox'
                  checked={viewData.checks.marking_integrity}
                  onChange={(e) =>
                    handleInputChange('checks.marking_integrity', e.target.checked, true)
                  }
                />
                <label className='form-label reception__checkbox--label'>Condiția marcajului</label>
              </div>
            </div>
          </div>
        </div>
        {viewData?.products_status?.existing_products?.length ? (
          <div className='reception__container'>
            <div className='reception__subtitle'>
              <h2>Produse existente</h2>
            </div>
            <div className='reception__table'>
              <div className='reception__details--row reception__details--row-border reception__table--row'>
                <div className='reception__details--row-header'>Cod produs</div>
                <div className='reception__details--row-header'>Cantitate</div>
              </div>
              {viewData.products_status.existing_products.map((existingProd, index) => {
                return (
                  <div
                    key={existingProd.sku}
                    className='reception__details--row reception__details--product reception__table--row'
                  >
                    <p className='reception__details--row-info'>{existingProd.sku}</p>
                    <div className='reception__details--row-info'>
                      <input
                        type='number'
                        value={existingProd.qty}
                        onChange={(e) =>
                          handleProductChange(index, 'qty', parseInt(e.target.value), false)
                        }
                        className='form-control reception__input'
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          ''
        )}
        {viewData?.products_status?.new_products?.length ? (
          <div className='reception__container'>
            <div className='reception__subtitle'>
              <h2>Produse noi</h2>
            </div>
            <div className='reception__table'>
              <div className='reception__details--row reception__details--row-border reception__table--row'>
                <div className='reception__details--row-header'>Cod produs</div>
                <div className='reception__details--row-header'>Cantitate</div>
              </div>
              {viewData.products_status.new_products.map((newProd, index) => {
                return (
                  <div
                    key={newProd.sku}
                    className='reception__details--row reception__details--product reception__table--row'
                  >
                    <p className='reception__details--row-info'>{newProd.sku}</p>
                    <div className='reception__details--row-info'>
                      <input
                        type='number'
                        value={newProd.qty}
                        onChange={(e) =>
                          handleProductChange(index, 'qty', parseInt(e.target.value), true)
                        }
                        className='form-control reception__input'
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          ''
        )}
        <button
          className='reception__action'
          onClick={handleUpdate}
          disabled={!viewData.finished} 
        >
          Actualizeaza date
        </button>

        {responseMessage && (
          <div
            style={{
              color: 'white',
              backgroundColor: isSuccess ? 'green' : 'red',
              padding: '10px',
              marginTop: '10px',
              borderRadius: '5px',
            }}
          >
            {responseMessage}
          </div>
        )}
      </div>
    </>
  )
}

export default Reception
