import React, {memo, useCallback, useEffect, useRef, useState} from 'react'
import RangeItem from './components/RangeItem'
import axiosConf from '../../app/modules/auth/core/Requests/_axiosInstance'
import {CREATE_DEPOSITS} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {useNavigate, useParams} from 'react-router'
import RangeItemShelf from './components/RangeItemShelf'
import './deposit-style.css'
import './style/CreateDepositSecond.scss'

export const Grid = ({columns, rows}) => {
  // Calculate the square size based on the viewport width and the number of columns
  const squareSize = Math.floor((window.innerWidth - (window.innerWidth * 25) / 100) / columns)
  // Calculate the number of rows needed
  // const rows = Math.ceil(totalCells / columns);

  // Generate the grid's cells
  const cells = []
  for (let i = 0; i < columns; i++) {
    cells.push(i)
  }

  // Generate grid squares
  const squares = []
  for (let y = 0; y < rows; y++) {
    squares.push(cells)
  }
  // console.log(squares);

  return (
    <div>
      {squares.map((row, indexS) => {
        return (
          <div
            className='grid-container'
            key={indexS}
            style={{maxWidth: `${columns * squareSize}px`}}
          >
            {row.map((cell, indexR) => (
              <div
                key={indexR}
                className='grid-cell'
                style={{
                  width: `${squareSize}px`,
                  height: `${squareSize}px`,
                  fontSize: `10px`,
                }}
              >
                {indexR + indexS * columns + 1}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

export const API_URL = process.env.REACT_APP_API_URL

interface Point {
  row: number
  col: number
}

interface Range {
  start: Point
  end: Point
  name: string
  fata: number
  levels: number
  id: string
  db_levels: []
}

// Define an interface for the component's props
interface CreateShelfSecondProps {
  scaleData: any // Replace 'any' with a more specific type if possible
  widthData: any
  heightData: any
  areaData: any
  depositId: any
}

const CreateShelfSecond: React.FC<CreateShelfSecondProps> = ({
                                                               scaleData,
                                                               widthData,
                                                               heightData,
                                                               areaData,
                                                               depositId,
                                                             }) => {
  const areaId = areaData?._id

  // console.log('areaData CreateShelfSecond', areaData, areaId)
  const areaheight = areaData.coordinates.second.y - areaData.coordinates.first.y
  const areawidth = areaData.coordinates.second.x - areaData.coordinates.first.x

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [height, setHeight] = useState(areaheight)
  const [width, setWidth] = useState(areawidth)
  const [scale, setScale] = useState(1)

  const [startPoint, setStartPoint] = useState<Point | null>(null)
  const [endPoint, setEndPoint] = useState<Point | null>(null)
  const [ranges, setRanges] = useState<Range[]>([])

  const [areaCreated, setAreaCreated] = useState(false)

  useEffect(() => {
    if (areaData.shelves.length > 0) {
      const newRanges = areaData.shelves.map((area: any) => {
        const start: Point = {row: area.coordinates.first.x, col: area.coordinates.first.y}
        const end: Point = {row: area.coordinates.second.x, col: area.coordinates.second.y}
        return {
          start,
          end,
          name: area.name,
          fata: area.face,
          levels: area.levels.length,
          db_levels: area.levels,
          id: area._id,
        }
      })

      setRanges(newRanges)
      setScale(1)
    }

    setWidth(areawidth)
    setHeight(areaheight)
  }, [areawidth, areaheight])

  const updateRangeName = (index: number, newName: string, fata: number, levels: number) => {
    const newRanges = [...ranges]
    newRanges[index] = {...newRanges[index], name: newName, fata: fata, levels: levels}
    setRanges(newRanges)
  }

  const handleScaleChange = (e: any) => {
    // setScale(Number(e.target.value))
    setScale(Number(1))
  }

  // handleChangeInputByName is used to update the state of the input fields by name of input
  const handleChangeInputByName = (e: any) => {
    const inputName = e.target.name
    if (inputName === 'name') {
      setName(e.target.value)
    } else if (inputName === 'address') {
      setAddress(e.target.value)
    }
  }

  const handleCellClick = (row: number, col: number) => {
    let rowScale = row * scale
    let colScale = col * scale
    if (!startPoint) {
      setStartPoint({row: rowScale, col: colScale})
    } else if (!endPoint) {
      // Generate a default name for the range, e.g., "Range 1", "Range 2", etc.
      const defaultName = `Raft ${ranges.length + 1}`
      setEndPoint({row: rowScale, col: col + scale})
      console.log('setEndPoint', {row: rowScale, col: col + scale})
      setRanges([
        ...ranges,
        {
          start: startPoint,
          end: {row: rowScale + scale, col: colScale + scale},
          name: defaultName,
          fata: 1,
          levels: 1,
          id: null,
          db_levels: []
        },
      ])
      setStartPoint(null)
      setEndPoint(null)
    }
  }

  const isCellInRange = (row: number, col: number) => {
    return ranges.some((range) => {
      const rowInRange = row >= range.start.row && row < range.end.row
      const colInRange = col >= range.start.col && col < range.end.col
      return rowInRange && colInRange
    })
  }

  // get cell range index
  const getCellRangeIndex = (row: number, col: number) => {
    return ranges.findIndex((range) => {
      const rowInRange = row >= range.start.row && row < range.end.row
      const colInRange = col >= range.start.col && col < range.end.col
      return rowInRange && colInRange
    })
  }

  const isStartPoint = (row: number, col: number) => {
    return startPoint && !endPoint && startPoint.row === row && startPoint.col === col
  }

  const deleteRange = async (index: number, id: string) => {
    const newRanges = [...ranges]
    newRanges.splice(index, 1)
    setRanges(newRanges)
    if (id !== null) {
      try {
        const response = await axiosConf.delete(`${API_URL}/locations/${id}`)
        if (response.status === 204) {
          alert('Raft stears cu succes!')
        }
      } catch (err: any) {
        alert('Eroare la stergerea raftului ' + id)
      }
    }
  }

  const colorsArray = [
    '#FCAB64',
    '#FCD29F',
    '#A1FCDF',
    '#7FD8BE',
    '#FCEFEF',
    '#CDB4DB',
    '#FFC8DD',
    '#FFAFCC',
    '#BDE0FE',
    '#A2D2FF',
  ]

  const renderTable = () => {
    let table = []
    const columnWidth = width > 0 ? `${100 / width}%` : 'auto'

    let key = 0
    let scaleVal = scale > 0 ? scale : 1
    let scaleHeight = height / scaleVal
    let scaleWidth = width / scaleVal
    for (
      let i = areaData.coordinates.first.x / scaleVal;
      i < areaData.coordinates.second.x / scaleVal;
      i++
    ) {
      let row = []
      for (
        let j = areaData.coordinates.first.y / scaleVal;
        j < areaData.coordinates.second.y / scaleVal;
        j++
      ) {
        key++

        const inRange = isCellInRange(i * scaleVal, j * scaleVal)
        const isStart = isStartPoint(i * scaleVal, j * scaleVal)
        const indexRange = getCellRangeIndex(i * scaleVal, j * scaleVal)

        row.push(
          <td
            key={`cell-${i}-${j}`}
            className=' font-size-xs p-0 text-center'
            onClick={() => handleCellClick(i, j)}
            style={{
              backgroundColor: inRange
                ? colorsArray[indexRange]
                : isStart
                  ? 'lightblue'
                  : 'transparent',
              width: columnWidth,
              minHeight: `100 - ${scaleHeight}vw`,
              fontSize: '9px',
            }}
          >{`R-F(${i * scaleVal}-${j * scaleVal})-S(${i * scaleVal + scaleVal}-${
            j * scaleVal + scaleVal
          })`}</td>
        )
      }
      table.push(
        <tr key={`row-${i}`} className=''>
          {row}
        </tr>
      )
    }

    return <tbody>{table}</tbody>
  }

  const grid = () => {
    let scaleVal = scale > 0 ? scale : 1
    let scaleHeight = height / scaleVal
    let scaleWidth = width / scaleVal

    // Calculate the square size based on the viewport width and the number of columns
    let squareSize = Math.floor((window.innerWidth - (window.innerWidth * 25) / 100) / scaleHeight)
    squareSize = squareSize > 200 ? squareSize / 4 : squareSize
    squareSize = squareSize > 50 ? squareSize / 1.5 : squareSize
    // console.log('squareSize ', window.innerWidth, squareSize)
    // Calculate the number of rows needed
    // const rows = Math.ceil(totalCells / columns);

    // Generate the grid's cells
    const cells = []

    // let i = areaData.coordinates.first.x / scaleVal;
    //       i < areaData.coordinates.second.x / scaleVal;
    for (let i = 0; i < scaleHeight; i++) {
      cells.push(i)
    }

    // Generate grid squares
    const squares = []
    for (let y = 0; y < scaleWidth; y++) {
      squares.push(cells)
    }
    // console.log(squares);

    return (
      <div>
        {squares.map((row, indexR) => {
          return (
            <div
              className='grid-container'
              key={indexR}
              style={{maxWidth: `${scaleHeight * squareSize}px`}}
            >
              {row.map((cell, indexC) => {
                const indecRCoordonate = indexR + areaData.coordinates.first.x / scaleVal
                const indecCCoordonate = indexC + areaData.coordinates.first.y / scaleVal

                const inRange = isCellInRange(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )
                const isStart = isStartPoint(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )
                const indexRange = getCellRangeIndex(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )

                return (
                  <div
                    key={indexC}
                    className='grid-cell'
                    onClick={() => handleCellClick(indecRCoordonate, indecCCoordonate)}
                    style={{
                      backgroundColor: inRange
                        ? colorsArray[indexRange]
                        : isStart
                          ? 'lightblue'
                          : 'transparent',
                      width: `${squareSize}px`,
                      height: `${squareSize}px`,
                      fontSize: `0`,
                      color: inRange ? 'black' : 'white',
                    }}
                  >
                    {/*{indexR + indexS * scaleHeight + 1}*/}
                    {`x(${indecRCoordonate * scaleVal}-${indecCCoordonate * scaleVal})-y(${
                      indecRCoordonate * scaleVal + scaleVal
                    }-${indecCCoordonate * scaleVal + scaleVal})`}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const navigate = useNavigate()

  const navigateDeposit = () => {
    navigate(`/deposits`);
  };

  const createShelfs = () => {
    const areas = {
      ...ranges,
      width,
      height,
    }
    // console.log('Shelf areas: ', areas)
    // console.log(ranges)
    //{
    //   "name": "string",
    //   "type": "area",
    //   "deposit": "string",
    //   "parent": "string", //(only for shlef, level, column)
    //   "face": 0, // (only for shelf)
    //   "coordinates": {
    //     "first": {
    //       "x": 0,
    //       "y": 0
    //     },
    //     "second": {
    //       "x": 0,
    //       "y": 0
    //     }
    //   }
    // }
    ranges.forEach(async (range, index) => {
      let rangeEndRow = range.end.row * scale
      let rangeEndCol = range.end.col * scale
      let widthMax = areaData.coordinates.second.x
      let heightMax = areaData.coordinates.second.y
      let ShelfData = {
        name: range.name,
        type: 'shelf',
        parent: areaId, // parent ID
        face: Number(range.fata),
        coordinates: {
          first: {
            x: range.start.row * scale,
            y: range.start.col * scale,
          },
          second: {
            x: rangeEndRow > widthMax + scale ? rangeEndRow - scale : rangeEndRow,
            y: rangeEndCol > heightMax + scale ? rangeEndCol - scale : rangeEndCol,
          },
        },
      }

      if (range.id !== null) {
        const response = await axiosConf.patch(`${API_URL}/locations/` + range.id, ShelfData)
        if (response.data.status === 'success') {
          let add_level = 0;
          let remove_level = 0;
          if (range.db_levels.length > range.levels) {
            remove_level = range.db_levels.length - range.levels;
          }
          if (range.db_levels.length < range.levels) {
            add_level = range.levels - range.db_levels.length;
          }

          if (add_level > 0) {
            for (let i = 0; i < add_level; i++) {
              let LevelData = {
                name: `Nivel ${i}`,
                type: 'level',
                parent: range.id, // parent ID
                coordinates: {
                  first: {
                    x: range.start.row * scale,
                    y: range.start.col * scale,
                  },
                  second: {
                    x: rangeEndRow > widthMax ? rangeEndRow - scale : rangeEndRow,
                    y: rangeEndRow > heightMax ? rangeEndCol - scale : rangeEndCol,
                  },
                },
              }

              try {
                const response = await axiosConf.post(`${API_URL}/locations`, LevelData)
                if (response) {
                  console.log('res level', response.data)
                }
              } catch (err: any) {
                console.log('CREATE_LOCATIONS err', err)
              }
            }
          }

          if (remove_level > 0) {
            for (let i = 0; i < remove_level; i++) {
              try {
                const response = await axiosConf.delete(`${API_URL}/locations/` + range.db_levels[i]['_id'])
                if (response) {
                  console.log('res level', response.data)
                }
              } catch (err: any) {
                console.log('DELETE_LOCATIONS err', err)
              }
            }
          }
        }
      } else {
        try {
          const response = await axiosConf.post(`${API_URL}/locations`, ShelfData)
          // console.log('CREATE_LOCATIONS res', response.data)
          if (response.data.status === 'success') {
            const shelfId = response.data.data.location._id
            // console.log('res', response.data)
            if (range.levels > 0) {
              // for each range level count create a level same as ShelfData
              for (let i = 1; i <= range.levels; i++) {
                let LevelData = {
                  name: `Nivel ${i}`,
                  type: 'level',
                  parent: shelfId, // parent ID
                  coordinates: {
                    first: {
                      x: range.start.row * scale,
                      y: range.start.col * scale,
                    },
                    second: {
                      x: rangeEndRow > widthMax ? rangeEndRow - scale : rangeEndRow,
                      y: rangeEndRow > heightMax ? rangeEndCol - scale : rangeEndCol,
                    },
                  },
                }

                try {
                  const response = await axiosConf.post(`${API_URL}/locations`, LevelData)
                  // console.log('Create Levels res', response.data)
                  if (response) {
                    console.log('res level', response.data)
                  }
                } catch (err: any) {
                  console.log('CREATE_LOCATIONS err', err)
                }
              }
            }
          }
        } catch (err: any) {
          // console.log('CREATE_SHELF err', err)
          alert(
            'Eroare la crearea raftului ' +
            range.name +
            '! Rafturi intersectate sau dimensiuni depasite!'
          )
        }
      }
    })
    alert('Date salvate!');

    setAreaCreated(true)
  }

  return (
    <div>
      <div className='editDeposit__section--title'>
        <h2>
          Aspect depozit: {areaData.name}{' '}
          <span>(Zonele se selectează de sus în jos, de la stânga la dreapta)</span>
        </h2>
      </div>
      <div className='editDeposit__section'>
        <div className='mb-3'>
          <label htmlFor='scaleInput' className='form-label editDeposit__input--label'>
            Scalare
          </label>
          <input
            type='text'
            className='form-control editDeposit__input'
            id='scaleInput'
            value={scale}
            onChange={handleScaleChange}
          />
        </div>
        <div style={{overflowX: 'auto'}}>
          <div className='d-flex justify-content-between'>
            <h4 className=' font-weight-bold'>0</h4>
            <h4 className=' font-weight-bold'>{height}</h4>
          </div>
          {/*<table*/}
          {/*  className='table table-bordered invisible-text'*/}
          {/*  style={{*/}
          {/*    tableLayout: 'fixed',*/}
          {/*    width: '100%',*/}
          {/*    height: `${height / scale}vw`,*/}
          {/*    minHeight: '500px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {renderTable()}*/}
          {/*</table>*/}
          {grid()}
          <h4 className=' font-weight-bold'>{width}</h4>
        </div>

        {/*<Grid columns={50} rows={10}/>*/}
      </div>
      {ranges.length > 0 && (
        <div>
          <div className='deposit__section--title'>
            <h2>Zonele depozitului: {areaData.name}</h2>
          </div>
          <div className='editDeposit__section editDeposit__table'>
            <div className='editDeposit__table--header'>
              <p className='editDeposit__table--header-cell'>Nume</p>
              <p className='editDeposit__table--header-cell'>Număr nivele</p>
              <p className='editDeposit__table--header-cell'>Orientare</p>
              <p className='editDeposit__table--header-cell'>Arie (rand/coloana)</p>
              <p className='editDeposit__table--header-cell'>Acțiune</p>
            </div>
            {ranges.map((range, index) => (
              <RangeItemShelf
                key={index}
                range={range}
                scale={scale}
                width={width}
                height={height}
                onDelete={() => deleteRange(index, range.id)}
                onNameChange={(val) => updateRangeName(index, val.name, val.fata, val.levels)}
              />
            ))}
          </div>
          {/*{!areaCreated && (*/}
            <div className='editDeposit__create'>
              <div>
                <button className='deposit__action' onClick={createShelfs}>
                  Salvare Rafturi
                </button>
              </div>
            </div>
          {/*)}*/}
          {/*{areaCreated && (*/}
            <div className="editDeposit__create">
              <div>
              <button className="deposit__action" onClick={navigateDeposit} style={{ marginTop: '15px' }} >
                Lista depozite
              </button>
              </div>
            </div>
          {/*)}*/}
        </div>
      )}
    </div>
  )
}

export default memo(CreateShelfSecond)
