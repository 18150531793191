import axiosConf from './_axiosInstance'

export const API_URL = process.env.REACT_APP_API_URL
export const GET_ORDERS = `${API_URL}/orders`
export const GET_PICKING_ORDERS = `${API_URL}/pickingOrders`

export const getAllOrders = async ({page, limit, currentUser}: any) => {
  let url = GET_ORDERS
  const selectedGroup = localStorage.getItem('group')

  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (currentUser?.role === 'super_admin' && selectedGroup)
    queryParams.append('group', selectedGroup)

  const queryString = queryParams.toString()
  if (queryString) url += `?${queryString}`

  try {
    const response = await axiosConf.get(url)
    return response.data
  } catch (err) {
    console.log('GET ALL ORDERS err EP', err)
    return err
  }
}

export const getOrderById = async (id: string | undefined) => {
  try {
    const response = await axiosConf.get(`${GET_ORDERS}/${id}`)
    return response.data
  } catch (err) {
    console.log('get ORDER BY ID err EP', err)
    return err
  }
}

export const getPickingOrderById = async (id: string | undefined) => {
  try {
    const response = await axiosConf.get(`${GET_PICKING_ORDERS}/${id}`)
    return response.data
  } catch (err) {
    console.log('get PICKING_ORDER BY ID err EP', err)
    return err
  }
}
