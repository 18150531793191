/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../app/modules/auth'
import {useState, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {getAllUsers} from '../../app/modules/auth/core/_requests_user'
import PaginationControls from '../components/PaginationControls'
import {deleteUser} from '../../app/modules/auth/core/Requests/_requests_deleteUser'
import './style/UsersList.scss'

export const API_URL = process.env.REACT_APP_API_URL

interface data {
  id: string
  name: string
  email: string
  phone: string
  lastActivationRequest: string
  createdAt: string
  role: string
  deposit: {
    name: string
  }
}

type Props = {
  className: string
}

const UsersList: React.FC<Props> = ({className}) => {
  const {currentUser} = useAuth()
  const selectedGroup = localStorage.getItem('group')
  const navigate = useNavigate()

  const [users, setUsers] = useState<data[]>([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    getAllUsers({page, limit, currentUser}).then((res) => {
      if (res && res.data && res.data.users.length !== 0) {
        setTotalPages(res.data.pages)
        setUsers(res.data.users)
      }
    })
  }, [page, limit]) // Re-run the effect if these values change

  const deleteSelectedUser = async (userId) => {
    await deleteUser(userId).then(() => {
      getAllUsers({page, limit}).then((res) => {
        if (res && res.data && res.data.users.length !== 0) {
          setTotalPages(res.data.pages)
          setUsers(res.data.users)
        }
      })
    })
  }

  return (
    <div className='users'>
      <div className='users__title'>
        <h1 className='card-title align-items start flex-column mb-0'>Utilizatori</h1>
        <div className='users__title--search'>
          <button
            onClick={() => navigate('/add-user')}
            className='users__title--search-button'
            style={{backgroundColor: '#CE8D3B', display: 'flex', alignItems: 'center'}}
            disabled={currentUser.role === 'super_admin' && !selectedGroup}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            <p>Utilizator nou</p>
          </button>
          {currentUser.role === 'super_admin' && !selectedGroup && (
            <p className='disabledNote'>Nici un grup selectat.</p>
          )}
        </div>
      </div>
      <div className='users__separator' />
      <div className='users__container'>
        <div className='users__subtitle'>
          <h2>Listă utilizatori</h2>
        </div>
        <div className='users__table'>
          {users.length ? (
            <>
              <div className='users__details--row users__table--row'>
                <p className='users__details--row-header'>Nume</p>
                <p className='users__details--row-header'>Email</p>
                <p className='users__details--row-header'>Telefon</p>
                <p className='users__details--row-header'>Rol</p>
                <p className='users__details--row-header'>Depozit</p>
                <p className='users__details--row-header'>Acțiuni</p>
              </div>
              {users.map((user: data) => {
                return (
                  <div
                    className='users__details--row users__details--user users__table--row'
                    key={user.id}
                  >
                    <p className='users__details--row-info'>{user.name}</p>
                    <p className='users__details--row-info'>{user.email}</p>
                    <p className='users__details--row-info'>{user.phone}</p>
                    <p className='users__details--row-info'>{user.role}</p>
                    <p className='users__details--row-info'>
                      {user.hasOwnProperty('deposit') ? user.deposit.name : ''}
                    </p>
                    <div className='users__details--row-info'>
                      <Link to={`/edit-user/${user.id}`} className='btn btn-icon btn-light btn-sm'>
                        <KTSVG
                          path='/media/icons/duotune/arrows/pencil.svg'
                          className='svg-icon-3'
                        />
                      </Link>
                      <a
                        onClick={() => {
                          deleteSelectedUser(user.id)
                        }}
                        className='btn btn-icon btn-danger btn-sm button-left-margin'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
          )}
        </div>
        <PaginationControls
          currentPage={page}
          totalPages={totalPages}
          limit={limit}
          setCurrentPage={(number: any) => setPage(number)}
        />
      </div>
    </div>
  )
}

export {UsersList}
