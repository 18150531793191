import React, {useState, useEffect} from 'react'
import './style/EntityProduct.scss'
import {getProductById} from '../../../../app/modules/auth/core/Requests/_requestsProduct'
import {API_URL} from '../../LogsList'
import {Link} from 'react-router-dom'

interface Product {
  _id: string
  name: string
  sku: string
  product_index: number
  price_wt: number
  local_pictures?: {
    fileName: string
    filePath: string
  }
}

const EntityProduct = ({entity}) => {
  const [product, setProduct] = useState<Product>()

  async function fetchProduct() {
    console.log('asdfasdf', entity)
    await getProductById(entity).then((res) => {
      setProduct(res.data.product)
      console.log('product', res.data.product)
    })
  }

  useEffect(() => {
    fetchProduct()
  }, [entity])

  return (
    <>
      {product && (
        <div className='entityProduct'>
          <div className='entityProduct__container'>
            <div className='entityProduct__image'>
              <img
                className={`entityProduct__image--img ${
                  product?.local_pictures[0] ? '' : 'entityProduct__image--img-placeholder'
                }`}
                src={
                  product?.local_pictures[0]
                    ? `${API_URL.replace('/v1', '')}${product?.local_pictures[0]?.filePath}`
                    : '/media/icons/duotune/general/photo.png'
                }
                alt={product?.local_pictures[0]?.fileName || 'photo-placeholder'}
              />
            </div>
            <div className='entityProduct__details'>
              <p className='entityProduct__details--title'>
                {product?.product_index ? product?.product_index + '.' : ''} {product?.name}
              </p>
              <p className='entityProduct__details--title-secondary'>{product?.sku}</p>
            </div>
          </div>
          <div className='entityProduct__container--right'>
            <p className='entityProduct__price'>{product?.price_wt} lei</p>
            <Link to={`/edit-product/${product?._id}`} className='entityProduct__action'>
              Editează
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default EntityProduct
