import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/Requests/_requests'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formatul emailului nu este valid')
    .min(3, 'Minim 3 de caractere')
    .max(50, 'Maxim 50 de caractere')
    .required('Email este obligatoriu'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      requestPassword(values.email)
        .then((response) => {
          if (response.success) {
            setHasErrors(false)
            setLoading(false)
            navigate('/auth/reset-password')
          } else {
            throw new Error(response.message)
          }
        })
        .catch((error) => {
          setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
          // Use the actual error message from the response
          setStatus(error.message || 'An unexpected error occurred.')
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      {/* Form content */}

      {/* Begin::Form Group for Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          placeholder='Introduceți emailul'
          name='email'
          autoComplete='off'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.email && formik.errors.email,
            'is-valid': formik.touched.email && !formik.errors.email,
          })}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* End::Form Group for Email */}

      {/* Begin::Form Group for Submit */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Trimite</span>
          {loading && (
            <span className='indicator-progress'>
              Așteaptă...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Înapoi
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}

export default ForgotPassword
