import axiosConf from './_axiosInstance'

export const API_URL = process.env.REACT_APP_API_URL

export const PUT_LOCAL_PIC = `${API_URL}/products/`
export const GET_ALL_PRODUCTS = `${API_URL}/products`
export const GET_PRODUCT_BY_ID = `${API_URL}/products`
export const DELETE_PRODUCT = `${API_URL}/products`
export const SET_STOCK_AVAILABLE = `${API_URL}/stockAvailable`
export const PRODUCT_ENDPOINT = `${API_URL}/products`;

export const addNewProduct = async (
  name: string,
  sku: string,
  priceWt: number,
  remotePictures: { fileName: string; filePath: string }[],
  ean: string,
  isbn: string,
  barcode: string,
  group: string
) => {
  try {
    const payload = {
      name,
      sku,
      price_wt: priceWt,
      ean,
      isbn,
      barcode,
      group,
      remote_pictures: remotePictures,
    };

    const response = await axiosConf.post(PRODUCT_ENDPOINT, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    console.error('Add new product error', err);
    throw err;
  }
};

export const addLocalImage = async (id: string, files: File[]) => {
  const formData = new FormData();
  for (let file of files) {
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      console.error('Unsupported file type:', file.type);
      return { error: 'Unsupported file type. Only .jpg, .jpeg, .png are allowed.' };
    }
    formData.append('pictures', file);
  }

  try {
    const res = await axiosConf.put(`${PUT_LOCAL_PIC}/${id}/pictures`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (error) {
    console.error('ERROR ADDING LOCAL IMAGE:', error);
    return { error: error.response?.data || 'Unknown error' };
  }
}

export const deletePicture = async (productId: string, pictureId: string) => {
  try {
    const url = `${API_URL}/products/${productId}/pictures/${pictureId}`;
    const response = await axiosConf.delete(url);
    if (response.status === 204) {
      console.log('Picture deleted successfully');
      return { success: true };
    } else {
      console.error('Unexpected response status:', response.status);
      return { success: false, error: 'Unexpected response status' };
    }
  } catch (error) {
    console.error('ERROR DELETING PICTURE:', error);
    return { success: false, error: error.response?.data || 'Unknown error' };
  }
};



export const getAllProducts = async ({
  page,
  limit,
  search,
  hasDetails,
  full,
  deposit,
  currentUser,
}: any) => {
  let url = GET_ALL_PRODUCTS
  const selectedGroup = localStorage.getItem('group')
  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (search) queryParams.append('search', search)
  if (hasDetails !== undefined) queryParams.append('hasDetails', hasDetails.toString())
  if (full) queryParams.append('full', full.toString())
  if (deposit) queryParams.append('deposit', deposit)
  if (currentUser?.role === 'super_admin' && selectedGroup)
    queryParams.append('group', selectedGroup)

  const queryString = queryParams.toString()
  if (queryString) url += `?${queryString}`

  try {
    const response = await axiosConf.get(url)
    return response.data
  } catch (err) {
    console.log('ALL PRODUCTS ENDPOINT err', err)
    return err
  }
}

export const getProductById = async (id: string) => {
  try {
    const response = await axiosConf.get(`${GET_PRODUCT_BY_ID}/${id}`)
    return response.data
  } catch (err) {
    console.log('get PRODUCT BY ID err', err)
    return err
  }
}
export const editProduct = async (
  id: string,
  name: string,
  sku: string,
  price_wt: number | undefined,
  remote_pictures: { fileName: string; filePath: string }[],
  ean: string,
  isbn: string
  
) => {
  try {
    const payload = {
      name,
      sku,
      ean,
      isbn,
      remote_pictures,
      price_wt: price_wt !== undefined ? price_wt : ""
    };

    const response = await axiosConf.patch(`${GET_PRODUCT_BY_ID}/${id}`, payload);
    return response.data;
  } catch (err) {
    console.log('update PRODUCT err', err);
    return err;
  }
}

export const setStockAvailable = async (
  depositID: string,
  productID: string,
  qty: number,
  reason: string
) => {
  try {
    const response = await axiosConf.put(`${SET_STOCK_AVAILABLE}/${depositID}/${productID}/stock`, {
      qty,
      reason,
    })
    return response.data
  } catch (err) {
    console.log('ERR SET STOCK AVAILABLE EP', err)
    return err
  }
}

export const deleteProduct = async (productId: string) => {
  try {
    const response = await axiosConf.delete(`${DELETE_PRODUCT}/${productId}`)
    return response.data
  } catch (err) {
    console.log('ERR DELETE PROD EP', err)
    return err
  }
}

