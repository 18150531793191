/* eslint-disable react/jsx-no-target-blank */
import React, {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {getMyDetails} from '../../../../../app/modules/auth/core/Requests/_requests'
import {Link} from 'react-router-dom'
import clsx from 'clsx'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const handleLogin = async () => {
    const userDetaild = await getMyDetails()
    const training_account = userDetaild.training_account
    const url_redirect =
      'https://incom.traininggroup.ro/uuid_verification_page/?uuid=' + training_account
    window.location.href = url_redirect
  }

  return (
    <>
      {currentUser.role === 'super_admin' && (
        <>
          <SidebarMenuItem
            to='/groups'
            icon='/media/icons/duotune/general/gen025.svg'
            title={`Grupuri | ${currentUser?.group?.name || 'Toate'}`}
            fontIcon='bi-app-indicator'
          />
          <br/>
        </>
      )}
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/files/fil001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/art/user-group.svg'
        title='Utilizatori'
      />
      <SidebarMenuItem
        to='/clients'
        icon='/media/icons/duotune/communication/com005.svg'
        title='Clienți'
      />
      <SidebarMenuItem
        to='/select-deposit'
        icon='/media/icons/duotune/art/boxes.svg'
        title='Produse'
      />
      <SidebarMenuItem
        to='/deposits'
        icon='/media/icons/duotune/general/gen001.svg'
        title='Depozite'
      />
      <SidebarMenuItem
        to='/logs'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Raport angajați'
      />
      <SidebarMenuItem
        to='/reports'
        icon='/media/icons/duotune/graphs/gra012.svg'
        title='Rapoarte'
      />
      <SidebarMenuItem
        to='/receptions'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        title='Recepții marfă'
      />
      <SidebarMenuItem
        to='/orders'
        icon='/media/icons/duotune/art/paper-plane.svg'
        title='Comenzi'
      />
      {currentUser.role !== 'super_admin' && (
        <div className='menu-item'>
          <a className={'menu-link py-3 px-6 without-sub'} onClick={handleLogin}>
          <span className='menu-icon'>
            {' '}
            <KTSVG path='/media/icons/duotune/finance/fin006.svg' className='svg-icon-2'/>
          </span>
            <span className='menu-title'>Platformă de învățare</span>
          </a>
        </div>
      )}
    </>
  )
}

export {SidebarMenuMain}
