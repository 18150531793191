import React, {FC} from 'react'
import {useIntl} from 'react-intl'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useQuery} from 'react-query'
import axiosConf from '../../modules/auth/core/Requests/_axiosInstance'
import {KTSVG} from '../../../_metronic/helpers'
import {useAuth} from '../../modules/auth'
import './style/DashboardWrapper.scss'

// @ts-ignore
export const API_URL = process.env.REACT_APP_API_URL

interface ChartData {
  orders: number
  date: {
    year: number
    month: number
    day: number
  }
}

const DashboardPage: FC = () => {
  const {currentUser} = useAuth()
  const selectedGroup = localStorage.getItem('group')
  const chartOptions: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {show: false},
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['white'],
      },
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CE8D3B',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
    },
    stroke: {
      curve: 'straight',
      colors: ['#CE8D3B'],
    },
    title: {
      text: 'Title',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: '900',
      },
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [],
      tooltip: {enabled: false},
    },
    markers: {colors: ['#ffffff']},
    tooltip: {
      marker: {
        fillColors: ['#CE8D3B'],
      },
    },
  }

  // Using useQuery to fetch dashboard data
  const fetchStats = () => {
    if (currentUser.role === 'super_admin') {
      console.log('calling first')
      return axiosConf.get(`${API_URL}/dashboard?group=${selectedGroup}`).then((res) => {
        return res.data.data
      })
    } else {
      console.log('calling second')
      return axiosConf.get(`${API_URL}/dashboard`).then((res) => {
        return res.data.data
      })
    }
  }
  const {
    data: stats,
    error: statsError,
    isLoading: statsLoading,
  } = useQuery('statistics', fetchStats, {
    enabled: !(currentUser.role === 'super_admin' && !selectedGroup),
  })
  console.log('laurentiu', stats, statsError, statsLoading)

  if (currentUser.role === 'super_admin' && !selectedGroup) {
    return (
      <div className='mt-0 p-0'>
        <h3 style={{color: '#000'}}>Este necesar să selectezi mai întâi un grup.</h3>
      </div>
    )
  }
  if (statsLoading) {
    return (
      <div className='mt-0 p-0'>
        <h3 style={{color: '#000'}}>Loading...</h3>
      </div>
    )
  }

  if (statsError) {
    return (
      <div className='mt-0 p-0'>
        <h3 style={{color: '#000'}}>Error fetching data</h3>
      </div>
    )
  }

  const ordersCount = stats?.ordersCount?.map((dataPoint: any) => dataPoint.count)
  const ordersTotal = stats?.ordersTotal?.map((dataPoint: any) => dataPoint.total)

  const ordersCountOptions: ApexOptions = {
    ...chartOptions,
    title: {
      ...chartOptions.title,
      text: 'Vânzări din ultima lună',
    },
    xaxis: {
      ...chartOptions.xaxis,
      categories: stats?.ordersCount?.map((dataPoint: any) => dataPoint.date),
    },
  }
  const ordersTotalOptions: ApexOptions = {
    ...chartOptions,
    title: {
      ...chartOptions.title,
      text: 'Încasări din ultima lună',
    },
    xaxis: {
      ...chartOptions.xaxis,
      categories: stats?.ordersTotal?.map((dataPoint: any) => dataPoint.date),
    },
  }

  return (
    <div>
      {stats && (
        <div className='dashboard'>
          <div className='dashboard__section'>
            <div className='dashboard__section--container dashboard__section--big dashboard__section--container-full'>
              <div className='sponsors'>
                <Link to='/'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/eu.png')}
                    style={{
                      height: '90px',
                    }}
                  />
                </Link>
                <Link to='/'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/guv.png')}
                    style={{
                      height: '90px',
                    }}
                  />
                </Link>
                <Link to='/'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/is.png')}
                    style={{
                      height: '90px',
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className='dashboard__section--container dashboard__section--dark dashboard__section--container-half'>
              <KTSVG
                path='/media/icons/duotune/ecommerce/box-1.svg'
                className='dashboard__section--icon'
                svgClassName='dashboard__section--icon-svg'
              />
              <div>
                <h4>{stats.products}</h4>
                <p>Produse totale</p>
              </div>
            </div>
            <div className='dashboard__section--container dashboard__section--dark dashboard__section--container-half-even'>
              <KTSVG
                path='/media/icons/duotune/ecommerce/box-remove.svg'
                className='dashboard__section--icon'
                svgClassName='dashboard__section--icon-svg'
              />
              <div>
                <h4>{stats.outOfStockProducts}</h4>
                <p>Produse cu Stoc epuizat</p>
              </div>
            </div>
            <div className='dashboard__section--container dashboard__section--container-full'>
              <h3>Adaugă Produs nou</h3>
              <Link to='/add-product'>
                <button>Produs nou</button>
              </Link>
            </div>
            <div className='dashboard__section--container dashboard__section--container-graph'>
              <ReactApexChart
                options={ordersCountOptions}
                series={[{name: 'Comenzi', data: ordersCount}]}
                type='line'
                height={350}
              />
            </div>
          </div>
          <div className='dashboard__section'>
            <div className='dashboard__section--container dashboard__section--big dashboard__section--dark dashboard__section--container-half'>
              <KTSVG
                path='/media/icons/duotune/ecommerce/order.svg'
                className='dashboard__section--icon'
                svgClassName='dashboard__section--icon-svg'
              />
              <div>
                <h4>{stats.orders}</h4>
                <p>Comenzi</p>
              </div>
            </div>
            <div className='dashboard__section--container dashboard__section--big dashboard__section--dark dashboard__section--container-half-even'>
              <KTSVG
                path='/media/icons/duotune/ecommerce/users.svg'
                className='dashboard__section--icon'
                svgClassName='dashboard__section--icon-svg'
              />
              <div>
                <h4>{stats.customers}</h4>
                <p>Clienți</p>
              </div>
            </div>
            <div className='dashboard__section--container dashboard__section--container-tall dashboard__section--container-tall-white dashboard__section--container-half'>
              <div>
                <KTSVG
                  path='/media/icons/duotune/ecommerce/storage.svg'
                  className='dashboard__section--icon'
                  svgClassName='dashboard__section--icon-svg'
                />
                <div>
                  <h4>{stats.deposits}</h4>
                  <p>Depozite</p>
                </div>
              </div>
              <Link to='/deposits'>
                <button>Vezi depozitele</button>
              </Link>
            </div>
            <div className='dashboard__section--container-tall dashboard__section--container-tall-group dashboard__section--container-half-even'>
              <div className='dashboard__section--group dashboard__section--group-half'>
                <KTSVG
                  path='/media/icons/duotune/ecommerce/admin.svg'
                  className='dashboard__section--icon'
                  svgClassName='dashboard__section--icon-svg'
                />
                <div>
                  <h4>{stats.admins}</h4>
                  <p>Admini</p>
                </div>
              </div>
              <div className='dashboard__section--group dashboard__section--group-half-even'>
                <KTSVG
                  path='/media/icons/duotune/ecommerce/employee-id.svg'
                  className='dashboard__section--icon'
                  svgClassName='dashboard__section--icon-svg'
                />
                <div>
                  <h4>{stats.employees}</h4>
                  <p>Angajați</p>
                </div>
              </div>
            </div>
            <div className='dashboard__section--container dashboard__section--container-graph'>
              <ReactApexChart
                options={ordersTotalOptions}
                series={[{name: 'Comenzi', data: ordersTotal}]}
                type='line'
                height={350}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <div className='mt-0 p-0'>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
