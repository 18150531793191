/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {addNewUser, addNewSuperUser} from '../../app/modules/auth/core/Requests/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers/components/KTSVG'
// import { toAbsoluteUrl} from '../../_metronic/helpers/components/KTCardBody'
import {PasswordMeterComponent} from '../../_metronic/assets/ts/components/'
import {useAuth} from '../../app/modules/auth/core/Auth'
import './style/AddUser.scss'

const initialValues = {
  name: '',
  phone: '',
  role: '',
  email: '',
  password: '',
  changepassword: '',
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de caractere')
    .required('Numele este obligatoriu'),
  email: Yup.string()
    .email('Formatul emailului este incorect')
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de caractere')
    .required('Emailul este obligatoriu'),
  password: Yup.string()
    .min(3, 'Minim 3 caractere')
    .max(50, 'Maxim 50 de caractere')
    .required('Parola este obligatorie'),
  phone: Yup.string()
    .min(10, 'Numărul de telefon conține mai puțin de 10 numere.')
    .max(10, 'Numărul de telefon conține mai mult de 10 numere.')
    .required('Numărul de telefon este obligatoriu.'),
  changepassword: Yup.string()
    .required('Confirmarea parolei este obligatorie')
    .test('passwords-match', 'Parolele nu coincid.', function (value) {
      return this.parent.password === value
    }),
})

export function AddUser() {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()
  const [showModal, setShowModal] = useState(false)

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      console.log('heheheheheheheh', currentUser)
      setLoading(true)
      if (currentUser.role === 'super_admin') {
        await addNewSuperUser(
          values.name,
          values.phone,
          values.role,
          values.email,
          values.password,
          values.changepassword,
          localStorage.getItem('group')
        )
          .then((res) => {
            setShowModal(true)
            return res
          })
          .catch((err) => {
            console.error('ADD USER response IN ADDUSER.TSX', err)
            setStatus('Datele nu sunt corecte')
            setSubmitting(false)
            setLoading(false)
            return err
          })
      } else {
        console.log('values', values)
        await addNewUser(
          values.name,
          values.phone,
          values.role,
          values.email,
          values.password,
          values.changepassword
        )
          .then((res) => {
            setShowModal(true)
            return res
          })
          .catch((err) => {
            console.error('ADD USER response IN ADDUSER.TSX', err)
            setStatus('Datele nu sunt corecte')
            setSubmitting(false)
            setLoading(false)
            return err
          })
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <div className='users'>
        <div className='users__title'>
          <h1 className='card-title align-items start flex-column mb-0'>Utilizatori</h1>
        </div>
        <div className='users__separator' />
        <div className='users__container'>
          <div className='users__subtitle'>
            <h2>Adaugă utilizator</h2>
          </div>
          <form noValidate id='kt_login_signup_form' onSubmit={formik.handleSubmit}>
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            <div className='users__section'>
              {/* begin::Form group Firstname */}
              <div className='fv-row mb-8'>
                <label className='form-label users__input--label'>Nume complet</label>
                <input
                  placeholder='Popescu Ion'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control users__input',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              <div className='fv-row mb-8'>
                <label className='form-label users__input--label'>Telefon</label>
                <input
                  placeholder='07--------'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('phone')}
                  className={clsx(
                    'form-control users__input',
                    {
                      'is-invalid': formik.touched.phone && formik.errors.phone,
                    },
                    {
                      'is-valid': formik.touched.phone && !formik.errors.phone,
                    }
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='mb-10'>
                <label htmlFor='role' className='form-label users__input--label'>
                  Rol utilizator:
                </label>
                <select
                  className={clsx(
                    'form-control users__input',
                    {
                      'is-invalid': formik.touched.role && formik.errors.role,
                    },
                    {
                      'is-valid': formik.touched.role && !formik.errors.role,
                    }
                  )}
                  name='role'
                  value={formik.values.role} // Bind select value to Formik's state
                  onChange={formik.handleChange} // Use Formik's handleChange method
                  onBlur={formik.handleBlur} // Optionally handle onBlur for touch feedback
                  style={{background: 'white', border: '2px solid #ffe3bf'}}
                >
                  <option value='user'>User</option>
                  <option value='admin'>Admin</option>
                </select>
              </div>
              {/* begin::Form group Email */}
              <div className='fv-row mb-8'>
                <label className='form-label users__input--label'>Email</label>
                <input
                  placeholder='exemplu@domeniu.com'
                  type='email'
                  autoComplete='off'
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    'form-control users__input',
                    {'is-invalid': formik.touched.email && formik.errors.email},
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group Password */}
              <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                  <label className='form-label users__input--label'>Parola</label>
                  <div className='position-relative mb-3'>
                    <input
                      type='password'
                      placeholder='*********'
                      autoComplete='off'
                      {...formik.getFieldProps('password')}
                      className={clsx(
                        'form-control users__input',
                        {
                          'is-invalid': formik.touched.password && formik.errors.password,
                        },
                        {
                          'is-valid': formik.touched.password && !formik.errors.password,
                        }
                      )}
                    />
                    {formik.touched.password && formik.errors.password && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.password}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* begin::Meter */}
                  <div
                    className='d-flex align-items-center mb-3'
                    data-kt-password-meter-control='highlight'
                  >
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                  </div>
                  {/* end::Meter */}
                </div>
                <div className='text-muted'>
                  Folosește 8 sau mai multe caractere cu litere, numere și simboluri.
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Form group Confirm password */}
              <div className='fv-row mb-5'>
                <label className='form-label users__input--label'>Confirmă parola</label>
                <input
                  type='password'
                  placeholder='*********'
                  autoComplete='off'
                  {...formik.getFieldProps('changepassword')}
                  className={clsx(
                    'form-control users__input',
                    {
                      'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                    },
                    {
                      'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                    }
                  )}
                />
                {formik.touched.changepassword && formik.errors.changepassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.changepassword}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
            </div>

            {/* begin::Form group */}
            <button
              type='submit'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_1'
              id='kt_sign_up_submit'
              className='products__action'
              style={{background: '#CE8D3B'}}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Adaugă</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Așteaptă...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            {/* end::Form group */}
          </form>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content' style={{backgroundColor: 'white'}}>
            <div className='modal-header'>
              <h5 className='modal-title text-light'>Contul a fost creat cu succes</h5>
              <button
                className='btn-close btn-close-white'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <p className='text-light'>
                Pentru a se loga, angajatul trebuie să își activeze contul în maxim 15 minute din
                link-ul trimis pe mail. Dacă nu a fost găsit, trebuie să verifice și folderul
                'Spam'.
              </p>
              <div className='text-end mt-10'>
                <button
                  data-bs-dismiss='modal'
                  type='button'
                  className='btn btn-success'
                  onClick={() => {
                    setLoading(false)
                    navigate('/users')
                  }}
                >
                  Mai departe
                </button>
              </div>
            </div>
            {/* <div className='modal-footer'></div> */}
          </div>
        </div>
      </div>
    </>
  )
}
