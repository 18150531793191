import React, {useEffect, useState} from 'react'
import './rangeItem.scss'

interface RangeItemProps {
  range: {start: {row: number; col: number}; end: {row: number; col: number}; name: string}
  scale: number
  width: number
  height: number
  onDelete: () => void
  onNameChange: (newName: string) => void
}

const RangeItem: React.FC<RangeItemProps> = ({
  range,
  scale,
  width,
  height,
  onDelete,
  onNameChange,
}) => {
  const [inputValue, setInputValue] = useState(range.name)
  const [debouncedInputValue, setDebouncedInputValue] = useState('')

  let rangeStartRow = range.start.row
  let rangeStartCol = range.start.col
  let rangeEndRow = range.end.row
  let rangeEndCol = range.end.col

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue)
      onNameChange(inputValue)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [inputValue, 500])

  return (
    <div className='rangeItem'>
      <input
        className='rangeItem__cell rangeItem__cell--input'
        type='text'
        placeholder='Introdu numele zonei'
        value={inputValue}
        onChange={handleInputChange}
      />
      <span className='rangeItem__cell rangeItem__cell--text'>
        De la ({range.start.row}, {range.start.col}) până la ({rangeEndRow}, {rangeEndCol})
      </span>
      <button onClick={onDelete} className='rangeItem__cell  rangeItem__cell--button'>
        Șterge
      </button>
    </div>
  )
}

export default RangeItem
