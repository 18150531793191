import {response} from 'express'
import {useEffect, useState} from 'react'

interface TaxData {
  status: string
  data: {
    all: number
    results: number
    currentPage: number
    pages: number
    taxes: {
      id: string
      name: string
      rate: number
      active: boolean
      createdAt: string
    }[]
  }
}

export function AddNewClient() {
  const [loading, setLoading] = useState(false)

  //   const [prodName, setProdName] = useState('')
  //   const [sku, setSku] = useState('')
  //   const [priceWt, setPriceWt] = useState(0)
  //   const [tva, setTva] = useState('')
  //   const [remotePicture, setremotePicture] = useState('')
  //   const [ean, setEan] = useState('')
  //   const [isbn, setIsbn] = useState('')
  //   const [barcode, setBarcode] = useState('')

  // setTimeout(() => console.log('tax values', taxValues), 500)

  //   function checkFormRequirements() {
  //     if (prodName == '') {
  //       return true
  //     }
  //   }

  //   const Product = {
  //     name: prodName,
  //     sku: sku,
  //     pricewt: priceWt,
  //     tva: tva,
  //     ean: ean,
  //     isbn: isbn,
  //     barcode: barcode,
  //   }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target

    console.log('value in handle change AddnewProduct.tsx', name, value)

    //     switch (name) {
    //       case 'name':
    //         setProdName(value)
    //         break
    //       case 'sku':
    //         setSku(value)
    //         break
    //       case 'priceWT':
    //         setPriceWt(Number(value))
    //         break
    //       case 'TVA':
    //         setTva(value)
    //         break
    //       // case 'remotePicture':
    //       //   setRemotePicture(value)
    //       //   break
    //       case 'ean':
    //         setEan(value)
    //         break
    //       case 'isbn':
    //         setIsbn(value)
    //         break
    //       case 'barcode':
    //         setBarcode(value)
    //         break
    //       default:
    //         break
    //     }

    //     setProdName(event.target.value)
    //     console.log('This is the submitted product:', Product)
  }

  return (
    <div>
      <div className='mb-10'>
        <h1 style={{color: 'black'}}>Client nou</h1>
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          //   console.log('PROD NAME ON SUBMIT', prodName)
        }}
      >
        {/* start product form here */}

        {/* NAME input start */}
        <div className='subform'>
          <div className='mb-10 row'>
            <div className='col'>
              <label className='required form-label text-light'>Nume</label>
              <input
                name='nume'
                // value={prodName}
                onChange={handleChange}
                type='text'
                className='form-control  form-control-solid bg-transparent'
                placeholder='Nume'
              />
            </div>
            <div className='col'>
              <label className='required form-label text-light'>Prenume</label>

              <input
                name='prenume'
                // value={prodName}
                onChange={handleChange}
                type='text'
                className='form-control form-control-solid bg-transparent'
                placeholder='Prenume'
              />
            </div>
          </div>
          {/* NAME input end */}

          {/* Email input start */}
          <div className='mb-10'>
            <label className='required form-label text-light'>Email</label>
            <input
              onChange={handleChange}
              name='email'
              type='text'
              // value={sku}
              className='form-control form-control-solid bg-transparent'
              placeholder='Email'
            />
          </div>
          {/* Email input end */}

          {/* Locatie input start */}
          <div className='mb-10 row'>
            <div className='col'>
              <label className='required form-label text-light'>Locație</label>
              <select
                className='form-control form-control-solid bg-transparent'
                name='Judet'
                onChange={() => handleChange}
              >
                <option>Selectează un județ</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div className='col'>
              <label className='form-label text-light' style={{marginTop: '15px'}}></label>
              <select
                className='form-control form-control-solid bg-transparent'
                name='Oras'
                onChange={() => handleChange}
              >
                <option>Selectează un oraș</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
          </div>
          {/* Locatie input end */}

          {/* Telefon input start */}
          <div className='mb-10'>
            <label className='required form-label text-light'>Telefon</label>
            <input
              onChange={handleChange}
              name='Telefon'
              type='number'
              // value={sku}
              className='form-control form-control-solid bg-transparent'
              placeholder='Telefon'
            />
          </div>
          {/* telefon input end */}
        </div>

        <div className='subform'>
          {/* Nume societate input start */}
          <div className='mb-10 row'>
            <div className='col'>
              <label className='required form-label text-light'>Nume societate</label>
              <input
                className='form-control form-control-solid bg-transparent'
                type='text'
                name='societate'
                placeholder='Societate'
                onChange={handleChange}
              />
            </div>
            {/* CUI input start */}
            <div className='col'>
              <label className='required form-label text-light'>CUI</label>
              <input
                onChange={handleChange}
                // value={ean}
                name='cui'
                type='number'
                className='bg-transparent form-control form-control-solid'
                placeholder='CUI'
              />
            </div>
          </div>
          {/* CUI input end */}
          {/* Nume societate input end */}

          <div className=' mb-10 row'>
            {/* Domeniu de activitate input start */}
            <div className='col'>
              <label className='required form-label text-light'>Domeniu de activitate</label>
              <select
                onChange={() => handleChange}
                // value={isbn}
                name='activitate'
                className='form-control form-control-solid bg-transparent'
                // placeholder='Domeniu'
              >
  <option value="" disabled selected>Selectează un domeniu</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            {/* Domeniu de activitate input end */}

            {/* Tip solicitare serviciu input start */}
            <div className='col'>
            <option value="" disabled selected>Selectează un tip de serviciu</option>
              <select
                onChange={() => handleChange}
                // value={isbn}
                name='Serviciu'
                className='form-control form-control-solid bg-transparent'
                // placeholder='Serviciu'
              >
                <option>Selectează un tip de serviciu</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
          </div>
          {/* Tip solicitare serviciu input end */}

          {/* Adresa input start */}
          <div className='mb-10'>
            <label className='required form-label text-light'>Adresă</label>
            <input
              onChange={handleChange}
              name='Adresa'
              type='text'
              // value={sku}
              className='form-control form-control-solid bg-transparent'
              placeholder='Adresa'
            />
          </div>
          {/* telefon input end */}
        </div>
        {/* local Pictures input start */}

        <div className='mb-10'>
          <label className='form-label text-light'>Introdu o poză din calculatorul tău</label>
          <div style={{display: 'flex', gap: '10px'}}>
            <input
              type='file'
              id='myFile'
              className='form-control form-control-solid bg-transparent'
              name='filename'
              // onChange={(e) => localImages(e)}
              multiple
            />
            {/* <button className='btn btn-primary' onClick={handleClear}> */}
            <button className='btn btn-danger'>Șterge</button>
          </div>
          <div className='form-label mt-5 text-gray-600' style={{fontStyle: 'italic'}}>
            Format suportat: jpg, jpeg, png{' '}
          </div>
        </div>

        {/* local Pictures input end */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn'
            style={{backgroundColor: '#CE8D3B'}}
            // disabled={checkFormRequirements()}
          >
            {!loading && <span className='indicator-label'>Crează produs</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </div>
  )
}
