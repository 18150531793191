import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
// @ts-ignore
import {CookieBanner} from '@keepist/react-gdpr-cookie-banner'
import './style/App.scss'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
        <div>
          <CookieBanner
            message='Utilizăm cookie-uri pentru îmbunătățirea experienței tale online. Prin continuarea utilizării acestui site, ești de acord cu utilizarea cookie-urilor conform politicii noastre de confidențialitate.'
            privacyPolicyLinkText='Politica de Confidențialitate'
            policyLink='/#'
            necessaryOptionText='Necesare'
            preferencesOptionText='Preferințe'
            statisticsOptionText='Statistici'
            marketingOptionText='Marketing'
            acceptAllButtonText='Acceptă Tot'
            acceptSelectionButtonText='Acceptă Selecția'
            showAcceptSelectionButton='true'
            styles={{
              dialog: {
                position: 'fixed',
                bottom: '0',
                zIndex: '9999',
                width: '100%',
                backgroundColor: '#fff',
                borderTop: '8px solid #b97e36',
                boxShadow: 'rgba(0, 0, 0, .5) 0px 0px 10px 0px',
              },
              container: {
                maxWidth: '960px',
                margin: '0.5rem auto',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },
              selectPane: {
                height: '4rem',
                alignItems: 'center',
                display: 'flex',
              },
              optionWrapper: {
                marginRight: '1.5rem',
              },
              optionLabel: {
                display: 'flex',
                color: '#1c1c0d',
                fontSize: '1.125rem',
                fontWeight: '500',
              },
              checkbox: {
                marginRight: '.5rem',
                height: '18px',
                width: '18px',
              },
              policy: {
                fontSize: '10pt',
                color: 'rgb(0, 0, 0)',
                textDecoration: 'underline',
                width: 'fit-content',
              },
              buttonWrapper: {
                position: 'absolute',
                right: '22rem',
                display: 'flex',
                bottom: '2rem',
                height: '3rem',
                margin: '.5rem 0',
              },
              button: {},
            }}
            onAcceptPreferences={() => {
              // load your preferences trackers here
            }}
            onAcceptStatistics={() => {
              // load your statistics trackers here
            }}
            onAcceptMarketing={() => {
              // load your marketing trackers here
            }}
          />
        </div>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
