import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {getDepositById} from '../../app/modules/auth/core/Requests/_requestsDeposits'

interface Deposit {
  name: string
  address: string
}

const DepositDetails = () => {
  const [depositData, setDepositData] = useState<Deposit>()

  const navigate = useNavigate()

  const {id} = useParams<{id: string}>()
  const depositID = id!.substring(1)

  useEffect(() => {
    // console.log('=======================', depositID)
    getDepositById(depositID)
      .then((res) => {
        setDepositData(res.data.deposit)
        console.log('res in get deposit by id', res)
      })
      .catch((err) => {
        console.log('ERR IN GET DEPOSIT BY ID', err)
      })
  }, [id])

  return (
    <>
      <div className='card-body py-3 fw-bold bg-light p-10 rounded mb-10'>
        {/* begin::Table container */}
        <div style={{textAlign: 'right'}}>
          <button
            onClick={() => navigate(`/edit-deposit:${depositID}`)}
            className='btn btn-primary mt-5'
          >
            Editează
          </button>
        </div>

        <table
          className='table align-middle'
          style={{
            tableLayout: 'fixed',
          }}
        >
          <thead>
            <tr>
              <th className='text-left p-5' style={{width: '50%'}}>
                <h4 className='m-0 pr-5'>Nume depozit:</h4>
              </th>

              <th className='text-left p-5' style={{width: '50%'}}>
                <h4 className='m-0 pr-5'>Adresă:</h4>
              </th>
            </tr>
          </thead>

          <tbody style={{border: '3px solid #2B2B40'}}>
            <tr>
              <td className='p-5' style={{borderRight: '2px solid #2B2B40', width: '50%'}}>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div>
                      <h2 className='m-0'>{depositData !== undefined ? depositData.name : ''}</h2>
                    </div>
                  </div>
                </div>
              </td>
              <td className='p-5' style={{borderRight: '2px solid #2B2B40', width: '50%'}}>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                      <h2 className='m-0'>
                        {depositData !== undefined ? depositData.address : ''}
                      </h2>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default DepositDetails
