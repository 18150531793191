import {debounce} from 'lodash'
import {ChangeEvent, useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {Link} from 'react-router-dom'
import {
  deleteDepositById,
  getAllDeposits,
} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {KTSVG} from '../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import PaginationControls from '../components/PaginationControls'
import IFrame from './PreviewDeposits/IFrame'
import {useAuth} from '../../app/modules/auth'
import './style/DepositsPage.scss'

interface Deposit {
  id: string
  name: string
  address: string
}

interface DepositInfo {
  id: string
  name: string
}

function DepositsPage() {
  const selectedGroup = localStorage.getItem('group')
  const {auth, currentUser} = useAuth()
  const [allDeposits, setAllDeposits] = useState([])
  const [flag, setFlag] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [search, setSearch] = useState('')

  const [showModal, setShowModal] = useState(false)
  const [selectedDepositData, setSelectedDepositData] = useState<DepositInfo>({
    id: '',
    name: '',
  })

  //IFrame
  const [showFullScreenModal, setShowFullScreenModal] = useState(false)
  const [selectedDepositId, setSelectedDepositId] = useState('')

  const handleDepositSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    console.log(searchTerm)

    getAllDeposits({search: searchTerm, currentUser: currentUser}).then((res) => {
      console.log('res in DepositsPage.tsx', res)
      // if (res.data.deposits.length !== 0) {
      setAllDeposits(res.data.deposits)
    })
  }

  const debouncedHandleDepositSearch = debounce(handleDepositSearch, 1000)

  const navigate = useNavigate()

  // useEffect(() => {
  //   getAllDeposits().then((res) => {
  //     console.log('res in DepositsPage.tsx', res)
  //     if (res.data.deposits.length !== 0) {
  //       setAllDeposits(res.data.deposits)
  //     } else {
  //       setFlag(true)
  //     }
  //   })
  // }, [])

  useEffect(() => {
    getAllDeposits({page, limit, search, currentUser}).then((res) => {
      console.log('res in DepositsPage.tsx', res)
      if (res && res.data && res.data.deposits.length !== 0) {
        setAllDeposits(res.data.deposits)
        setTotalPages(res.data.pages)
      }
    })
  }, [page, limit, search])

  const handleDeleteDeposit = () => {
    deleteDepositById(selectedDepositData.id)
      .then((res) => {
        console.log('RESULT OF DEPOSIT DELETE', res)
      })
      .catch((err) => {
        console.log('ERR OF DEPOSIT DELETE', err)
      })

    const depositId = allDeposits.findIndex(
      (deposit: Deposit) => deposit.id.toString() === selectedDepositData.id
    )

    if (depositId !== -1) {
      allDeposits.splice(depositId, 1)
    }

    setShowModal(false)
  }

  const setCurrentPage = (number: any) => {
    setPage(number)
  }

  const handleModalAppearance = (id: string, name: string) => {
    setSelectedDepositData({id: id, name: name})
    setShowModal(true)
  }

  // IFrame action
  const [adminStatus, setAdminStatus] = useState(false)
  const [iframeToken, setIframeToken] = useState('')

  const handleEyeIconClick = async (id: string) => {
    try {
      const token = auth?.accessToken
      const response = await fetch(`https://api.incom.ai/v1/deposits/${id}?token=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const data = await response.json()
      if (response.ok) {
        console.log('API Response:', data)
        setAdminStatus(data.data.admin)
        setIframeToken(data.data.token)
        setShowFullScreenModal(true)
        setSelectedDepositId(id)
      } else {
        console.error('API Error:', data.message)
      }
    } catch (error) {
      console.error('Request failed:', error)
    }
  }

  return (
    <div className='deposits'>
      <div className='deposits__title'>
        <h1 className='card-title align-items-start flex-column mb-0'>Depozite</h1>

        <div className='deposits__title--search'>
          <input
            onChange={(event) => debouncedHandleDepositSearch(event)}
            type='text'
            placeholder='Caută un depozit'
          />

          <div style={{position: 'relative'}}>
            <button
              onClick={() => navigate('/create-deposit')}
              disabled={currentUser.role === 'super_admin' && !selectedGroup}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
              <p>Depozit nou</p>
            </button>
            {currentUser.role === 'super_admin' && !selectedGroup && (
              <p className='disabledNote' style={{position: 'absolute'}}>
                Nici un grup selectat.
              </p>
            )}
          </div>
        </div>
      </div>
      {/* end::Header */}
      <div className='deposits__separator'/>
      {/* begin::Body */}
      <div className='deposits__container'>
        {/* begin::Table container */}
        <div className='deposits__subtitle'>
          <h2>Listă depozite</h2>
        </div>
        <div className='deposits__table'>
          {allDeposits.length ? (
            <>
              <div className='deposits__details--row deposits__table--row'>
                <p className='deposits__details--row-header'>Nume</p>
                <p className='deposits__details--row-header'>Adresă</p>
                <p className='deposits__details--row-header'>Acțiune</p>
              </div>
              {/* begin::Table */}

              {showModal && (
                <DeleteModal
                  active={true}
                  depositName={selectedDepositData.name}
                  handleClose={() => setShowModal(false)}
                  handleDeleteDeposit={() => handleDeleteDeposit()}
                />
              )}

              {/* begin::Table body */}
              {allDeposits.map((deposit: Deposit) => {
                return (
                  <div
                    key={deposit.id}
                    className='deposits__details--row deposits__details--deposit deposits__table--row'
                  >
                    <Link
                      to={''}
                      className='deposits__details--row-info'
                      onClick={() => handleEyeIconClick(deposit.id)}
                    >{deposit.name}</Link>
                    <p className='deposits__details--row-info'>{deposit.address}</p>
                    <div className='deposits__details--row-info'>
                      <Link
                        // to={'/preview-deposits/' + deposit.id}
                        onClick={() => handleEyeIconClick(deposit.id)}
                        className='btn btn-icon btn-light btn-sm'
                        to={''}
                      >
                        <KTSVG path='/media/icons/duotune/arrows/eye.svg' className='svg-icon-3'/>
                      </Link>
                      <Link
                        to={`/edit-deposit/${deposit.id}`}
                        className='btn btn-icon btn-light btn-sm button-left-margin'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen055.svg'
                          className='svg-icon-3'
                        />
                      </Link>
                      {/* Delete Button */}
                      <a
                        onClick={() => {
                          handleModalAppearance(deposit.id, deposit.name)
                          console.log('deposit DELETE ID', deposit)
                        }}
                        className='btn btn-icon btn-danger btn-sm button-left-margin'
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </a>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
          )}
          {/* end::Table body */}
          {/* end::Table */}
          <IFrame
            show={showFullScreenModal}
            depositId={selectedDepositId}
            accessToken={auth?.accessToken}
            adminStatus={adminStatus}
            iframeToken={iframeToken}
            onClose={() => setShowFullScreenModal(false)}
          />
        </div>

        {allDeposits.length ? (
          <PaginationControls
            currentPage={page}
            totalPages={totalPages}
            limit={limit}
            setCurrentPage={(number: any) => setPage(number)}
          />
        ) : (
          ''
        )}

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export default DepositsPage

interface DeleteProps {
  active: boolean
  depositName: string
  handleClose: () => void
  handleDeleteDeposit: () => void
}

const MyButton: any = Button

const DeleteModal = ({active, depositName, handleClose, handleDeleteDeposit}: DeleteProps) => {
  // Define the handleClose function with an explicit return type of void
  const handleCloseEvent = (): void => {
    // Your code to close the modal or perform other actions
    handleClose()
  }

  return (
    <>
      <Modal show={active} onHide={handleClose} centered>
        <Modal.Header style={{background: 'black'}} closeButton>
          <Modal.Title>Ștergere depozit</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            background: 'white',
            borderBottomRightRadius: '6px',
            borderBottomLeftRadius: '6px',
          }}
        >
          <h4 className='text-light'>Sigur ștergi {depositName}?</h4>
          <div className='d-flex mt-10 justify-content-end gap-5'>
            <MyButton
              variant='dark'
              onClick={(): void => {
                handleClose()
              }}
            >
              Închide
            </MyButton>
            <MyButton variant='danger' onClick={handleDeleteDeposit}>
              Șterge
            </MyButton>
          </div>
        </Modal.Body>
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  )
}
