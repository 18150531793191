/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import {deleteProduct, getAllProducts} from '../../app/modules/auth/core/Requests/_requestsProduct'
import {getDepositById} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {Button, Form, Modal} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {debounce} from 'lodash'
import PaginationControls from '../components/PaginationControls'
import {useAuth} from '../../app/modules/auth'

type Props = {
  className: string
}
interface Product {
  _id: string
  name: string
  sku: string
  price: number
  stock_data: {stock: number}
}

interface DeleteInfo {
  name: string
  id: string
}

interface Deposit {
  _id: string
  id: string
  name: string
}

const ProductList: React.FC<Props> = ({className}) => {
  const [allDeposits, setAllDeposits] = useState<Deposit[]>([])
  const [allProducts, setAllProducts] = useState([])
  const [products, setProducts] = useState([])
  const [depositDetails, setDepositDetails] = useState<Deposit>()
  const [showModal, setShowModal] = useState(false)
  const [selectedProductDetails, setSelectedProductDetails] = useState<DeleteInfo>({
    name: '',
    id: '',
  })
  const [flag, setFlag] = useState(false)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const selectedGroup = localStorage.getItem('group')

  const {id} = useParams()
  const depositID = id?.substring(0)

  useEffect(() => {
    if (depositID !== undefined) {
      getAllProducts({full: true, deposit: depositID, currentUser: currentUser})
        .then((res) => {
          setProducts(res.data.products)
        })
        .catch((err) => {
          return err
        })

      getDepositById(depositID).then((res) => {
        setDepositDetails(res.data)
      })
    } else {
      getAllProducts({full: true, deposit: 'null', currentUser: currentUser})
        .then((res) => {
          setProducts(res.data.products)
        })
        .catch((err) => {
          return err
        })
    }
  }, [depositID])

  function showStockAvailable(stockData: any) {
    let totalQty = 0
    stockData.map((stock: any) => {
      totalQty = totalQty + stock.qty
    })
    return totalQty
  }
  function handleModalAppearance(name: string, id: string) {
    setSelectedProductDetails({name: name, id: id})
    setShowModal(true)
    console.log('Modal opened')
  }

  function handleDeleteProd() {
    deleteProduct(selectedProductDetails.id)
      .then(() => {
        console.log(`Product ${selectedProductDetails.name} deleted successfully.`)

        const updatedProducts = allProducts.filter(
          (product) => product._id !== selectedProductDetails.id
        )
        setAllProducts(updatedProducts)

        const updatedPageProducts = products.filter(
          (product) => product._id !== selectedProductDetails.id
        )
        setProducts(updatedPageProducts)

        setShowModal(false)
      })
      .catch((err) => {
        console.log('Error deleting product:', err)
      })
  }

  function handleDropdownSubmit(deposit: any) {
    navigate('/select-deposit')
  }

  const handlePoductSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value
    setFlag(false)

    getAllProducts({search: searchTerm, deposit: depositID, currentUser: currentUser}).then(
      (res) => {
        // if (res.data.deposits.length !== 0) {
        setProducts(res.data.products)
        if (res.data.products.length == 0) {
          setFlag(true)
        }
      }
    )
  }

  const debouncedHandleProductSearch = debounce(handlePoductSearch, 1000)
  const debouncedHandleDropdownSubmit = debounce(handleDropdownSubmit, 500)

  return (
    <>
      <div className='products'>
        <div className='products__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>Produse</h1>
          <div className='products__title--search'>
            <button
              onClick={() => navigate('/add-product')}
              className='products__title--search-button'
              style={{backgroundColor: '#CE8D3B', display: 'flex', alignItems: 'center'}}
              disabled={currentUser.role === 'super_admin' && !selectedGroup}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              <p>Produs nou</p>
            </button>
            {currentUser.role === 'super_admin' && !selectedGroup && (
              <p className='disabledNote'>Nici un grup selectat.</p>
            )}
          </div>
        </div>
        <div className='products__separator' />
        <div className='filters'>
          <h2 className='filters-title'>Filtre</h2>
          <div className='filter-container'>
            <div className='filter'>
              <input
                onChange={(event) => debouncedHandleProductSearch(event)}
                type='text'
                placeholder='Caută produs'
              />
            </div>
          </div>
          <div className='filter-container'>
            <div className='filter'>
              <select
                defaultValue={''}
                name='deposit'
                onChange={(event) => debouncedHandleDropdownSubmit(event.target.value)}
              >
                <option value='null'>Vezi toate produsele</option>
                <option value='' className='p-5' disabled>
                  Vezi produsele fără depozite
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className='products__separator' />
        <div className='products__container'>
          <div className='products__subtitle'>
            <h2>
              {depositID === undefined
                ? 'Produse neatribuite'
                : `Produse din ${depositDetails?.name}`}
            </h2>
          </div>
          <div className='products__table'>
            <div className='products__details--row products__table--row'>
              <p className='products__details--row-header'>SKU</p>
              <p className='products__details--row-header'>Nume</p>
              <p className='products__details--row-header'>Stoc</p>
              <p className='products__details--row-header'>Preț</p>
              <p className='products__details--row-header'>Acțiuni</p>
            </div>
            {products.map((product: Product, id) => {
              return (
                <div
                  key={`${product.sku}`}
                  className='products__details--row products__details--product products__table--row'
                >
                  <p className='products__details--row-info'>{product.sku}</p>
                  <p className='products__details--row-info'>{product.name}</p>
                  <p className='products__details--row-info'>
                    {showStockAvailable(product.stock_data)}
                  </p>
                  <p className='products__details--row-info'>{product.price ? product.price : 0}</p>
                  <div className='products__details--row-info'>
                    <Link
                      to={`/edit-product/${product._id}`}
                      className='btn btn-icon btn-light btn-sm'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/pencil.svg' className='svg-icon-3' />
                    </Link>
                    <a
                      type='button'
                      onClick={() => handleModalAppearance(product.name, product._id)}
                      className='btn btn-icon btn-danger btn-sm'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        {showModal && (
          <DeleteModal
            active={true}
            prodName={selectedProductDetails.name}
            handleClose={() => setShowModal(false)}
            handleDeleteProd={() => handleDeleteProd()}
          />
        )}
        {flag && <h3 className='px-10 pb-5'>Nu există produse conform căutării tale.</h3>}
      </div>
    </>
  )
}

export {ProductList}

interface DeleteProps {
  active: boolean
  prodName: string
  handleClose: () => void
  handleDeleteProd: () => void
}

const MyButton: any = Button

const DeleteModal = ({active, prodName, handleClose, handleDeleteProd}: DeleteProps) => {
  //   const [show, setShow] = useState(true)

  //   const handleClose = () => setShow(false)
  //   const handleShow = () => setShow(true)

  return (
    <>
      <Modal show={active} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Șterge produs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>Sigur ștergi {prodName}?</div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <MyButton variant='secondary' onClick={handleClose}>
            Închide
          </MyButton>
          <MyButton variant='primary' onClick={handleDeleteProd}>
            Șterge
          </MyButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}
