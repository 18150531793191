import axios from 'axios'
import axiosConf from './_axiosInstance'
import {GET_ALL_PRODUCTS} from './_requestsProduct'

export const API_URL = process.env.REACT_APP_API_URL
export const GET_LOGS = `${API_URL}/logs`

export const getAllLogs = async ({
  page,
  limit,
  currentUser,
  action,
  entity,
  dateFrom,
  dateTo,
}: any) => {
  let url = GET_LOGS
  const selectedGroup = localStorage.getItem('group')

  try {
    const queryParams = new URLSearchParams()
    if (page) queryParams.append('page', page.toString())
    if (limit) queryParams.append('limit', limit.toString())
    if (action) queryParams.append('action', action.toString())
    if (entity) queryParams.append('entity', entity.toString())
    if (dateFrom) queryParams.append('dateFrom', dateFrom.toString())
    if (dateTo) queryParams.append('dateTo', dateTo.toString())
    if (currentUser?.role === 'super_admin' && selectedGroup)
      queryParams.append('group', selectedGroup)
    const queryString = queryParams.toString()
    if (queryString) url += `?${queryString}`

    const response = await axiosConf.get(url)
    return response.data
  } catch (err) {
    console.log('get ALL LOGS err', err)
    return err
  }
}
