import React, {useEffect, useState} from 'react'
import './rangeItemShelf.scss'
import {KTSVG} from '../../../_metronic/helpers'

interface val {
  fata: number
  name: string
  levels: number
}

interface RangeItemProps {
  range: {start: {row: number; col: number}; end: {row: number; col: number}; name: string, levels: number, fata: number}
  scale: number
  width: number
  height: number
  onDelete: () => void
  onNameChange: (val: val) => void
}

const RangeItemShelf: React.FC<RangeItemProps> = ({
  range,
  scale,
  width,
  height,
  onDelete,
  onNameChange,
}) => {
  const [inputValue, setInputValue] = useState(range.name)
  const [fata, setFata] = useState(range.fata)
  const [levels, setLevels] = useState(range.levels)
  const [debouncedInputValue, setDebouncedInputValue] = useState('')

  let rangeEndRow = range.end.row
  let rangeEndCol = range.end.col

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleFataChange = (event: any) => {
    setFata(event.target.value)
  }

  const handleLevelChange = (event: any) => {
    setLevels(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue)
      onNameChange({fata: fata, name: inputValue, levels: levels})
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [inputValue, fata, levels, 500])

  return (
    <div className='rangeItemShelf'>
      <input
        className='rangeItemShelf__cell rangeItemShelf__cell--input'
        type='text'
        placeholder='Introdu numele zonei'
        value={inputValue}
        onChange={handleInputChange}
      />

      <input
        id='levels'
        type='number'
        className='rangeItemShelf__cell rangeItemShelf__cell--input'
        placeholder='Nr. nivele'
        value={levels}
        onChange={handleLevelChange}
      />

      <select
        id='fata'
        value={fata}
        onChange={handleFataChange}
        className='rangeItemShelf__cell rangeItemShelf__cell--input'
      >
        <option value='1'>sus</option>
        <option value='2'>jos</option>
        <option value='3'>stanga</option>
        <option value='4'>dreapta</option>
      </select>

      <span className='rangeItemShelf__cell rangeItemShelf__cell--text'>
        De la ({range.start.row}, {range.start.col}) până la ({rangeEndRow}, {rangeEndCol})
      </span>
      <div className='rangeItemShelf__cell'>
        <a onClick={onDelete} className='btn btn-icon btn-danger btn-sm button-left-margin'>
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
    </div>
  )
}

export default RangeItemShelf
