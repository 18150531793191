import React, {useState} from 'react'
import RangeItem from './components/RangeItem'
import axiosConf from '../../app/modules/auth/core/Requests/_axiosInstance'
import {CREATE_DEPOSITS} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../app/modules/auth'
import './style/CreateDepositSecond.scss'

export const API_URL = process.env.REACT_APP_API_URL

interface Point {
  row: number
  col: number
}

interface Range {
  start: Point
  end: Point
  name: string
}

interface DepositPoint {
  x: number
  y: number
}

const CreateDepositSecond = () => {
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [address, setAddress] = useState('')
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [scale, setScale] = useState(1)
  const [depositStartPoint, setDepositStartPoint] = useState<DepositPoint>({x: 0, y: 0})

  const [startPoint, setStartPoint] = useState<Point | null>(null)
  const [endPoint, setEndPoint] = useState<Point | null>(null)
  const [ranges, setRanges] = useState<Range[]>([])

  const [depositData, setDepositData] = useState<any>(null)
  const [depositCreated, setDepositCreated] = useState(false)
  const [areaCreated, setAreaCreated] = useState(false)

  const updateRangeName = (index: number, newName: string) => {
    const newRanges = [...ranges]
    newRanges[index] = {...newRanges[index], name: newName}
    setRanges(newRanges)
  }

  const handleWidthChange = (e: any) => {
    setWidth(Number(e.target.value))
  }

  const handleHeightChange = (e: any) => {
    setHeight(Number(e.target.value))
  }
  const handleScaleChange = (e: any) => {
    setScale(Number(e.target.value))
  }

  // handleChangeInputByName is used to update the state of the input fields by name of input
  const handleChangeInputByName = (e: any) => {
    const inputName = e.target.name
    if (inputName === 'name') {
      setName(e.target.value)
    } else if (inputName === 'address') {
      setAddress(e.target.value)
    }
  }

  const handleStartPointChange = (e: any) => {
    // verify if the inpus is x or y and update the state accordingly
    const inputId = e.target.id
    if (inputId === 'startPointXInput') {
      setDepositStartPoint({...depositStartPoint, x: Number(e.target.value)})
    } else if (inputId === 'StartPointYInput') {
      setDepositStartPoint({...depositStartPoint, y: Number(e.target.value)})
    }
  }

  const handleCellClick = (row: number, col: number) => {
    let rowScale = row * scale
    let colScale = col * scale
    if (!startPoint) {
      setStartPoint({row: rowScale, col: colScale})
    } else if (!endPoint) {
      // Generate a default name for the range, e.g., "Range 1", "Range 2", etc.
      const defaultName = `Zona ${ranges.length + 1}`
      setEndPoint({row: rowScale + scale, col: colScale + scale})
      setRanges([
        ...ranges,
        {
          start: startPoint,
          end: {row: rowScale + scale, col: colScale + scale},
          name: defaultName,
        },
      ])
      setStartPoint(null)
      setEndPoint(null)
    }
  }

  const isCellInRange = (row: number, col: number) => {
    return ranges.some((range) => {
      const rowInRange = row >= range.start.row && row < range.end.row
      const colInRange = col >= range.start.col && col < range.end.col
      return rowInRange && colInRange
    })
  }

  // get cell range index
  const getCellRangeIndex = (row: number, col: number) => {
    return ranges.findIndex((range) => {
      const rowInRange = row >= range.start.row && row < range.end.row
      const colInRange = col >= range.start.col && col < range.end.col
      return rowInRange && colInRange
    })
  }

  const isStartPoint = (row: number, col: number) => {
    return startPoint && !endPoint && startPoint.row === row && startPoint.col === col
  }

  const deleteRange = (index: number) => {
    const newRanges = [...ranges]
    newRanges.splice(index, 1)
    setRanges(newRanges)
  }

  const colorsArray = [
    '#7209b7',
    '#3a0ca3',
    '#023047',
    '#ffb703',
    '#283618',
    '#264653',
    '#2a9d8f',
    '#e9c46a',
    '#f4a261',
    '#e76f51',
  ]

  const renderTable = () => {
    let table = []
    const columnWidth = width > 0 ? `${100 / width}%` : 'auto'

    let key = 0
    let scaleVal = scale > 0 ? scale : 1
    let scaleWidth = width / scaleVal
    let scaleHeight = height / scaleVal
    for (let i = 0; i < scaleWidth; i++) {
      let row = []
      for (let j = 0; j < scaleHeight; j++) {
        key++

        const inRange = isCellInRange(i * scaleVal, j * scaleVal)
        const isStart = isStartPoint(i * scaleVal, j * scaleVal)
        const indexRange = getCellRangeIndex(i * scaleVal, j * scaleVal)

        row.push(
          <td
            key={`cell-${i}-${j}`}
            className=' font-size-xs p-0 text-center'
            onClick={() => handleCellClick(i, j)}
            style={{
              backgroundColor: inRange
                ? colorsArray[indexRange]
                : isStart
                ? 'lightblue'
                : 'transparent',
              width: columnWidth,
              minHeight: `100 - ${scaleHeight}vw`,
              fontSize: '9px',
            }}
          >{`F(${i * scaleVal}-${j * scaleVal})-S(${i * scaleVal + scaleVal}-${
            j * scaleVal + scaleVal
          })`}</td>
        )
      }
      table.push(
        <tr key={`row-${i}`} className=''>
          {row}
        </tr>
      )
    }

    return <tbody>{table}</tbody>
  }

  const grid = () => {
    let scaleVal = scale > 0 ? scale : 1
    let scaleHeight = height / scaleVal
    let scaleWidth = width / scaleVal

    // Calculate the square size based on the viewport width and the number of columns
    let squareSize = Math.floor((window.innerWidth - (window.innerWidth * 25) / 100) / scaleHeight)
    squareSize = squareSize > 200 ? squareSize / 4 : squareSize
    squareSize = squareSize > 50 ? squareSize / 1.5 : squareSize
    // Calculate the number of rows needed
    // const rows = Math.ceil(totalCells / columns);

    // Generate the grid's cells
    const cells = []

    // let i = areaData.coordinates.first.x / scaleVal;
    //       i < areaData.coordinates.second.x / scaleVal;
    for (let i = 0; i < scaleHeight; i++) {
      cells.push(i)
    }

    // Generate grid squares
    const squares = []
    for (let y = 0; y < scaleWidth; y++) {
      squares.push(cells)
    }

    return (
      <div>
        {squares.map((row, indexR) => {
          return (
            <div
              className='grid-container'
              key={indexR}
              style={{maxWidth: `${scaleHeight * squareSize}px`}}
            >
              {row.map((cell, indexC) => {
                const indecRCoordonate = indexR
                const indecCCoordonate = indexC

                const inRange = isCellInRange(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )
                const isStart = isStartPoint(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )
                const indexRange = getCellRangeIndex(
                  indecRCoordonate * scaleVal,
                  indecCCoordonate * scaleVal
                )

                return (
                  <div
                    key={indexC}
                    className='grid-cell'
                    onClick={() => handleCellClick(indecRCoordonate, indecCCoordonate)}
                    style={{
                      backgroundColor: inRange
                        ? colorsArray[indexRange]
                        : isStart
                        ? 'lightblue'
                        : 'transparent',
                      width: `${squareSize}px`,
                      height: `${squareSize}px`,
                      fontSize: `10px`,
                      color: inRange ? 'black' : 'white',
                    }}
                  >
                    {/*{indecRCoordonate + indecCCoordonate * scaleHeight + 1}*/}
                    {/*{`R-F(${indecRCoordonate * scaleVal}-${indecCCoordonate * scaleVal})-S(${indecRCoordonate * scaleVal + scaleVal}-${indecCCoordonate * scaleVal + scaleVal})`}*/}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const {currentUser} = useAuth()

  const createDeposit = async () => {
    let depositData = {
      name,
      address,
      width,
      height,
      scale,
      startPoint: depositStartPoint,
      ...(currentUser.role === 'super_admin' && {group: localStorage.getItem('group')}),
    }
    await axiosConf
      .post(CREATE_DEPOSITS, depositData)
      .then((res) => {
        setDepositData(res.data)
        setDepositCreated(true)
        alert('Depozit creat cu succes!')
      })
      .catch((err) => {
        console.log('CREATE_DEPOSITS err', err)
        alert('Eroare la crearea depozitului!')
      })
  }

  const createAreas = () => {
    const areas = {
      ...ranges,
      width,
      height,
      depositStartPoint,
    }
    ranges.forEach(async (range, index) => {
      let rangeEndRow = range.end.row
      let rangeEndCol = range.end.col
      let areaData = {
        name: range.name,
        type: 'area',
        deposit: depositData.data.deposit._id, // deposit ID
        coordinates: {
          first: {
            x: range.start.row,
            y: range.start.col,
          },
          second: {
            x: rangeEndRow > width + scale ? rangeEndRow - scale : rangeEndRow,
            y: rangeEndRow > height + scale ? rangeEndCol - scale : rangeEndCol,
          },
        },
      }
      try {
        const response = await axiosConf.post(`${API_URL}/locations`, areaData)
        if (response.status === 201) {
          alert('Zone create cu succes!')
        }
      } catch (err: any) {
        console.log('CREATE_DEPOSITS err', err)
        alert(
          'Eroare la crearea zonei ' + range.name + '! Zone intersectate sau dimensiuni depasite!'
        )
      }
    })

    setTimeout(() => {
      navigate('/edit-deposit/' + depositData.data.deposit._id)
    }, 2000)

    setAreaCreated(true)
  }

  return (
    <div className='deposit'>
      {/* NAME input start */}
      <div className='deposit__title'>
        <h1>Creare depozit</h1>
      </div>
      <div className='deposit__separator' />
      <div className='deposit__section--title'>
        <h2>Informații generale</h2>
      </div>
      <div className='deposit__section'>
        <div className='mb-3'>
          <label htmlFor='name' className='form-label deposit__input--label'>
            Nume
          </label>
          <input
            type='text'
            className='form-control deposit__input'
            id='name'
            value={name}
            name='name'
            onChange={handleChangeInputByName}
          />
        </div>
        <div className='mb-3'>
          <label htmlFor='address' className='form-label deposit__input--label'>
            Adresa
          </label>
          <input
            type='text'
            className='form-control deposit__input'
            id='address'
            value={address}
            name='address'
            onChange={handleChangeInputByName}
          />
        </div>
        <div className='mb-3 deposit__flex'>
          <div className='deposit__flex--section'>
            <label htmlFor='heightInput' className='form-label deposit__input--label'>
              Lungime (m) - axa X
            </label>
            <input
              type='number'
              className='form-control deposit__input'
              id='heightInput'
              value={height}
              onChange={handleHeightChange}
            />
          </div>
          <div className='deposit__flex--section'>
            <label htmlFor='widthInput' className='form-label deposit__input--label'>
              Latime (m) - axa Y
            </label>
            <input
              type='number'
              className='form-control deposit__input'
              id='widthInput'
              value={width}
              onChange={handleWidthChange}
            />
          </div>
        </div>
        <div className='mb-3'>
          <label htmlFor='scaleInput' className='form-label deposit__input--label'>
            Scalare pentru reprezentare grafica
          </label>
          <input
            type='number'
            className='form-control deposit__input'
            id='scaleInput'
            value={scale}
            onChange={handleScaleChange}
          />
        </div>
        <div className='mb-3 deposit__flex'>
          <div className='deposit__flex--section'>
            <label htmlFor='startPointXInput' className='form-label deposit__input--label'>
              Punct de start - axa X
            </label>
            <input
              type='number'
              className='form-control deposit__input'
              id='startPointXInput'
              value={depositStartPoint.x}
              onChange={handleStartPointChange}
            />
          </div>
          <div className='deposit__flex--section'>
            <label htmlFor='StartPointYInput' className='form-label deposit__input--label'>
              Punct de start - axa Y
            </label>
            <input
              type='number'
              className='form-control deposit__input'
              id='StartPointYInput'
              value={depositStartPoint.y}
              onChange={handleStartPointChange}
            />
          </div>
        </div>
      </div>
      {height > 0 && width > 0 && (
        <div>
          <div className='deposit__section--title'>
            <h2>
              Aspect depozit{' '}
              <span>(Zonele se selectează de sus în jos, de la stânga la dreapta)</span>
            </h2>
          </div>
          <div className='deposit__section'>
            <div className='deposit__layout'>
              <span className='deposit__coordinate deposit__coordinate--start'>0</span>
              <span className='deposit__coordinate deposit__coordinate--height'>{height}</span>
              <span className='deposit__coordinate deposit__coordinate--width'>{width}</span>
              {grid()}
            </div>
          </div>
        </div>
      )}
      {ranges.length > 0 && (
        <div>
          <div className='deposit__section--title'>
            <h2>Zonele depozitului</h2>
          </div>
          <div className='deposit__section deposit__table'>
            <div className='deposit__table--header'>
              <p className='deposit__table--header-cell'>Nume</p>
              <p className='deposit__table--header-cell'>Arie (rand/coloana)</p>
              <p className='deposit__table--header-cell'>Acțiune</p>
            </div>
            {ranges.map((range, index) => (
              <RangeItem
                key={index}
                range={range}
                scale={scale}
                width={width}
                height={height}
                onDelete={() => deleteRange(index)}
                onNameChange={(newName) => updateRangeName(index, newName)}
              />
            ))}
          </div>
        </div>
      )}
      <div className='deposit__create'>
        {!depositCreated && (
          <div>
            <p className='warning-message'>
              Atenție! Dupa ce ați creat depozitul, nu mai puteți modifica datele acestuia!
            </p>
            <button className='deposit__action' onClick={createDeposit}>
              Crează depozit
            </button>
          </div>
        )}
        {depositCreated && (
          <button className='deposit__action' onClick={createAreas}>
            Salvare zone
          </button>
        )}
      </div>
    </div>
  )
}

export default CreateDepositSecond
