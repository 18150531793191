import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {getAllOrders} from '../../app/modules/auth/core/Requests/_requestsOrders'
import {useAuth} from '../../app/modules/auth'
import {KTSVG} from '../../_metronic/helpers'
import PaginationControls from '../components/PaginationControls'
import './style/OrderList.scss'

interface Order {
  _id: string
  name: string
  date: string
  total: string
  payment: string
  ext_id: string
  createdAt: string
  total_paid_tax_incl: number
  customer_name: string
  group?: {
    id: string
    name: string
  }
}

const OrderList = () => {
  const {currentUser} = useAuth()
  const [allOrders, setAllOrders] = useState<Order[]>([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)

  const navigate = useNavigate()

  useEffect(() => {
    getAllOrders({page, limit, currentUser}).then((res) => {
      console.log('res in ORDERS.tsx', res)
      if (res && res.data && res.data.orders.length !== 0) {
        setTotalPages(res.data.pages)
        setAllOrders(res.data.orders)
      }
    })
  }, [page, limit]) // Re-run the effect if these values change

  function showConvertedDate(dateParam: string) {
    // Create a new Date object with the given parameter
    const date = new Date(dateParam)

    // Get the UTC date and time in the desired format
    const formattedDate = date.toLocaleDateString('ro-RO', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    const formattedTime = date.toLocaleTimeString('ro-RO', {
      timeZone: 'UTC',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    // Return the formatted date and time as a string
    return `${formattedDate} Ora: ${formattedTime}`
  }

  return (
    <>
      <div className='orders'>
        <div className='orders__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>Comenzi</h1>
        </div>
        <div className='orders__separator' />
        <div className='orders__container'>
          <div className='orders__subtitle'>
            <h2>Listă comenzi</h2>
          </div>
          <div className='orders__table'>
            {allOrders?.length ? (
              <>
                <div className='orders__details--row orders__table--row'>
                  <p className='orders__details--row-header'>ID</p>
                  <p className='orders__details--row-header'>Nume client</p>
                  <p className='orders__details--row-header'>Dată</p>
                  <p className='orders__details--row-header'>Magazin</p>
                  <p className='orders__details--row-header'>Total</p>
                  <p className='orders__details--row-header'>Metoda de plată</p>
                  <p className='orders__details--row-header'>Acțiuni</p>
                </div>
                {allOrders?.map((order, id) => {
                  return (
                    <div
                      key={order._id}
                      onClick={() => navigate(`/view-order/${order._id}`)}
                      className='orders__details--row orders__details--order orders__table--row'
                    >
                      <p className='orders__details--row-info'>{order.ext_id}</p>
                      <p className='orders__details--row-info'>{order.customer_name}</p>
                      <p className='orders__details--row-info'>
                        {showConvertedDate(order.createdAt)}
                      </p>
                      <p className='orders__details--row-info'>{order.group.name}</p>
                      <p className='orders__details--row-info'>{order.total_paid_tax_incl}</p>
                      <p className='orders__details--row-info'>{order.payment}</p>
                      <div className='orders__details--row-info'>
                        <div className='btn btn-icon btn-light btn-sm'>
                          <KTSVG
                            path='/media/icons/duotune/arrows/eye.svg'
                            className='svg-icon-3'
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
            )}
          </div>
          <PaginationControls
            currentPage={page}
            totalPages={totalPages}
            limit={limit}
            setCurrentPage={(number: any) => setPage(number)}
          />
        </div>
      </div>
    </>
  )
}

export default OrderList
