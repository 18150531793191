import React, {FC} from 'react'
import {getGroups} from '../../app/modules/auth/core/Requests/_requestsGroups'

import {debounce} from 'lodash'
import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import PaginationControls from '../components/PaginationControls'
import {API_URL} from '../../app/modules/auth/core/Requests/_requestsGroups'
import {useAuth} from '../../app/modules/auth'
import {getGroup, deleteGroup} from '../../app/modules/auth/core/Requests/_requestsGroups'
import './style/GroupsList.scss'

type Props = {
  className: string
}

interface Group {
  _id: string
  name: string
  store_domain: string
  store_ip: string
  status: string
  public: boolean
  createdAt: string
  deletedAt?: string
  logo?: {
    fileName: string
    filePath: string
  }
}

interface DeleteInfo {
  name: string
  id: string
}

const MyButton: any = Button

const GroupsList: FC<Props> = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const [showModal, setShowModal] = useState(false)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [allGroups, setAllGroups] = useState<Group[]>([])
  const [groupToDelete, setGroupToDelete] = useState<DeleteInfo>()

  const handleGroupSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value

    getGroups({search: searchTerm}).then((res) => {
      setAllGroups(res.data.groups)
    })
  }
  function handleGroupStatus(status: any) {
    getGroups({status: status}).then((res) => {
      setAllGroups(res.data.groups)
    })
  }
  const debouncedHandleGroupSearch = debounce(handleGroupSearch, 1000)
  const debouncedHandleGroupStatus = debounce(handleGroupStatus, 1000)

  function handleModalAppearance(groupName: string, groupId: string) {
    setGroupToDelete({name: groupName, id: groupId})
    setShowModal(true)
  }

  function activeGroup(id) {
    return localStorage.getItem('group') === id
  }

  const handleGroupChange = (groupId) => {
    localStorage.setItem('group', groupId)

    if (groupId) {
      getGroup(groupId).then((res) => {
        setCurrentUser((prevUser) => ({
          ...prevUser,
          group: {
            id: res.data.group.id,
            name: res.data.group.name,
          },
        }))
      })
    } else {
      setCurrentUser((prevUser) => ({
        ...prevUser,
        group: {
          id: '',
          name: '',
        },
      }))
    }
  }
  const handleDeleteGroup = (groupId) => {
    deleteGroup(groupId).then(() => {
      setGroupToDelete({name: '', id: ''})
      setShowModal(false)
      if (page > 1) {
        setPage(1)
      } else {
        getGroups({page, limit}).then((res) => {
          if (res && res.data && res.data.groups.length !== 0) {
            setTotalPages(res.data.pages)
            setAllGroups(res.data.groups)
          }
        })
      }
      console.log('success')
    })
  }

  useEffect(() => {
    getGroups({page, limit}).then((res) => {
      if (res && res.data && res.data.groups.length !== 0) {
        setTotalPages(res.data.pages)
        setAllGroups(res.data.groups)
      }
    })
  }, [page, limit])

  useEffect(() => {
    handleGroupChange(localStorage.getItem('group'))
  }, [])

  return (
    <>
      <div className='groups'>
        <div className='groups__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>
            Grupuri #{currentUser?.group?.name || 'Toate'}
          </h1>
          <div className='groups__title--buttons'>
            <div className='groups__title--search'>
              <Link
                to=''
                onClick={() => handleGroupChange('')}
                className='groups__title--search-button groups__title--search-button-secondary'
              >
                <KTSVG path='/media/icons/duotune/arrows/eye.svg' className='svg-icon-3' />
                <p>Vezi toate grupurile</p>
              </Link>
            </div>
            <div className='groups__title--search'>
              <Link to='/add-group' className='groups__title--search-button'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                <p>Grup nou</p>
              </Link>
            </div>
          </div>
        </div>
        <div className='groups__separator' />
        <div className='filters'>
          <h2 className='filters-title'>Filtre</h2>
          <div className='filter-container'>
            <div className='filter'>
              <input
                onChange={(event) => debouncedHandleGroupSearch(event)}
                type='text'
                placeholder='Caută grup'
              />
            </div>
          </div>
          <div className='filter-container'>
            <div className='filter'>
              <select
                defaultValue={'unselected'}
                name='groupStatus'
                onChange={(event) => debouncedHandleGroupStatus(event.target.value)}
              >
                <option value='unselected' disabled>
                  Selectează status
                </option>
                <option value='' className='p-5'>
                  Orice status
                </option>
                <option value='active' className='p-5'>
                  Activ
                </option>
                <option value='disabled' className='p-5'>
                  Dezactivat
                </option>
                <option value='deleted' className='p-5'>
                  Șters
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className='groups__separator' />
        <div className='groups__container'>
          <div className='groups__subtitle'>
            <h2>Listă grupuri</h2>
          </div>
          <div className='groups__table'>
            {allGroups.length ? (
              <>
                <div className='groups__details--row groups__table--row'>
                  <p className='groups__details--row-header'>Nume</p>
                  <p className='groups__details--row-header'>Magazin</p>
                  <p className='groups__details--row-header'>Status</p>
                  <p className='groups__details--row-header'>Acțiuni</p>
                </div>
                {allGroups.map((group: Group) => {
                  return (
                    <div
                      key={`group-${group.name}`}
                      className={`groups__details--row groups__details--group groups__table--row ${
                        activeGroup(group._id) && 'groups__details--group-active'
                      }`}
                      onClick={() =>
                        group.status !== 'deleted' ? handleGroupChange(group._id) : ''
                      }
                    >
                      <div className='groups__details--row-info groups__table--name'>
                        <img
                          className='groups__table--logo'
                          src={`${API_URL.replace('/v1', '')}${group.logo?.filePath}`}
                          alt={group.logo?.fileName}
                        />
                        <span>{group.name}</span>
                      </div>
                      <div className='groups__details--row-info'>
                        <p className='groups__table--description'>{group.store_domain}</p>
                        <p className='groups__table--description-secondary'>{group.store_ip}</p>
                      </div>
                      <div className='groups__details--row-info'>
                        <p
                          className={`groups__table--status groups__table--status-${group.status}`}
                        >
                          {group.status === 'active' && 'Activ'}
                          {group.status === 'disabled' && 'Dezactivat'}
                          {group.status === 'deleted' && 'Șters'}
                          {!(
                            group.status === 'active' ||
                            group.status === 'disabled' ||
                            group.status === 'deleted'
                          ) && ''}
                        </p>
                      </div>
                      {group.status !== 'deleted' ? (
                        <div className='groups__details--row-info'>
                          <Link
                            onClick={(e) => {
                              e.stopPropagation()
                              handleGroupChange(group._id)
                            }}
                            className='btn btn-icon btn-light btn-sm'
                            to={''}
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/eye.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                          <Link
                            onClick={(e) => e.stopPropagation()}
                            to={`/edit-group/${group._id}`}
                            className='btn btn-icon btn-light btn-sm button-left-margin'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/pencil.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                          <a
                            onClick={(e) => {
                              e.stopPropagation()
                              handleModalAppearance(group.name, group._id)
                            }}
                            className='btn btn-icon btn-danger btn-sm button-left-margin'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      ) : (
                        <div className='groups__details--row-info' />
                      )}
                    </div>
                  )
                })}
              </>
            ) : (
              <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
            )}
          </div>
          <PaginationControls
            currentPage={page}
            totalPages={totalPages}
            limit={limit}
            setCurrentPage={(number: any) => setPage(number)}
          />
        </div>
      </div>
      {showModal && (
        <DeleteModal
          active={true}
          groupName={groupToDelete.name}
          handleClose={() => {
            setShowModal(false)
            setGroupToDelete({name: '', id: ''})
          }}
          handleDeleteGroup={() => handleDeleteGroup(groupToDelete.id)}
        />
      )}
    </>
  )
}

const DeleteModal = ({active, groupName, handleClose, handleDeleteGroup}) => {
  return (
    <>
      <Modal show={active} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Șterge grup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>Sigur ștergi {groupName}?</div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <MyButton variant='secondary' onClick={handleClose}>
            Închide
          </MyButton>
          <MyButton variant='primary' onClick={handleDeleteGroup}>
            Șterge
          </MyButton>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {GroupsList}
