import React, {useCallback, useContext, useRef, useState} from 'react';
import {useFrame} from "@react-three/fiber";
import {Line, Text} from "@react-three/drei";
import {DepositDataContext} from "../PreviewDeposits";
import {Vector3} from "three";


interface Object3D{
  isMesh?: any,
  geometry?: any,
  material?: any,
  scale?: any,
}

// Levels
interface LevelProps {
  position: [number, number, number];
  dimensions: [number, number, number];
  color: string;
}


// Columns
interface ColumnProps {
  position: [number, number, number];
  dimensions: [number, number, number];
  color: string;
}


interface AreaCoordinates {
  first: { x: number; y: number };
  second: { x: number; y: number };
}

interface LevelData {
  coordinates: AreaCoordinates;
  dimensions: [number, number, number]; // width, height, depth
}

interface ColumnData {
  coordinates: AreaCoordinates;
  dimensions: [number, number, number]; // width, height, depth
}

interface ShelfData {
  coordinates: AreaCoordinates;
  name: string;
  levels: LevelData[];
  columns: ColumnData[];
}

interface AreaData {
  coordinates: AreaCoordinates;
  name: string;
  shelves: ShelfData[];
}

interface Area {
  name: string;
  shelves: { name: string }[];
}


interface ShelfProps {
  position: [number, number, number];
  dimensions: [number, number, number];
  text: string;
  color: string;
  levels: number;
  columns: number;
}


const Shelf: React.FC<ShelfProps> = ({position, dimensions, text, color}) => {
  const shelfRef = useRef<Object3D>()


  const {areas} = useContext(DepositDataContext)
  const {name: zoneName} = Array.isArray(areas) && areas.find((area: Area) =>
    area.shelves.some((shelf) => shelf.name === text)
  ) || {name: ''};



  let textPosition, rotationX, rotationY, rotationZ

  if (dimensions[0] < dimensions[2]) {
    textPosition = [
      0, // X: Center of the shelf
      dimensions[1] / 2 + 0.1, // Y: On top of the shelf
      0, // Z: Center of the shelf
    ]
    rotationX = -90 // Rotate 90 degrees along the X-axis
    rotationY = 0
    rotationZ = -90 // No rotation along the Z-axis
  } else {
    textPosition = [
      0, // X: Center of the shelf
      dimensions[1] / 2 + 0.1, // Y: On top of the shelf
      0, // Z: Center of the shelf
    ]
    rotationX = -90 // Rotate 90 degrees along the X-axis
    rotationY = 0
    rotationZ = 0 // Rotate 90 degrees along the Z-axis
  }

  // Convert degrees to radians
  const rotationXRadians = rotationX * (Math.PI / 180)
  const rotationYRadians = rotationY * (Math.PI / 180)
  const rotationZRadians = rotationZ * (Math.PI / 180)

  // Outlines

  const createLine = (start: any, end: any) => (
    <Line points={[start, end]} color='gray' lineWidth={4}/>
  )

  const createCubeOutline = () => {
    const w = dimensions[0] / 2
    const h = dimensions[1] / 2
    const d = dimensions[2] / 2

    const topFrontLeft = [-w, h, d]
    const topFrontRight = [w, h, d]
    const topBackLeft = [-w, h, -d]
    const topBackRight = [w, h, -d]
    const bottomFrontLeft = [-w, -h, d]
    const bottomFrontRight = [w, -h, d]
    const bottomBackLeft = [-w, -h, -d]
    const bottomBackRight = [w, -h, -d]

    return (
      <>
        {createLine(topFrontLeft, topFrontRight)}
        {createLine(topFrontRight, topBackRight)}
        {createLine(topBackRight, topBackLeft)}
        {createLine(topBackLeft, topFrontLeft)}

        {createLine(bottomFrontLeft, bottomFrontRight)}
        {createLine(bottomFrontRight, bottomBackRight)}
        {createLine(bottomBackRight, bottomBackLeft)}
        {createLine(bottomBackLeft, bottomFrontLeft)}

        {createLine(topFrontLeft, bottomFrontLeft)}
        {createLine(topFrontRight, bottomFrontRight)}
        {createLine(topBackRight, bottomBackRight)}
        {createLine(topBackLeft, bottomBackLeft)}
      </>
    )
  }

  return (
    <group
      ref={shelfRef as any}
      position={position}
    >
      <boxGeometry args={dimensions}/>
      <meshBasicMaterial color= 'gray' transparent={true} opacity={0}/>
      {createCubeOutline()}
      <Text
        position={new Vector3().fromArray(textPosition)}
        rotation={[rotationXRadians, rotationYRadians, rotationZRadians]}
        color='black'
        fontSize={3}
        maxWidth={200}
        lineHeight={3}
        letterSpacing={0.02}
        textAlign='center'
        anchorX='center'
        anchorY='middle'
      >
        {text}
      </Text>
    </group>
  )
}



export default Shelf;
