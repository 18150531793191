import React, {useEffect, useState, createContext} from 'react'
import {Canvas} from '@react-three/fiber'
import {PerspectiveCamera, Plane, MapControls, Grid} from '@react-three/drei'
import axios from 'axios'
// import {useControls} from 'leva'
import {useParams} from 'react-router'
import ModalDeposits from './ModalDeposits'
import Shelf from './components/Shelf'
import Level from './components/Level'
import ExtrudedPlane from './components/ExtrudedPlane'
import Column from './components/Column'
// import CameraSetup from './components/CameraSetup'
import Lights from './components/Lights';
import StartPoint from './components/StartPoint'
import Material from './components/Materials'


interface Object3D {
  isMesh?: any
  geometry?: any
  material?: any
  scale?: any
}

// Levels
interface LevelProps {
  position: [number, number, number]
  dimensions: [number, number, number]
  color: string
  rotation?: [number, number, number] // Optional rotation property
}

// Columns
interface ColumnProps {
  position: [number, number, number]
  dimensions: [number, number, number]
  color: string
  name: string
  levels?: number // Optional levels property to stack levels on top of the column
  id: string
}

interface AreaCoordinates {
  first: {
    x: number
    y: number
  }
  second: {
    x: number
    y: number
  }
}

interface Level {
  _id: string
  name: string
  coordinates: AreaCoordinates
  columns: Column[]
}

interface Column {
  _id: string
  name: string
  coordinates: AreaCoordinates
}

interface ShelfData {
  _id: string
  name: string
  coordinates: AreaCoordinates
  levels: Level[] // Add this line to include levels inside shelves
}

interface AreaData {
  coordinates: AreaCoordinates
  name: string
  shelves: ShelfData[]
}

interface Area {
  name: string
  shelves: {
    coordinates: AreaCoordinates;
    name: string
    levels: Level[]
  }[]
}

interface DepositData {
  name: string;
  width: number;
  height: number;
  areas: AreaData[];
  address?: string; // Since address might be optional, use a question mark
  startPoint?: {
    x: number;
    y: number;
  };
}

export const DepositDataContext = createContext<DepositData>({} as DepositData)

const PreviewDeposits: React.FC = () => {
  const [depositData, setDepositData] = useState<DepositData | null>(null)
  const [tree, setTree] = useState(true)
  const {id} = useParams()
  console.log(id)
  const [isModalOpen, setModalOpen] = useState(false)

  const {id: depositId} = useParams();
  console.log(depositId);

  const concreteMaterial = Material();
  console.log(concreteMaterial);

  const [levelProps, setLevelProps] = useState<LevelProps[]>([])

  const [columnProps, setColumnProps] = useState<ColumnProps[]>([])


  const [selectedColumn, setSelectedColumn] = useState({
    id: '',
    name: '',
    products: []
  });

  // Function to handle column click
  const handleColumnClick = (id: string, products: any, name: string) => {
    setSelectedColumn({
      id,
      name,
      products,
    })
    setModalOpen(true)
  }


  const handleCloseModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`https://api.incom.ai/v1/deposits/${id}?tree=${tree}`)
        const fetchedData = result.data.data
        const depositData = result.data.data
        setDepositData(fetchedData)
        // console.log(result.data)

        const colors = ['green', 'royalblue', 'grey', 'brown', 'magenta']

        // Function to get a random color
        const getRandomColor = () => {
          return colors[Math.floor(Math.random() * colors.length)]
        }

        // Initialize the props arrays
        const newLevelProps: LevelProps[] = []
        const newColumnProps: ColumnProps[] = []

        // console.log('Fetched Data:', fetchedData);

        // Loop through the areas, shelves, levels and columns to calculate their dimensions and positions
        fetchedData.areas.forEach((area: Area) => {
          area.shelves.forEach((shelf) => {
            // console.log('Shelf:', shelf);
            const levelWidth = shelf.coordinates.second.x - shelf.coordinates.first.x;
            const levelDepth = shelf.coordinates.second.y - shelf.coordinates.first.y;
            const levelX = shelf.coordinates.first.x + levelWidth / 2;

            shelf.levels.forEach((level, levelIndex) => {
              const levelHeight = 0.1; // Fixed height for all levels
              const levelY = (levelHeight + 3) * levelIndex;
              const levelZ = fetchedData.height - (shelf.coordinates.first.y + levelDepth / 2);

              // @ts-ignore
              newLevelProps.push({
                position: [levelX, levelY, levelZ],
                dimensions: [levelWidth, levelHeight, levelDepth],
                color: 'orange',
              });

              level.columns.forEach((column) => {
                const columnWidth = column.coordinates.second.x - column.coordinates.first.x;
                const columnDepth = column.coordinates.second.y - column.coordinates.first.y;
                const columnHeight = 2.8; // Fixed height for all columns
                const columnX = column.coordinates.first.x + columnWidth / 2;
                const columnY = levelY + levelHeight + columnHeight / 2;
                const columnZ = fetchedData.height - (column.coordinates.first.y + columnDepth / 2);

                // @ts-ignore
                newColumnProps.push({
                  position: [columnX, columnY, columnZ],
                  dimensions: [columnWidth, columnHeight, columnDepth],
                  color: getRandomColor(),
                  name: column.name,
                  id: column._id,
                });
              });
            });
          });
        });

        // console.log('New Level Props:', newLevelProps);
        // console.log('New Column Props:', newColumnProps);
        setLevelProps(newLevelProps);
        setColumnProps(newColumnProps);

      } catch (error) {
        console.error('Error fetching deposit data:', error);
      }
    };

    fetchData();
  }, [id]);

  // // gridSize, cellSize, cellColor leva controller functional grid controller
  // const {gridSize, cellSize, cellColor} = useControls({
  //   gridSize: {
  //     value: 100,
  //     min: 10,
  //     max: 200,
  //     step: 10,
  //   },
  //   cellSize: {
  //     value: 1,
  //     min: 0.1,
  //     max: 10,
  //     step: 0.1,
  //   },
  //   cellColor: {
  //     value: '#303035',
  //     label: 'Cell Color',
  //   },
  // })
  // // Grid contrroller end
  if (!depositData) return <div>Loading...</div>;

  const zones =
    tree && depositData.areas
      ? depositData.areas.map((area) => {
        const width = area.coordinates.second.x - area.coordinates.first.x
        const height = area.coordinates.second.y - area.coordinates.first.y
        const position = [
          area.coordinates.first.x + width / 2,
          0.1,
          depositData.height - (area.coordinates.first.y + height / 2),
        ] as [number, number, number]
        const color = 'royalblue'
        return {
          position,
          dimensions: [width, height] as [number, number],
          color,
          name: area.name,
          shelves: area.shelves.map((shelf) => {
            const shelfWidth = shelf.coordinates.second.x - shelf.coordinates.first.x
            // variable height for all shelves for each level height of 3 on y axis, level * 3 if there is now level
            // then 3
            const shelfHeight = shelf.levels.length * 3
            const shelfDepth = shelf.coordinates.second.y - shelf.coordinates.first.y
            const shelfX = shelf.coordinates.first.x + shelfWidth / 2
            const shelfY = shelfHeight / 2 + 0.1
            const shelfZ = depositData.height - (shelf.coordinates.first.y + shelfDepth / 2)
            return {
              position: [shelfX, shelfY, shelfZ] as [number, number, number],
              dimensions: [shelfWidth, shelfHeight, shelfDepth] as [number, number, number],
              text: shelf.name,
              color: 'gray',
            }
          }),
        }
      })
      : []

  // Start point component
  const startPointComponent = depositData.startPoint ? (
    <StartPoint
      position={[
        depositData.startPoint.x,
        0.5,
        depositData.height - depositData.startPoint.y,
      ]}
      label='Start Point'
      color='red'
    />
  ) : null;


  return (
    <DepositDataContext.Provider value={depositData}>
      <div
        style={{
          height: '100%',
          width: '100%',
          background: '#303035',
          position: 'absolute',
          // fullfill the screen centered
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Canvas shadows>
          <Lights/>

          <PerspectiveCamera
            makeDefault
            position={[depositData.width * 1.5, 90, depositData.height * 1.5]}
            rotation={[-Math.PI / 2, 2, 0]}
          />
          <MapControls/>
          {/* <CameraSetup /> */}

          <Plane
            receiveShadow
            position={[depositData.width / 2, -.6, depositData.height / 2]}
            args={[depositData.width, depositData.height]}
            rotation={[-Math.PI / 2, 0, 0]}
          >
            {concreteMaterial && <primitive object={concreteMaterial} attach="material"/>}
          </Plane>
          {zones.map((zone, index) => (
            <ExtrudedPlane
              key={index}
              position={zone.position}
              args={zone.dimensions}
              text={zone.name}
              rotation={[-Math.PI / 2, 0, 0]}
            />
          ))}
          {zones.flatMap((zone, zoneIndex) =>
            zone.shelves.map((shelf, shelfIndex) => {
              const randNr = Math.floor(Math.random() * 200)
              const levels = 0
              const columns = 0 /* your logic to get columns for shelf */
              return (
                <Shelf
                  key={`${zoneIndex}+${shelfIndex}+${randNr}`}
                  position={shelf.position}
                  dimensions={shelf.dimensions}
                  text={shelf.text}
                  color={shelf.color}
                  levels={levels}
                  columns={columns}
                />
              )
            })
          )}
          {levelProps.map((level, index) => (
            <Level key={index} {...level} />
          ))}
          {columnProps.map((column) => (
            <Column
              key={column.id}
              {...column}
              //@ts-ignore
              depositId={depositId}
              onColumnClick={handleColumnClick}
            />
          ))}
          {startPointComponent}
        </Canvas>
      </div>
      {/* <div className="deposit-info-box"
       style={{
        color:'black' }}>
           <b>{depositData.name}</b>
      {depositData.address && <p> <b>{depositData.address}</b></p>}
      </div> */}
      {/*  <ModalDeposits isOpen={isModalOpen} onClose={() => setModalOpen(false)}>*/}
      {/*  <div>*/}
      {/*    <h2> {selectedColumn.name}</h2>*/}
      {/*    <p>Numar de produse: {selectedColumn.products.length}</p>*/}
      {/*    <ul>*/}
      {/*      {selectedColumn.products.map((product: any) => (*/}
      {/*        <li key={product._id}>{product.name}</li>*/}
      {/*      ))}*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</ModalDeposits>*/}
    </DepositDataContext.Provider>
  )
}

export default PreviewDeposits
