import React, {useState, useEffect} from 'react'
import EntityDeposit from './Entities/EntityDeposit'
import EntityGroup from './Entities/EntityGroup'
import EntityOrder from './Entities/EntityOrder'
import EntityProduct from './Entities/EntityProduct'
import EntityReception from './Entities/EntityReception'
import EntityUser from './Entities/EntityUser'
import './LogsModal.scss'

import {getDepositById} from '../../../app/modules/auth/core/Requests/_requestsDeposits'
import {getOrderById} from '../../../app/modules/auth/core/Requests/_requestsOrders'
import {getPickingOrderById} from '../../../app/modules/auth/core/Requests/_requestsOrders'
import {getProductById} from '../../../app/modules/auth/core/Requests/_requestsProduct'
import {getReceptionById} from '../../../app/modules/auth/core/Requests/_requestsReceptions'
import {getUserById} from '../../../app/modules/auth/core/_requests_user'
import {getGroup} from '../../../app/modules/auth/core/Requests/_requestsGroups'

// import { getStockAvailable } from '../../../app/modules/auth/core/Requests/_requestsClients'
// import { getLocationById } from '../../../app/modules/auth/core/Requests/_requestsDeposits'

const LogsModal = ({isOpen, onClose, log}) => {
  if (!isOpen || !log) {
    return null
  }

  return (
    <div className='modal__overlay'>
      <div className='modal__content'>
        <h3 className='modal__content--title'>Detalii</h3>
        <div className='modal__container'>
          <div className='modal__container--item'>
            <h4 className='modal__container--item-header'>Utilizator</h4>
            <p className='modal__container--item-description'>{log.user ? log.user.name : 'N/A'}</p>
          </div>
          <div className='modal__container--item'>
            <h4 className='modal__container--item-header'>Acțiune</h4>
            <p className='modal__container--item-description'>{log.action}</p>
          </div>
          <div className='modal__container--item'>
            <h4 className='modal__container--item-header'>Mesaj</h4>
            <p className='modal__container--item-description'>{log.message}</p>
          </div>
        </div>
        <div className='modal__container'>
          {log?.entity === 'Deposit' && <EntityDeposit entity={log?.affectedId} />}
          {log?.entity === 'Group' && <EntityGroup entity={log?.affectedId} />}
          {log?.entity === 'Order' && <EntityOrder entity={log?.affectedId} />}
          {log?.entity === 'Product' && <EntityProduct entity={log?.affectedId} />}
          {log?.entity === 'Reception' && <EntityReception entity={log?.affectedId} />}
          {log?.entity === 'User' && <EntityUser entity={log?.affectedId} />}
        </div>
        <button className='modal__close' onClick={onClose}>
          Închide
        </button>
      </div>
    </div>
  )
}

export {LogsModal}
