/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {getAllCustomers, getGroups} from '../../app/modules/auth/core/Requests/_requestsClients'
// import { Products } from './Products'
import {KTSVG} from '../../_metronic/helpers'
import PaginationControls from '../components/PaginationControls'
import {getAllUsers} from '../../app/modules/auth/core/_requests_user'
import {useAuth} from '../../app/modules/auth'
import './style/ClientList.scss'

interface Client {
  map(arg0: (order: any, id: string) => JSX.Element): import('react').ReactNode
  email: string
  ext_id: number
  firstname: string
  lastname: string
  id: string
  group?: {
    id: string
    name: string
  }
}
// interface CurrentUser {
//   role:
// }

// Define the hook as a function
const useClients = (initialPage = 1, initialLimit = 30) => {
  // State for logs, current page, limit, and total pages
  const [clients, setClients] = useState<Client[]>([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [email, setEmail] = useState()
  const [group, setGroup] = useState()
  const [availableGroups, setAvailableGroups] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const {currentUser} = useAuth()

  const fetchGroups = async () => {
    let retrievedGroups = await getGroups()
    console.log('groups CALLED', retrievedGroups?.data?.groups)
    setAvailableGroups(retrievedGroups?.data?.groups)
  }
  useEffect(() => {
    if (currentUser.role === 'super_admin') {
      fetchGroups()
    }
  }, [currentUser.role])

  const debounce = (func, delay) => {
    let timeoutId

    return function () {
      const context = this
      const args = arguments

      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func.apply(context, args)
      }, delay)
    }
  }

  const handleEmailChange = debounce((event) => {
    setEmail(event.target.value)
    console.log(`handleEmailChange - ${event.target.value}`)
  }, 500)
  const handleShopChange = debounce((event) => {
    setGroup(event.target.value)
    console.log(`handleShopChange - ${event.target.value}`)
  }, 500)

  // Effect to fetch logs when the page or limit changes
  useEffect(() => {
    getAllCustomers({page, limit, email, group, currentUser}).then((res) => {
      console.log('res in UsersList.tsx', res)
      if (res && res.data && res.data.customers.length !== 0) {
        setTotalPages(res.data.pages)
        setClients(res.data.customers)
      }
    })
  }, [page, limit, email, group]) // Re-run the effect if these values change

  // Return everything needed to manage logs in a component
  return {
    clients,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    handleEmailChange,
    handleShopChange,
    currentUser,
    availableGroups,
  }
}

const ClientList = () => {
  const {
    clients,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    handleEmailChange,
    handleShopChange,
    currentUser,
    availableGroups,
  } = useClients()
  console.log(`CUURENT UUUUSER = ${currentUser.role}`)

  return (
    <>
      <div className='clients'>
        <div className='clients__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>Clienți</h1>
        </div>
        <div className='clients__separator' />
        <div className='filters'>
          <h2 className='filters-title'>Filtre</h2>
          <div className='filter-container'>
            <div className='filter'>
              <input type='text' placeholder='Caută după email' onChange={handleEmailChange} />
            </div>
          </div>
          {currentUser.role === 'super_admin' && (
            <div className='filter-container'>
              <div className='filter'>
                <select id='cars' name='cars' defaultValue={'null'} onChange={handleShopChange}>
                  <option value='null' disabled>
                    Caută după magazin
                  </option>
                  <option key={'all'} value={''}>
                    Toate
                  </option>
                  {availableGroups.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
        <div className='clients__separator' />

        <div className='clients__container'>
          <div className='clients__subtitle'>
            <h2>Listă clienți</h2>
          </div>
          <div className='clients__table'>
            {clients.length ? (
              <>
                <div className='clients__details--row clients__table--row'>
                  <p className='clients__details--row-header'>ID</p>
                  <p className='clients__details--row-header'>Nume</p>
                  <p className='clients__details--row-header'>Email</p>
                  <p className='clients__details--row-header'>Magazin</p>
                </div>
                {clients?.map((client: Client) => {
                  return (
                    <div
                      key={client.ext_id}
                      className='clients__details--row clients__details--client clients__table--row'
                    >
                      <p className='clients__details--row-info'>{client.ext_id}</p>
                      <p className='clients__details--row-info'>
                        {client.lastname} {client.firstname}
                      </p>
                      <p className='clients__details--row-info'>{client.email}</p>
                      <p className='clients__details--row-info'>{client.group.name}</p>
                    </div>
                  )
                })}
              </>
            ) : (
              <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
            )}
          </div>
          {clients.length ? (
            <PaginationControls
              currentPage={page}
              totalPages={totalPages}
              limit={limit}
              setCurrentPage={(number: any) => setPage(number)}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export default ClientList
