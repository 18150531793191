import React, {useState, useEffect} from 'react'
import './style/EntityUser.scss'
import {getUserById} from '../../../../app/modules/auth/core/_requests_user'
import {Link} from 'react-router-dom'

interface User {
  _id: string
  name: string
  role: string
  email: string
  phone?: string
  group?: {
    _id: string
    name: string
  }
  deposit?: {
    _id: string
    name: string
  }
}

const EntityUser = ({entity}) => {
  const [user, setUser] = useState<User>()

  async function fetchUser() {
    await getUserById(entity).then((res) => {
      setUser(res.data.user)
      console.log('user', res.data.user)
    })
  }

  useEffect(() => {
    fetchUser()
  }, [entity])

  return (
    <>
      {user && (
        <div className='entityUser'>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'>Nume:</p>
            <p className='entityUser__row--description'>{user?.name}</p>
          </div>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'>Rol:</p>
            <p className='entityUser__row--description'>{user?.role}</p>
          </div>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'>Email:</p>
            <p className='entityUser__row--description'>{user?.email}</p>
          </div>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'>Telefon:</p>
            <p className='entityUser__row--description'>{user?.phone}</p>
          </div>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'>Grup:</p>
            <p className='entityUser__row--description'>{user?.group?.name}</p>
          </div>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'>Depozit:</p>
            <p className='entityUser__row--description'>{user?.deposit?.name}</p>
          </div>
          <div className='entityUser__row'>
            <p className='entityUser__row--title'></p>
            <Link
              to={`/edit-user/${user?._id}`}
              className='entityUser__row--description entityUser__row--description-link'
            >
              Editează utilizator
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default EntityUser
