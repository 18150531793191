import React, {useState, useEffect} from 'react'
import './style/EntityGroup.scss'
import {getGroup} from '../../../../app/modules/auth/core/Requests/_requestsGroups'
import {Link} from 'react-router-dom'

interface Group {
  _id: string
  name: string
  store_domain: string
  store_ip: string
  status: string
  public: boolean
  createdAt: string
  logo: {
    fileName: string
    filePath: string
  }
}

const EntityGroup = ({entity}) => {
  const [group, setGroup] = useState<Group>()

  async function fetchGroup() {
    await getGroup(entity).then((res) => {
      setGroup(res?.data?.group)
    })
  }

  function showConvertedDate(dateParam: string) {
    // Create a new Date object with the given parameter
    const date = new Date(dateParam)

    // Get the UTC date and time in the desired format
    const formattedDate = date.toLocaleDateString('ro-RO', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    const formattedTime = date.toLocaleTimeString('ro-RO', {
      timeZone: 'UTC',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    // Return the formatted date and time as a string
    return `${formattedDate} \n @${formattedTime}`
  }

  const groupStatus = (status) => {
    if (status === 'active') return 'Activ'
    if (status === 'disabled') return 'Dezactivat'
    if (status === 'deleted') return 'Șters'
  }

  useEffect(() => {
    fetchGroup()
  }, [entity])

  return (
    <>
      {group && (
        <div className='entityGroup'>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'>Nume grup:</p>
            <p className='entityGroup__row--description'>{group?.name}</p>
          </div>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'>Domeniu:</p>
            <p className='entityGroup__row--description'>{group?.store_domain}</p>
          </div>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'>IP:</p>
            <p className='entityGroup__row--description'>{group?.store_ip}</p>
          </div>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'>Status:</p>
            <p className='entityGroup__row--description'>{groupStatus(group?.status)}</p>
          </div>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'>Public:</p>
            <p className='entityGroup__row--description'>{group?.public ? 'Da' : 'Nu'}</p>
          </div>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'>Data creării:</p>
            <p className='entityGroup__row--description'>{showConvertedDate(group?.createdAt)}</p>
          </div>
          <div className='entityGroup__row'>
            <p className='entityGroup__row--title'></p>
            <Link
              to={`/edit-group/${group?._id}`}
              className='entityGroup__row--description entityGroup__row--description-link'
            >
              Editează grup
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default EntityGroup
