import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getMyDetails} from './Requests/_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import Cookies from 'js-cookie'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

//The initAuthContextPropsState object defines the initial state and default functions for the authentication context.
// This will be the context's initial state, used mainly as a placeholder before actual data gets loaded.
const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

//AuthContext is a React context with the initial state defined above.
const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

// useAuth Hook
// The useAuth hook returns the current context, which gives easy access to auth variables and functions anywhere in
// the React component tree.
const useAuth = () => {
  return useContext(AuthContext)
}

// This component sets up the AuthContext.Provider, making the auth state and functions available to all child
// components. auth and currentUser are state variables. saveAuth updates the auth state and also updates local storage
// using authHelper. logout clears the auth state, the current user, and also removes cookies.
const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    Cookies.remove('token')
    Cookies.remove('refreshToken')
    Cookies.remove('group')
    authHelper.removeAuth()
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

// This component runs some logic when the app initializes. It uses the useAuth hook to get the current authentication
// context.
const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const requestUser = async () => {
    try {
      if (!didRequest.current) {
        // console.log('AuthInit useEffect step 2');

        const data = await getMyDetails()
        // console.log('getMyDetails data ', data);
        if (data.status == 401) {
          // logout();
        }
        if (data) {
          setCurrentUser(data)
        }
      }
    } catch (error) {
      if (!didRequest.current) {
        // console.log('AuthInit useEffect step 3');

        logout()
      }
    } finally {
      setShowSplashScreen(false)
    }
  }

  useEffect(() => {
    // console.log('AuthInit useEffect step 1');

    // @ts-ignore
    if (auth && auth.accessToken) {
      // console.log('AuthInit useEffect step 4 ', auth);

      requestUser()
    } else {
      // console.log('AuthInit useEffect step 5');

      logout()
      setShowSplashScreen(false)
    }

    // Moved the cleanup function to here
    return () => {
      didRequest.current = true
    }
  }, []) // If auth and logout functions don't change, you can keep dependency array empty

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
