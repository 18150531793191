import React, {FC, useEffect, useState} from 'react'
import {ApexOptions} from 'apexcharts'
import axiosConf from '../../app/modules/auth/core/Requests/_axiosInstance'
import {useQuery} from 'react-query'
import ReactApexChart from 'react-apexcharts'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {useAuth} from '../../app/modules/auth'
import './style/Reports.scss'

// @ts-ignore
export const API_URL = process.env.REACT_APP_API_URL

interface ReportParams {
  page?: number
  limit?: number
  action?: string
  entity?: string
  user?: string | number
  affectedId?: string
  message?: string
  code?: string
  group?: string
  dateFrom?: string
  dateTo?: string
  count?: boolean
}

interface areParamsReady {
  dateFrom?: string
  dateTo?: string
  code?: string
  user?: string
}

const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = `0${date.getMonth() + 1}`.slice(-2) // Months are 0-indexed
  const day = `0${date.getDate()}`.slice(-2)
  return `${year}-${month}-${day}`
}

// ------------- CODES -------------
// >>>>>>>>>> ORDER >>>>>>>>>
//  - ORDMAC - Marked as completed
//  - ORDMAF - Marked as finished
//  - ORDRBE - Reserved by employee
//  - ORDCTR - Cancel the reservation
//
// >>>>>>>>>> PICKING ORDER >>>>>>>>>
//  - PORDC - Create PickingOrder with order(s): ...
//  - PORDATC - Added to cart ...
//
// >>>>>>>>>> RECEPTION >>>>>>>>>
//  - RCTC - Create Reception with properties: ...
//  - RCTU - Update Reception, new properties: ...
//  - RCTUD - Upload document(s): ...
//  - RCTDD - Delete document: ...
//  - RCTSP - Set reception products: ...
//  - RCTMAS - Mark as completed
//
// >>>>>>>>>> STOCK AVAILABLE >>>>>>>>>
//  - STASS - Set stock from ... to ... with reason: ...
//  - STASUS - Supply stock: ... items. Old qty: ...
//  - STATS - Transfer ... of ... from ... to ...

// Custom hook for fetching report data
const useReportData = (params: ReportParams, fetch: boolean) => {
  // Function to fetch reports data
  // @ts-ignore
  const fetchReports = ({queryKey}) => {
    // Destructure the queryKey to get the queryParams
    // The first element of queryKey is ignored (hence '_'), and the second element is used
    const [, queryParams] = queryKey

    // Convert the queryParams object into a query string
    const queryString = new URLSearchParams(queryParams).toString()

    console.log('queryString', queryString)

    // Make an HTTP GET request to the API endpoint with the query string
    // and return the response data
    return axiosConf.get(`${API_URL}/logs/statistics?${queryString}`).then((res) => res.data)
  }

  // Use the useQuery hook from React Query to fetch the data
  // 'reportData' is the query key, and params are passed as part of the query key
  // fetchReports is the function to fetch the data
  // Additional options for useQuery can be added as needed
  const {data, error, isLoading} = useQuery(['reportData', params], fetchReports, {
    enabled: fetch,
  })

  let chartDataReports = []
  let xAxisReports = []

  if (data?.data) {
    chartDataReports = data.data.map((dataPoint: any) => dataPoint.count)
    xAxisReports = data.data.map((dataPoint: any) => `${dataPoint.date}`)
  }

  return {chartDataReports, xAxisReports, isLoading, error}
}

const useUsers = () => {
  return useQuery('users', async () => {
    const {data} = await axiosConf.get(`${API_URL}/users`, {
      params: {role: 'user'}, 
    })
    return data.data.users
  })
}

const oneDayInMilliseconds = 24 * 60 * 60 * 1000
const yesterday = new Date(Date.now() - oneDayInMilliseconds)

const Reports: FC = () => {
  const {currentUser} = useAuth()
  const currentUserId = currentUser?.id ? currentUser?.id : ''

  const {data: users, isLoading: isLoadingUsers, error: errorUsers} = useUsers()

  const [startDate, setStartDate] = useState(yesterday)
  const [endDate, setEndDate] = useState(new Date())
  const [selectedCode, setSelectedCode] = useState('')
  const [selectedUser, setSelectedUser] = useState('')

  const reportParams = {
    dateFrom: formatDate(startDate),
    dateTo: formatDate(endDate),
    code: selectedCode,
    user: selectedUser,
  }

  const areParamsReady =
    reportParams.dateFrom !== '' &&
    reportParams.dateTo !== '' &&
    reportParams.code !== '' &&
    reportParams.user !== ''

  // Call useReportData hook
  const {chartDataReports, xAxisReports, isLoading, error} = useReportData(
    reportParams,
    !!reportParams.user && !!reportParams.code && !!reportParams.dateFrom && !!reportParams.dateTo
  )
  console.log('chartDataReports', chartDataReports)

  // const {
  //   data: users,
  //   isLoading: isLoadingUsers,
  //   error: errorUsers,
  // } = useQuery('users', () =>
  //   axiosConf.get(`${API_URL}/users`).then((res) =>
  //     // Filter the users to include only those with role 'user'
  //     res.data.data.users.filter((user: any) => user.role === 'user')
  //   )
  // )
  const codes = [
    {code: 'ORDMAC', description: 'Comanda marcată ca fiind finalizată'},
    {code: 'ORDMAF', description: 'Comanda marcată ca fiind completată'},
    {code: 'ORDRBE', description: 'Comanda rezervată de un angajat'},
    {code: 'ORDCTR', description: 'Anulare rezervare comandă'},
    {code: 'PORDC', description: 'Inițierea procesului de preluare a unei comenzi'},
    {code: 'PORDATC', description: 'Produs adăugat în coșul de cumpărături'},
    {code: 'RCTC', description: 'Înregistrarea unei noi recepții cu caracteristicile specificate'},
    {code: 'RCTU', description: 'Actualizare informații recepție cu noi proprietăți'},
    {code: 'RCTUD', description: 'Încărcare documente recepție'},
    {code: 'RCTDD', description: 'Ștergere documente recepție'},
    {code: 'RCTSP', description: 'Stabilirea produselor pentru recepție'},
    {code: 'RCTMAS', description: 'Recepția a fost marcată ca finalizată'},
    {code: 'STASS', description: 'Ajustare stoc dintr-un motiv specific'},
    {code: 'STASUS', description: 'Suplimentare stoc cu un număr specific de articole'},
    {code: 'STATS', description: 'Transfer de stoc de la un loc la altul'},
  ];
  

  // const codes = [
  //   {code: 'ORDMAC', description: 'Comandă Finalizată'},
  //   {code: 'ORDMAF', description: 'Complet procesată'},
  //   {code: 'ORDRBE', description: 'Rezervată'},
  //   {code: 'ORDCTR', description: 'Rezervare anulată'},
  //   {code: 'PORDC', description: 'Pregătire comandă'},
  //   {code: 'PORDATC', description: 'Adăugat în coș'},
  //   {code: 'RCTC', description: 'Recepție nouă'},
  //   {code: 'RCTU', description: 'Actualizare recepție'},
  //   {code: 'RCTUD', description: 'Document încărcat'},
  //   {code: 'RCTDD', description: 'Document șters'},
  //   {code: 'RCTSP', description: 'Produse recepționate'},
  //   {code: 'RCTMAS', description: 'Recepție finalizată'},
  //   {code: 'STASS', description: 'Actualizare stoc'},
  //   {code: 'STASUS', description: 'Suplimentare stoc'},
  //   {code: 'STATS', description: 'Transfer stoc'},
  // ]

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  //
  // if (error) {
  //   return (
  //     <div className='mt-0 p-0'>
  //       <h3>Error fetching data</h3>
  //     </div>
  //   );
  // }

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {show: false},
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['white'],
      },
      background: {
        enabled: true,
        foreColor: '#000',
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CE8D3B',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45,
        },
      },
    },
    stroke: {
      curve: 'straight',
      colors: ['#CE8D3B'],
    },
    title: {
      text: 'Unitati pe zi',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: '900',
      },
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: xAxisReports,
      tooltip: {enabled: false},
    },
    markers: {colors: ['#ffffff']},
    tooltip: {
      marker: {
        fillColors: ['#CE8D3B'],
      },
    },
  }

  return (
    <>
      <div className='reports'>
        <div className='reports__section'>
          <div className='reports__section--report'>
            <label className='reports__section--report-label'>Tip raport</label>
            <select
              className='reports__section--report-input'
              onChange={(e) => setSelectedCode(e.target.value)}
            >
              <option selected disabled value=''>
                Selecteaza actiune
              </option>
              {codes.map((code) => (
                <option key={code.code} value={code.code}>
                  {code.description}
                </option>
              ))}
            </select>
          </div>
          <div className='reports__section--report'>
            <label className='reports__section--report-label'>Utilizator</label>
            <select
              className='reports__section--report-input'
              value={selectedUser || ''}
              onChange={(e) => setSelectedUser(e.target.value)}
            >
              <option disabled value=''>
                Selecteaza un Utilizator
              </option>
              {users?.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.name}
                </option>
              ))}
            </select>
          </div>
          <div className='reports__section--report'>
            <label className='reports__section--report-label'>Zi început</label>
            <div className='date--full-width'>
              <DatePicker
                className='reports__section--report-input'
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
              />
            </div>
          </div>
          <div className='reports__section--report'>
            <label className='reports__section--report-label'>Zi sfârșit</label>
            <div className='date--full-width'>
              <DatePicker
                className='reports__section--report-input'
                selected={endDate}
                onChange={(date: any) => setEndDate(date)}
              />
            </div>
          </div>
        </div>
        <div className='reports__section'>
          <div className='reports__section--inner'>
            <ReactApexChart
              options={options}
              series={[{name: 'Comenzi', data: chartDataReports}]}
              type='line'
              height={'100%'}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Reports
