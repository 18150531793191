import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router'
import {addNewProduct, addLocalImage} from '../../app/modules/auth/core/Requests/_requestsProduct'
import './style/AddNewProduct.scss'

interface FormData {
  name: string
  sku: string
  priceWt: number | ''
  tva: string
  remotePictures: {fileName: string; filePath: string}[]
  localImages: FileObject[]
  ean: string
  isbn: string
  barcode: string
}

interface Input {
  id: number
  value: string
}
type FileObject = {
  uri: string
  name: string
  type: string
  file: File
}

export function AddNewProduct() {
  const [loading, setLoading] = useState(false)
  const [inputs, setInputs] = useState<Input[]>([{id: 0, value: ''}])
  const [formData, setFormData] = useState<FormData>({
    name: '',
    sku: '',
    priceWt: '',
    tva: '',
    remotePictures: [],
    localImages: [],
    ean: '',
    isbn: '',
    barcode: '',
  })
  const [selectedFiles, setSelectedFiles] = useState<FileObject[]>([])
  const navigate = useNavigate()

  function checkFormRequirements(): boolean {
    return !formData.name || !formData.sku
  }
  useEffect(() => {
    if (!formData.remotePictures.some((picture) => picture.filePath)) {
      setInputs([{id: 0, value: ''}])
    } else {
      const newInputs = formData.remotePictures.map((picture, index) => ({
        id: index,
        value: picture.filePath,
      }))
      setInputs(newInputs)
    }
  }, [formData.remotePictures])

  const Product = {
    name: formData.name,
    sku: formData.sku,
    pricewt: formData.priceWt,
    tva: formData.tva,
    remotePictures: formData.remotePictures,
    localImages: selectedFiles,
    ean: formData.ean,
    isbn: formData.isbn,
    barcode: formData.barcode,
  }
  const handleInputChange = (id: number, value: string) => {
    const newInputs = inputs.map((input) => (input.id === id ? {...input, value} : input))
    setInputs(newInputs)
    const newRemotePictures = newInputs.map((input, index) => ({
      fileName: `Link${index + 1}`,
      filePath: input.value,
    }))
    setFormData({...formData, remotePictures: newRemotePictures})
  }

  const handleAddInput = (event: any) => {
    const newId = inputs.length
    const newInputs = [...inputs, {id: newId, value: ''}]
    setInputs(newInputs)
    event.preventDefault()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({...formData, [event.target.name]: event.target.value})
  }
  function localImages(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.files) {
      const files = e.target.files
      const fileArray: FileObject[] = []
      for (let i = 0; i < files.length; i++) {
        fileArray.push({
          uri: URL.createObjectURL(files[i]),
          name: files[i].name,
          type: files[i].type,
          file: files[i],
        })
      }
      setSelectedFiles(fileArray)
    }
  }

  async function handleAddProduct() {
    setLoading(true)
    try {
      const productResponse = await addNewProduct(
        formData.name,
        formData.sku,
        Number(formData.priceWt),
        formData.remotePictures,
        formData.ean,
        formData.isbn,
        formData.barcode,
        'group'
      )

      console.log('Product creation successful:', productResponse)
      if (productResponse.status === 'success') {
        const productId = productResponse.data.product.id
        const localImageResponse = await addLocalImage(
          productId,
          selectedFiles.map((fileObject) => fileObject.file)
        )

        console.log('Local image upload response:', localImageResponse)
        navigate('/select-deposit')
      } else {
        console.error('Product creation failed:', productResponse)
      }
    } catch (error) {
      console.error('Error in adding a product:', error)
    } finally {
      setLoading(false)
    }
  }

  function handleRemoveImage(index: number): void {
    const newFiles = selectedFiles.filter((_, i) => i !== index)
    setSelectedFiles(newFiles)
  }

  //input remote pictures
  const handleDeleteInput = (event: any, id: number) => {
    event.preventDefault()
    const newInputs = inputs.filter((input) => input.id !== id)
    setInputs(newInputs)
    const newRemotePictures = newInputs.map((input, index) => ({
      fileName: `Link${index + 1}`,
      filePath: input.value,
    }))
    setFormData({...formData, remotePictures: newRemotePictures})
  }

  console.log(JSON.stringify(formData, null, 2))

  return (
    <>
      <div className='products'>
        <div className='products__title'>
          <h1 className='card-title align-items start flex-column mb-0'>Produse</h1>
        </div>
        <div className='products__separator' />
        <div className='products__container'>
          <div className='products__subtitle'>
            <h2>Adaugă produs</h2>
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault()
              handleAddProduct()
            }}
          >
            {/* start product form here */}

            {/* NAME input start */}
            <div className='products__section'>
              <div className='mb-6'>
                <label className='form-label products__input--label'>Nume</label>
                <input
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  type='text'
                  className='form-control products__input'
                  placeholder='Senzor Parcare'
                />
              </div>
              {/* NAME input end */}
              {/* SKU input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>Sku</label>
                <input
                  onChange={handleChange}
                  name='sku'
                  type='text'
                  value={formData.sku}
                  className='form-control products__input'
                  placeholder='CL10877'
                />
              </div>
              {/* SKU input end */}
              {/* PRICE WT input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>Preț fără TVA</label>
                <input
                  onChange={handleChange}
                  value={formData.priceWt}
                  name='priceWt'
                  type='number'
                  className='form-control products__input'
                  placeholder='99.95'
                />
              </div>
              {/* PRICE WT input end */}
              {/* REMOTE Pictures input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>Imagini</label>
                <div>
                  {inputs.map((input, key) => (
                    <div key={input.id}>
                      <label htmlFor={`input-${input.id}`}>Link {key + 1}</label>
                      <div style={{display: 'flex', gap: '10px'}}>
                        <input
                          className='form-control products__input'
                          id={`input-${input.id}`}
                          type='text'
                          name='remotePictures'
                          value={input.value}
                          onChange={(e) => {
                            handleInputChange(input.id, e.target.value)
                          }}
                        />
                        <a
                          href='#'
                          onClick={(event) => handleAddInput(event)}
                          className='products__input--add'
                        >
                          <i className='bi bi-plus-lg p-0' style={{color: 'white'}}></i>
                        </a>

                        {/* {inputs.length > 2 && (
                          <a
                            href='#'
                            onClick={(event) => handleDeleteInput(event, input.id)}
                            className='btn btn-danger removeButton'
                          >
                            <i className='bi bi-dash-lg p-0'></i>
                          </a>
                        )} */}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {formData.remotePictures.length > 0 &&
                formData.remotePictures.some((picture) => picture.filePath) && (
                  <>
                    <label className='form-label products__input--label'>Preview Imagini</label>
                    <div className='products__image-preview'>
                      {formData.remotePictures.map((picture, index) => (
                        <div key={index} className='image-container'>
                          {picture.filePath && (
                            <>
                              <img src={`${picture.filePath}`} alt={`Remote Image ${index}`} />
                              <button
                                type='button'
                                onClick={(event) => handleDeleteInput(event, inputs[index].id)}
                              >
                                &times;
                              </button>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </>
                )}
              {/* REMOTE Pictures input end */}
              {/* local Pictures input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>
                  Introdu o poză din calculatorul tău
                </label>
                <div style={{display: 'flex', gap: '10px'}}>
                  <input
                    type='file'
                    id='myFile'
                    name='filename'
                    onChange={localImages}
                    multiple
                    className='form-control products__input'
                  />
                  {/* <button className='products__input--danger' onClick={handleClear}>
                    Șterge
                  </button> */}
                </div>
                <div className='form-label mt-5 text-gray-600' style={{fontStyle: 'italic'}}>
                  Format suportat: jpg, jpeg, png{' '}
                </div>
                {selectedFiles.length > 0 && (
                  <div className='products__image-preview'>
                    {selectedFiles.map((file, index) => (
                      <div key={index}>
                        <img src={file.uri} alt={`Preview ${index}`} />
                        <button type='button' onClick={() => handleRemoveImage(index)}>
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* local Pictures input end */}
              {/* EAN input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>EAN</label>
                <input
                  onChange={handleChange}
                  value={formData.ean}
                  name='ean'
                  type='text'
                  className='form-control products__input'
                  placeholder='978020137962'
                />
              </div>
              {/* EAN input end */}
              {/* ISBN input start */}
              <div className='mb-6'>
                <label className='form-label products__input--label'>ISBN</label>
                <input
                  onChange={handleChange}
                  value={formData.isbn}
                  name='isbn'
                  type='text'
                  className='form-control products__input'
                  placeholder='9781234567897'
                />
              </div>
              {/* ISBN input end */}
              {/* BARCODE input start */}
              {/* <div className='mb-6'>
                <label className='form-label products__input--label'>Cod de bare</label>
                <input
                  onChange={handleChange}
                  value={formData.barcode}
                  name='barcode'
                  type='text'
                  className='form-control products__input'
                  placeholder='ABC-abc-1234'
                />
              </div> */}
              {/* BARCODE input end */}
            </div>
            {/* begin::Action */}
            <div className='d-grid'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='products__action'
                style={{background: '#CE8D3B'}}
                disabled={checkFormRequirements()}
              >
                {!loading && <span className='indicator-label'>Crează produs</span>}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Așteaptă...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
      </div>
    </>
  )
}
