import React, { useEffect, useState } from 'react'
import './IFrame.css'; 

interface IFrameProps {
  show: boolean;
  depositId: string;
  accessToken: string | undefined;
  onClose: () => void;
  adminStatus: boolean; 
  iframeToken: string; 
}

const IFrame: React.FC<IFrameProps> = ({ show, depositId, accessToken, onClose }) => {
  const [token, setToken] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (show && depositId && accessToken) {
      fetch(`https://api.incom.ai/v1/deposits/${depositId}?token=true`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log('API Response:', data);
        const fetchedToken = data.data.token;
        const adminStatus = data.data.admin; 
        console.log('Fetched Token:', fetchedToken, 'Admin:', adminStatus);
        setToken(fetchedToken);
        setIsAdmin(adminStatus);
      })
      .catch(error => {
        console.error('Error fetching deposit data:', error);
      });
    }
  }, [show, depositId, accessToken]);
  

  const iframeUrl = token ? `https://deposit.incom.ai/${token}` : '';
  // console.log('Deposit Token:', token, 'Admin:', isAdmin, 'IFrame AccessToken:', accessToken);

  if (!show) {
    return null;
  }

  return (
    <div className="custom-modal">
      <div className="modal-IFrame">
        <button onClick={onClose} className="close-button">&times;</button>
        {token ? (
          <iframe src={iframeUrl} frameBorder='0' className="iframe-style"></iframe>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  )
}

export default IFrame
