import axiosConf from './Requests/_axiosInstance'

export const API_URL = process.env.REACT_APP_API_URL
export const GET_USERS = `${API_URL}/users`

export const getAllUsers = async ({page, limit, currentUser}: any) => {
  let url = GET_USERS
  const selectedGroup = localStorage.getItem('group')

  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (currentUser?.role === 'super_admin' && selectedGroup)
    queryParams.append('group', selectedGroup)

  const queryString = queryParams.toString()
  if (queryString) url += `?${queryString}`

  try {
    const response = await axiosConf.get(url)
    console.log('get ALL USERS res', response.data)
    return response.data
  } catch (err) {
    console.log('get ALL USERS err', err)
    return err
  }
}

export const getUserById = async (id: string | undefined) => {
  try {
    const response = await axiosConf.get(`${GET_USERS}/${id}`)
    return response.data
  } catch (err) {
    console.error('Error in getUserById:', err.response ? err.response : err)
    return {error: true, message: err.message}
  }
}
