import React, {useEffect, useState} from 'react'
import './rangeItem.scss'
import {KTSVG} from '../../../_metronic/helpers'

interface val {
  fata: number
  name: string
}

interface RangeItemProps {
  range: {start: {row: number; col: number}; end: {row: number; col: number}; name: string}
  scale: number
  width: number
  height: number
  onDelete: () => void
  onNameChange: (val: val) => void
}

const RangeItemColumn: React.FC<RangeItemProps> = ({
  range,
  scale,
  width,
  height,
  onDelete,
  onNameChange,
}) => {
  const [inputValue, setInputValue] = useState(range.name)
  const [fata, setFata] = useState(1)
  const [levels, setLevels] = useState(1)
  const [debouncedInputValue, setDebouncedInputValue] = useState('')

  let rangeEndRow = range.end.row
  let rangeEndCol = range.end.col

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const handleFataChange = (event: any) => {
    setFata(event.target.value)
  }

  const handleLevelChange = (event: any) => {
    setLevels(event.target.value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue)
      onNameChange({fata: fata, name: inputValue})
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [inputValue, fata, 500])

  return (
    <div className='rangeItem'>
      <input
        className='rangeItem__cell rangeItem__cell--input'
        type='text'
        placeholder='Enter range name'
        value={inputValue}
        onChange={handleInputChange}
      />

      <span className='rangeItem__cell rangeItem__cell--text'>
        De la ({range.start.row}, {range.start.col}) până la ({rangeEndRow}, {rangeEndCol})
      </span>
      <div className='rangeItem__cell'>
        <a onClick={onDelete} className='btn btn-icon btn-danger btn-sm button-left-margin'>
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      </div>
    </div>
  )
}

export default RangeItemColumn
