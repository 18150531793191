import axios from 'axios'
import {GET_USERS} from '../_requests_user'
import axiosConf from './_axiosInstance'
export const API_URL = process.env.REACT_APP_API_URL
export const EDIT_USERS = `${API_URL}/users/{id}`

export const editUsers = async (
  id: string | undefined,
  name: string,
  email: string,
  phone: string,
  deposit: string,
  role: string
) => {
  try {
    const response = await axiosConf.patch(`${GET_USERS}/${id}`, {
      name,
      email,
      phone,
      deposit,
      role,
    })
    return response.data
  } catch (err) {
    console.log('EDIT USER err', err)
    return err
  }
}
