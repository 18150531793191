import React from 'react'
import cardBoardTexture from '../assets/textures/cardboard.jpg'
import * as THREE from 'three'
import { Line, Text } from '@react-three/drei'
import { useRef, useState, useEffect, useCallback } from 'react'
import { useThree } from '@react-three/fiber'
import axios from 'axios'






// Columns
interface ColumnProps {
  id: string
  position: [number, number, number]
  dimensions: [number, number, number]
  color: string
  levels?: number
  name: string
  isRotated?: boolean
  depositId: string;
  onColumnClick: (columnId: string, products: any[], columnName: string) => void;
}

const createLine = (
  start: [number, number, number],
  end: [number, number, number],
  color: string = 'black',
  lineWidth: number = 0.8,
  lineRef?: React.MutableRefObject<any>
): JSX.Element => (
  <Line
    ref={lineRef}
    points={[start, end]}
    color={color}
    lineWidth={lineWidth}
  />
)

// Function to create a cube outline based on dimensions and hover state
const createCubeOutline = (
  dimensions: [number, number, number],
  isHovered: boolean,
  outlineRefs: React.MutableRefObject<any>[]
): JSX.Element => {
  const w = dimensions[0] / 2
  const h = dimensions[1] / 2
  const d = dimensions[2] / 2

  // Define an array to hold all the lines of a cube
  const lines = [
    // Top face
    createLine([-w, h, d], [w, h, d], 'black', 0.8, outlineRefs[0]),
    createLine([w, h, d], [w, h, -d], 'black', 0.8, outlineRefs[1]),
    createLine([w, h, -d], [-w, h, -d], 'black', 0.8, outlineRefs[2]),
    createLine([-w, h, -d], [-w, h, d], 'black', 0.8, outlineRefs[3]),
    // Bottom face
    createLine([-w, -h, d], [w, -h, d], 'black', 0.8, outlineRefs[4]),
    createLine([w, -h, d], [w, -h, -d], 'black', 0.8, outlineRefs[5]),
    createLine([w, -h, -d], [-w, -h, -d], 'black', 0.8, outlineRefs[6]),
    createLine([-w, -h, -d], [-w, -h, d], 'black', 0.8, outlineRefs[7]),
    // Vertical lines
    createLine([-w, h, d], [-w, -h, d], 'black', 0.8, outlineRefs[8]),
    createLine([w, h, d], [w, -h, d], 'black', 0.8, outlineRefs[9]),
    createLine([w, h, -d], [w, -h, -d], 'black', 0.8, outlineRefs[10]),
    createLine([-w, h, -d], [-w, -h, -d], 'black', 0.8, outlineRefs[11]),
  ]

  return <>{lines}</>
}
const texture = new THREE.TextureLoader().load(cardBoardTexture)


const Column: React.FC<ColumnProps> = ({ position, dimensions, color, levels, name, id, depositId, onColumnClick }) => {
  const outlineRefs = useRef(new Array(12).fill(undefined).map(() => React.createRef()))
  const [products, setProducts] = useState([]);
  const [isHovered, setIsHovered] = useState(false)

  const { raycaster, mouse, camera } = useThree(); // useThree hook to get raycaster, mouse, and camera

  useEffect(() => {
    // Effect for applying material color changes on hover
    outlineRefs.current.forEach((ref, index) => {
      if (ref.current) {
        // @ts-ignore
        ref.current.material.color.set(isHovered ? 'green' : 'black');
        // @ts-ignore
        ref.current.material.linewidth = isHovered ? 5 : 0.8;
      }
    });
  }, [isHovered]);


//@ts-ignore
  const handlePointerOver = useCallback((event) => {
    event.stopPropagation();
    const intersects = raycaster.intersectObject(event.object);
    if (intersects.length) setIsHovered(true);
  }, [raycaster]); // Only need raycaster here, mouse and camera are not needed
// @ts-ignore
  const handlePointerOut = useCallback((event) => {
    event.stopPropagation();
    setIsHovered(false);
  }, []);
  const meshRef = useRef<THREE.Mesh>(null);

// e has an any type
// @ts-ignore
//OnClick fucntion, when clicked send an GET api call to the backend by sending column id and deposit id
  const onClick = useCallback((e) => {
    e.stopPropagation();
    console.log(`Deposit Name: ${depositId} Column ${name} clicked with id ${id}`);

    // API call
    axios.get('https://api.incom.ai/v1/products', {
      params: {
        deposit: depositId,
        location: id,
        page: 1,
        limit: 1000,
        hasDetails: true,
        full: true,

      }
    })
      .then(response => {
        const columnProducts = response.data.data.products;
        console.log(`Products in Column ${name}:`, columnProducts);
        onColumnClick(id, columnProducts, name);
      })
      .catch(error => {
        console.error('Error fetching product data', error);
      });

  }, [id, depositId, name, onColumnClick]);

  // Text positions
  const textLeftPosition: [number, number, number] = [
    position[0] - dimensions[0] / 2 -.1, // Left side
    position[1],
    position[2],
  ]
  const textRightPosition: [number, number, number] = [
    position[0] + dimensions[0] / 2 +.1, // Right side
    position[1],
    position[2],
  ]

  // Correctly typed rotations for TypeScript
  const leftTextRotation: [number, number, number] = [0, -Math.PI / 2, 0]
  const rightTextRotation: [number, number, number] = [0, Math.PI / 2, 0]

  return (
    <group>
      <mesh
        receiveShadow
        ref={meshRef}
        position={position}
        onPointerOver={handlePointerOver}
        onPointerOut={handlePointerOut}
        // onClick={onClick}
      >
        <boxGeometry args={dimensions} />
        <meshStandardMaterial map={texture} />
        {createCubeOutline(dimensions, isHovered, outlineRefs.current)}
      </mesh>
      {/* Left face text */}
      <Text
        position={textLeftPosition}
        rotation={leftTextRotation}
        color="black" // text color set to black as default
        fontSize={1}
        anchorX="center"
        anchorY="middle"
      >
        {name}
      </Text>
      {/* Right face text */}
      <Text
        position={textRightPosition}
        rotation={rightTextRotation}
        color="black" // text color set to black as default
        fontSize={1}
        anchorX="center"
        anchorY="middle"
      >
        {name}
      </Text>
    </group>
  )
}

export default Column
