import React from 'react';
import { Html, Line } from "@react-three/drei";


interface ExtrudedPlaneProps {
  position: [number, number, number];
  args: [number, number];
  text: string;
  rotation: [number, number, number];
  onClick?: () => void;
}

interface SegmentProps {
  startPoint: [number, number, number];
  endPoint: [number, number, number];
  color: string;
}

const Segment: React.FC<SegmentProps> = ({ startPoint, endPoint, color }) => (
  <Line
    points={[startPoint, endPoint]}
    color={color}
    lineWidth={2}
    dashed
    dashSize={0}
    gapSize={0}
  />
);
const ExtrudedPlane: React.FC<ExtrudedPlaneProps> = ({ position, args, text, rotation }) => {
  const halfWidth = args[0] / 2;
  const halfHeight = args[1] / 2;
  const segmentLength = 1; // Length of each dash
  const offset = 0.15; // Offset value

  // Function to create segments for a side of the square
  const createSegments = (start: [number, number], end: [number, number], vertical: boolean) => {
    const segments = [];
    let distance = vertical ? end[1] - start[1] : end[0] - start[0];
    let numSegments = Math.floor(Math.abs(distance) / segmentLength);
    let currentPos = start;

    for (let i = 0; i < numSegments; i++) {
      const nextPos = vertical
        ? [start[0], currentPos[1] + (distance > 0 ? segmentLength : -segmentLength)]
        : [currentPos[0] + (distance > 0 ? segmentLength : -segmentLength), start[1]];

      segments.push(
        <Segment
          key={`segment-${i}`}
          startPoint={[currentPos[0], currentPos[1], 0]}
          endPoint={[nextPos[0], nextPos[1], 0]}
          color={i % 2 === 0 ? 'black' : 'yellow'}
        />
      );
      //@ts-ignore
      currentPos = nextPos;
    }
    return segments;
  };

  // Adjust the start and end points of each segment to be offset outward
  return (
    <group position={position} rotation={rotation}>
      {createSegments([-halfWidth - offset, halfHeight + offset], [halfWidth + offset, halfHeight + offset], false)}
      {createSegments([halfWidth + offset, halfHeight + offset], [halfWidth + offset, -halfHeight - offset], true)}
      {createSegments([halfWidth + offset, -halfHeight - offset], [-halfWidth - offset, -halfHeight - offset], false)}
      {createSegments([-halfWidth - offset, -halfHeight - offset], [-halfWidth - offset, halfHeight + offset], true)}
      <Html position={[0, 0, args[1] / 2 + 0.1]} center>
        <div style={{ color: 'white', textAlign: 'center' }}>{text}</div>
      </Html>
    </group>
  );
}
export default ExtrudedPlane;
