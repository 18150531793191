import React, {useState, useEffect} from 'react'
import './style/EntityReception.scss'
import {getReceptionById} from '../../../../app/modules/auth/core/Requests/_requestsReceptions'
import {Link} from 'react-router-dom'

interface Reception {
  _id: string
  createdAt: string
  finished: boolean
  deposit?: {
    _id: string
    name: string
    address: string
  }
  user?: {
    _id: string
    name: string
  }
}

const EntityReception = ({entity}) => {
  const [reception, setReception] = useState<Reception>()

  async function fetchReception() {
    await getReceptionById(entity).then((res) => {
      setReception(res.data.reception)
      console.log('reception', res.data.reception)
    })
  }

  function showConvertedDate(dateParam: string) {
    // Create a new Date object with the given parameter
    const date = new Date(dateParam)

    // Get the UTC date and time in the desired format
    const formattedDate = date.toLocaleDateString('ro-RO', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    const formattedTime = date.toLocaleTimeString('ro-RO', {
      timeZone: 'UTC',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    // Return the formatted date and time as a string
    return `${formattedDate} \n @${formattedTime}`
  }

  useEffect(() => {
    fetchReception()
  }, [entity])

  return (
    <>
      {reception && (
        <div className='entityReception'>
          <div className='entityReception__row'>
            <p className='entityReception__row--title'>Nume utilizator:</p>
            <p className='entityReception__row--description'>{reception?.user?.name}</p>
          </div>
          <div className='entityReception__row'>
            <p className='entityReception__row--title'>Nume depozit:</p>
            <p className='entityReception__row--description'>{reception?.deposit?.name}</p>
          </div>
          <div className='entityReception__row'>
            <p className='entityReception__row--title'>Adresă depozit:</p>
            <p className='entityReception__row--description'>{reception?.deposit?.address}</p>
          </div>
          <div className='entityReception__row'>
            <p className='entityReception__row--title'>Data creării:</p>
            <p className='entityReception__row--description'>
              {showConvertedDate(reception?.createdAt)}
            </p>
          </div>
          <div className='entityReception__row'>
            <p className='entityReception__row--title'></p>
            <Link
              to={`/reception/:${reception?._id}`}
              className='entityReception__row--description entityReception__row--description-link'
            >
              Editează recepție
            </Link>
          </div>
        </div>
      )}
    </>
  )
}

export default EntityReception
