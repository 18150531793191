import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'

const itemClass = 'ms-1 ms-lg-3'

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

export const HOME_URL = process.env.REACT_APP_HOME_URL

const Navbar = () => {
  const {config} = useLayout()
  const {currentUser} = useAuth()


  return (
    <div className='app-navbar flex-shrink-0 gap-5'>
      <div className='d-flex justify-content-center align-items-center'>
        <h3 className='mb-0' style={{fontWeight: 'normal'}}>
        {currentUser && (
    <div>
        Salutare, <span style={{color: '#ce8d3b'}}>{currentUser.name}</span>
    </div>
)}

        </h3>
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img
            src={HOME_URL + currentUser?.profilePicture?.filePath}
            alt={`${currentUser?.name}'s profile`}
            onError={(e) => (e.currentTarget.src = toAbsoluteUrl('/media/avatars/default-user.png'))}
            />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  )
}

export {Navbar}
