import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router'
import {editDeposit, getDepositById} from '../../app/modules/auth/core/Requests/_requestsDeposits'
import CreateShelfSecond from './CreateShelfSecond'
import {useQuery} from 'react-query'
import CreateColumnSecond from './CreateColumnSecond'
import './style/EditDepositSecond.scss'

interface DepositData {
  scale: any
  width: any
  height: any
  name: string
  address: string
  areas: any[]
  shelfs: any[]
}

const EditDepositSecond = () => {
  const {id} = useParams<{id: string}>()
  const depositID = id
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [areaSelected, setAreaSelected] = useState('')
  const [shelfSelected, setShelfSelected] = useState<any>('')
  const [levelSelected, setLevelSelected] = useState<any>('')
  const [shelvs, setShelves] = useState([])
  // console.log('AREA SELECTED', areaSelected)
  const [formData, setFormData] = useState<DepositData>({
    name: '',
    address: '',
    areas: [],
    shelfs: [],
    width: '',
    height: '',
    scale: '',
  })

  const {isLoading, isError, error} = useQuery(['deposit', id], () => getDepositById(id), {
    onSuccess: (data) => {
      // Update formData state with the fetched data
      if (data.status === 'success') {
        setFormData(data.data)
        console.log('useQuery IN EDIT DEPOSIT', data.data)

        // setShelfSelected from a new array from each data.data.areas from prop shelves
        let newShelvs: any = []
        data.data.areas.map((area: any) => {
          area.shelves.map((shelf: any) => {
            newShelvs.push({...shelf, areaName: area.name})
          })
        })
        setShelves(newShelvs)
      }
    },
    onError: (err) => {
      console.log('ERR IN EDIT DEPOSIT', err)
    },
  })

  function checkFormRequirements() {
    if (formData.name == '' || formData.address == '') {
      return true
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormData({...formData, [event.target.name]: event.target.value})
  }

  function handleEditDepositSubmit() {
    editDeposit(depositID, formData.name, formData.address)
      .then((res) => {
        console.log('RES IN UPDATE DEPOSIT', res)
        if (res.status == 'success') {
          navigate(`/deposit:${depositID}`)
        }
      })
      .catch((err) => console.log('ERR IN UPDATE DEPOSIT', err))
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    console.error(error)
    return <div>Error loading deposit data</div>
  }

  // @ts-ignore
  return (
    <div className='editDeposit'>
      {/* NAME input start */}
      <div className='editDeposit__title'>
        <h1>Editare depozit</h1>
      </div>
      <div className='editDeposit__separator' />
      <div className='editDeposit__section--title'>
        <h2>Informații generale</h2>
      </div>
      <div className='editDeposit__section'>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleEditDepositSubmit()
            console.log('FORM DATA ON SUBMIT', formData)
          }}
        >
          <div className='mb-3'>
            <label className='form-label editDeposit__input--label'>Nume</label>
            <input
              name='name'
              value={formData.name}
              onChange={handleChange}
              type='text'
              className='form-control editDeposit__input'
              placeholder='Senzor'
            />
          </div>
          {/* NAME input end */}

          {/* ADDRESS input start */}
          <div className='mb-3'>
            <label className='form-label editDeposit__input--label'>Adresă</label>
            <input
              name='address'
              value={formData.address}
              onChange={handleChange}
              type='text'
              className='form-control editDeposit__input'
              placeholder='Aleea Z, nr Y'
            />
          </div>
          {/* ADDRESS input end */}

          <div className='mb-3'>
            <label className='form-label editDeposit__input--label'>Zonă</label>
            <select
              className='form-select editDeposit__input'
              aria-label='Default select example'
              onChange={(event) => {
                const selectedId = event.target.value
                const selectedArea = formData.areas.find((area) => area._id === selectedId)
                console.log('Selected Area Object', selectedArea)
                setAreaSelected(selectedArea) // Assuming you want to store the whole object
              }}
            >
              <option value=''>Alege Zona</option>
              {formData?.areas &&
                formData?.areas.map((area: any) => {
                  return (
                    <option key={area._id} value={area._id}>
                      {area.name}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className='mb-3'>
            <label className='form-label editDeposit__input--label'>Raft</label>
            <select
              className='form-select editDeposit__input'
              aria-label='Default select example'
              onChange={(event) => {
                const selectedId = event.target.value
                // console.log('shelvs', shelvs)
                const selectedShelv: any = shelvs.find((shelf: any) => shelf?._id === selectedId)
                // console.log('Selected Area Object', selectedShelv)
                setShelfSelected(selectedShelv) // Assuming you want to store the whole object
              }}
            >
              <option value=''>Alege Raft</option>
              {shelvs.length > 0 &&
                shelvs?.map((shelf: any) => {
                  return (
                    <option key={shelf?._id} value={shelf?._id}>
                      {shelf?.areaName + ' - ' + shelf?.name}
                    </option>
                  )
                })}
            </select>
          </div>

          {shelfSelected?.levels && (
            <div className='mb-10'>
              <label className='form-label editDeposit__input--label'>Nivel</label>
              <select
                className='form-select editDeposit__input'
                aria-label='Default select example'
                onChange={(event) => {
                  const selectedId = event.target.value
                  console.log('nivele', shelfSelected?.levels)
                  const selectedLevel: any = shelfSelected?.levels.find(
                    (shelf: any) => shelf?._id === selectedId
                  )
                  console.log('Selected LEVEL Object', selectedLevel)
                  setLevelSelected(selectedLevel) // Assuming you want to store the whole object
                }}
              >
                <option value=''>Alege Nivel</option>
                {shelfSelected.levels.length > 0 &&
                  shelfSelected?.levels?.map((level: any) => {
                    return (
                      <option key={level?._id} value={level?._id}>
                        {level?.name + ' - ' + level?.name}
                      </option>
                    )
                  })}
              </select>
            </div>
          )}
        </form>
      </div>
      {areaSelected && !levelSelected && (
        <CreateShelfSecond
          scaleData={formData.scale}
          heightData={formData.height}
          widthData={formData.width}
          areaData={areaSelected}
          depositId={id}
        />
      )}
      {levelSelected && (
        <CreateColumnSecond
          scaleData={formData.scale}
          heightData={formData.height}
          widthData={formData.width}
          areaData={levelSelected}
          depositId={id}
        />
      )}
    </div>
  )
}

export default EditDepositSecond
