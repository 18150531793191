import axiosConf from './_axiosInstance'

export const API_URL = process.env.REACT_APP_API_URL

export const GROUPS_PUBLIC = `${API_URL}/groups/public`
export const GROUPS_ALL = `${API_URL}/groups`
export const GROUPS_ID = `${API_URL}/groups/{{id}}`
export const GROUPS_USERS = `${API_URL}/groups/{{id}}/users`
export const USERS_ALL = `${API_URL}/users`

interface GroupParams {
  page?: number
  limit?: number
  search?: string
  status?: string
}
interface UsersParams {
  page?: number
  limit?: number
  role?: string
  group?: string
}

export const getGroups = async ({page, limit, search, status}: GroupParams = {}) => {
  let URL = GROUPS_ALL

  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (search) queryParams.append('search', search.toString())
  if (status) queryParams.append('status', status.toString())

  const queryString = queryParams.toString()
  if (queryString) URL += `?${queryString}`

  try {
    const response = await axiosConf.get(URL)
    return response.data
  } catch (err) {
    console.log('GROUPS ENDPOINT err', err)
    return err
  }
}

export const getUsers = async ({page, limit, role, group}: UsersParams = {}) => {
  let URL = USERS_ALL

  // Append query parameters to the URL
  const queryParams = new URLSearchParams()
  if (page) queryParams.append('page', page.toString())
  if (limit) queryParams.append('limit', limit.toString())
  if (role) queryParams.append('role', role.toString())
  if (group) queryParams.append('group', group.toString())

  const queryString = queryParams.toString()
  if (queryString) URL += `?${queryString}`

  try {
    const response = await axiosConf.get(URL)
    return response.data
  } catch (err) {
    console.log('USERS ENDPOINT err', err)
    return err
  }
}

export const getGroup = async (id) => {
  try {
    const response = await axiosConf.get(GROUPS_ID.replace('{{id}}', id))
    return response.data
  } catch (err) {
    console.log('GROUPS_ID ENDPOINT err', err)
  }
}

export const addGroup = async (group) => {
  try {
    const response = await axiosConf.post(GROUPS_ALL, group)
    return response.data
  } catch (err) {
    console.log('addGroup req err', err)
  }
}
export const editGroup = async (group) => {
  try {
    const response = await axiosConf.patch(GROUPS_ID.replace('{{id}}', group.id), group.data)
    return response.data
  } catch (err) {
    console.log('editGroup req err', err)
  }
}
export const deleteGroup = async (group) => {
  try {
    const response = await axiosConf.delete(GROUPS_ID.replace('{{id}}', group))
  } catch (err) {
    console.error(err)
  }
}

export const addGroupLogo = async (logo) => {
  try {
    const response = await axiosConf.put(GROUPS_ID.replace('{{id}}', logo.groupId), logo.image)
    return response.data
  } catch (err) {
    console.log('addGroupLogo req err', err)
  }
}

export const addGroupUsers = async (users) => {
  try {
    const response = await axiosConf.post(
      GROUPS_USERS.replace('{{id}}', users.groupId),
      users.users
    )
  } catch (err) {
    console.log('addGroupUsers req err', err)
  }
}

export const deleteGroupUsers = async (users) => {
  try {
    const response = await axiosConf.delete(GROUPS_USERS.replace('{{id}}', users.groupId), {
      data: users.users,
    })
  } catch (err) {
    console.log('deleteGroupUsers req err', err)
  }
}
