import {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {getOrderById} from '../../app/modules/auth/core/Requests/_requestsOrders'
import './style/OrderDetails.scss'

interface Customer {
  company: string
  email: string
  _id: number
  firstname: string
  lastname: string
}

interface Address {
  address1: string
  address2: string
  alias: string
  city: string
  company: string
  dni: string
  ext_id: number
  ext_id_customer: number
  firstname: string
  lastname: string
  phone: string
  phone_mobile: string
  postcode: string
  vat_number: string
}

interface Order {
  carrier: string
  createdAt: string
  ext_id: number
  payment: string
  reference: string
  total_paid_tax_incl: number
  total_shipping_tax_incl: number
}

interface Products {
  ext_id: number
  product_name: string
  product_qty: number
  product_sku: string
  total_price_tax_incl: number
  unit_price_tax_incl: number
  id: string
}

const OrderDetails = () => {
  const {id} = useParams<{id: string}>()
  const orderID = id

  console.log('id in order details', id)
  const [customer, setCustomer] = useState<Customer>()
  const [deliveryAddress, setDeliveryAddress] = useState<Address>()
  const [invoiceAddress, setInvoiceAddress] = useState<Address>()
  const [orderDetails, setOrderDetails] = useState<Order>()
  const [products, setProducts] = useState<Products[]>()

  useEffect(() => {
    getOrderById(orderID)
      .then((res) => {
        console.log('res in get ORDER by id', res.data)
        setCustomer(res.data.customer)
        setDeliveryAddress(res.data.delivery_address)
        setInvoiceAddress(res.data.invoice_address)
        setProducts(res.data.products)
        setOrderDetails(res.data.order)
      })
      .catch((err) => {
        console.log('ERR IN GET ORDER BY ID', err)
      })
  }, [id])

  function showCompany() {
    if (customer?.company !== undefined || null || '') {
      return customer?.company
    } else return 'test'
  }

  function showConvertedDate(dateParam: string) {
    // Create a new Date object with the given parameter
    const date = new Date(dateParam)

    // Get the UTC date and time in the desired format
    const formattedDate = date.toLocaleDateString('ro-RO', {
      timeZone: 'UTC',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })

    const formattedTime = date.toLocaleTimeString('ro-RO', {
      timeZone: 'UTC',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
    })

    // Return the formatted date and time as a string
    return `${formattedDate} \n Oră:${formattedTime}`
  }

  return (
    <div className='card-body fw-bold rounded order'>
      {/* begin::Table container */}
      <div className='order__title'>
        <h1>Detalii Comandă #{orderDetails?.reference && orderDetails?.reference}</h1>
      </div>
      <div className='order__separator' />
      <div className='order__container'>
        <div className='order__subtitle'>
          <h2>Client</h2>
        </div>
        <div className='order__details'>
          {(customer?.firstname || customer?.lastname) && (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Nume: </p>
              <p className='order__details--row-description'>
                {customer.firstname} {customer.lastname}
              </p>
            </div>
          )}
          {customer?.email && (
            <div className='order__details--row'>
              <p>Email: </p>
              <p>{customer.email}</p>
            </div>
          )}
          {customer?.company && (
            <div className='order__details--row'>
              <p>Companie: </p>
              <p>{customer.company}</p>
            </div>
          )}
        </div>
      </div>
      <div className='order__flex'>
        <div className='order__container order__flex--inner'>
          <div className='order__subtitle'>
            <h2>Adresă de livrare</h2>
          </div>
          <div className='order__details'>
            {(deliveryAddress?.firstname || deliveryAddress?.lastname) && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Nume: </p>
                <p className='order__details--row-description'>
                  {deliveryAddress.firstname} {deliveryAddress.lastname}
                </p>
              </div>
            )}
            {deliveryAddress?.company && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Companie: </p>
                <p className='order__details--row-description'>{deliveryAddress.company}</p>
              </div>
            )}
            {deliveryAddress?.vat_number && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>CUI: </p>
                <p className='order__details--row-description'>{deliveryAddress.vat_number}</p>
              </div>
            )}
            {deliveryAddress?.dni && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Nr. Înmatriculare: </p>
                <p className='order__details--row-description'>{deliveryAddress.dni}</p>
              </div>
            )}
            {deliveryAddress?.address1 && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>
                  Adresă ({deliveryAddress?.alias && deliveryAddress.alias}):
                </p>
                <p className='order__details--row-description'>{deliveryAddress.address1}</p>
              </div>
            )}
            {deliveryAddress?.address2 && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Adresă Secundară: </p>
                <p className='order__details--row-description'>{deliveryAddress.address2}</p>
              </div>
            )}
            {deliveryAddress?.city && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Oraș: </p>
                <p className='order__details--row-description'>{deliveryAddress.city}</p>
              </div>
            )}
            {deliveryAddress?.phone && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Telefon: </p>
                <p className='order__details--row-description'>{deliveryAddress.phone}</p>
              </div>
            )}
            {deliveryAddress?.postcode && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Cod Poștal: </p>
                <p className='order__details--row-description'>{deliveryAddress.postcode}</p>
              </div>
            )}
          </div>
        </div>
        <div className='order__container order__flex--inner'>
          <div className='order__subtitle'>
            <h2>Adresă de facturare</h2>
          </div>
          <div className='order__details'>
            {(invoiceAddress?.firstname || invoiceAddress?.lastname) && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Nume: </p>
                <p className='order__details--row-description'>
                  {invoiceAddress.firstname} {invoiceAddress.lastname}
                </p>
              </div>
            )}
            {invoiceAddress?.company && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Companie: </p>
                <p className='order__details--row-description'>{invoiceAddress.company}</p>
              </div>
            )}
            {invoiceAddress?.vat_number && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>CUI: </p>
                <p className='order__details--row-description'>{invoiceAddress.vat_number}</p>
              </div>
            )}
            {invoiceAddress?.dni && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Nr. Înmatriculare: </p>
                <p className='order__details--row-description'>{invoiceAddress.dni}</p>
              </div>
            )}
            {invoiceAddress?.address1 && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>
                  Adresă ({invoiceAddress?.alias && invoiceAddress.alias}):
                </p>
                <p className='order__details--row-description'>{invoiceAddress.address1}</p>
              </div>
            )}
            {invoiceAddress?.address2 && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Adresă Secundară: </p>
                <p className='order__details--row-description'>{invoiceAddress.address2}</p>
              </div>
            )}
            {invoiceAddress?.city && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Oraș: </p>
                <p className='order__details--row-description'>{invoiceAddress.city}</p>
              </div>
            )}
            {invoiceAddress?.phone && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Telefon: </p>
                <p className='order__details--row-description'>{invoiceAddress.phone}</p>
              </div>
            )}
            {invoiceAddress?.postcode && (
              <div className='order__details--row'>
                <p className='order__details--row-title'>Cod Poștal: </p>
                <p className='order__details--row-description'>{invoiceAddress.postcode}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='order__container'>
        <div className='order__subtitle'>
          <h2>Produse Comandate</h2>
        </div>
        <div className='order__table'>
          <div className='order__details--row order__table--row'>
            <p className='order__details--row-header'>Produs</p>
            <p className='order__details--row-header'>Preț unitate</p>
            <p className='order__details--row-header'>Cantitate</p>
            <p className='order__details--row-header'>Preț total</p>
          </div>
          {products?.map((product: Products) => {
            return (
              <div
                key={product.product_sku}
                className='order__details--row order__details--product order__table--row'
              >
                <div className='order__details--row-info'>
                  <p className='order__table--description'>{product?.product_name}</p>
                  <p className='order__table--description-secondary'>{product?.product_sku}</p>
                </div>
                <p className='order__details--row-info'>{product?.unit_price_tax_incl} lei</p>
                <p className='order__details--row-info'>{product?.product_qty}</p>
                <p className='order__details--row-info'>{product?.total_price_tax_incl} lei</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className='order__container'>
        <div className='order__subtitle'>
          <h2>Comandă</h2>
        </div>
        <div className='order__details'>
          {orderDetails?.reference && (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Referință comandă: </p>
              <p className='order__details--row-description'>{orderDetails?.reference}</p>
            </div>
          )}
          {orderDetails?.createdAt !== undefined ? (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Data: </p>
              <p className='order__details--row-description'>
                {showConvertedDate(orderDetails?.createdAt)}
              </p>
            </div>
          ) : (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Dată: </p>
              <p className='order__details--row-description'>-</p>
            </div>
          )}
          {orderDetails?.carrier ? (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Curier: </p>
              <p className='order__details--row-description'>{orderDetails?.carrier}</p>
            </div>
          ) : (
            ''
          )}
          {orderDetails?.payment ? (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Metodă de plată: </p>
              <p className='order__details--row-description'>{orderDetails?.payment}</p>
            </div>
          ) : (
            ''
          )}
          {orderDetails?.total_shipping_tax_incl ? (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Preț transport: </p>
              <p className='order__details--row-description'>
                {orderDetails?.total_shipping_tax_incl} lei
              </p>
            </div>
          ) : (
            ''
          )}
          {orderDetails?.total_paid_tax_incl ? (
            <div className='order__details--row'>
              <p className='order__details--row-title'>Preț total: </p>
              <p className='order__details--row-description'>
                {orderDetails?.total_paid_tax_incl} lei
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderDetails
