import React, {ReactNode} from 'react'
import ReactDOM from 'react-dom'
import {CloseButton} from 'react-bootstrap'

interface ModalDepositsProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}
const ModalDeposits: React.FC<ModalDepositsProps> = ({isOpen, onClose, children}) => {
  if (!isOpen) return null

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          // Existing styles
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          padding: '20px',
          borderRadius: '20px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',

          // Added styles for positioning
          alignSelf: 'flex-start',
          marginTop: '80px',
          marginLeft: '-820px',
        }}
      >
        <CloseButton
          onClick={onClose}
          style={{
            // Existing styles
            alignSelf: 'flex-end',
            marginBottom: '10px',
            cursor: 'pointer',
            marginLeft: 'auto', // Align to the right
          }}
        />
        {children}
      </div>
    </div>,
    document.body
  )
}

export default ModalDeposits
