import React from "react";

// Levels
interface LevelProps {
  position: [number, number, number];
  dimensions: [number, number, number];
  color: string;
  rotation?: [number, number, number]; // Optional rotation property
}

const Level: React.FC<LevelProps> = ({
                                       position = [0, 0, 0],
                                       dimensions,
                                       color,
                                       rotation = [Math.PI / 2, Math.PI, 0],
                                     }) => {
  return (
    <mesh receiveShadow position={position} rotation={rotation}>
      <boxGeometry args={[dimensions[0], dimensions[2], dimensions[1] + 0.1]} />
      <meshBasicMaterial color={color} />
    </mesh>
  );
};

export default Level;
