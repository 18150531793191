import React, {FC} from 'react'
import {ReceptionList} from './ReceptionList'

const Receptii: FC = () => {
  return (
    <>
      {/* begin::Row */}
      <div>
        <div>
          <ReceptionList className='card-xl-stretch mb-xl-8' />
        </div>
      </div>
    </>
  )
}

export {Receptii}
