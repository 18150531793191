/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useState, useEffect} from 'react'
import {getAllLogs} from '../../app/modules/auth/core/Requests/_requests_logs'
import PaginationControls from '../components/PaginationControls'
import {LogsModal} from './Modal/LogsModal'
import {KTSVG} from '../../_metronic/helpers'
import {useAuth} from '../../app/modules/auth'
import {debounce} from 'lodash'
import DatePicker from 'react-datepicker'
import './style/LogsList.scss'

export const API_URL = process.env.REACT_APP_API_URL

interface data {
  id: string
  action: string
  entity: string
  user: {
    name: string
  }
  message: string
  createdAt: string
}

type Props = {
  className: string
}

// Define the hook as a function
const useLogs = (initialPage = 1, initialLimit = 30) => {
  const {currentUser} = useAuth()

  const today = new Date(Date.now())

  // State for logs, current page, limit, and total pages
  const [logs, setLogs] = useState([])
  const [page, setPage] = useState(initialPage)
  const [limit, setLimit] = useState(initialLimit)
  const [totalPages, setTotalPages] = useState(0)

  const [filterAction, setFilterAction] = useState()
  const [filterEntity, setFilterEntity] = useState()
  const [filterDateFrom, setFilterDateFrom] = useState()
  const [filterDateTo, setFilterDateTo] = useState()

  function handleFilterAction(action: any) {
    setFilterAction(action)
    if (page > 1) setPage(1)
  }
  function handleFilterEntity(entity: any) {
    setFilterEntity(entity)
    if (page > 1) setPage(1)
  }
  function handleFilterDateFrom(dateFrom: any) {
    setFilterDateFrom(dateFrom)
  }
  function handleFilterDateTo(dateTo: any) {
    setFilterDateTo(dateTo)
  }

  function filterDateFormat(date) {
    if (date) {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0')
      const formattedDate = `${year}-${month}-${day}`
      return formattedDate
    } else {
      return
    }
  }

  // Effect to fetch logs when the page or limit changes
  useEffect(() => {
    const fetchLogs = async () => {
      const filters = {
        page: page,
        limit: limit,
        currentUser: currentUser,
        action: filterAction,
        entity: filterEntity,
        dateFrom: filterDateFormat(filterDateFrom),
        dateTo: filterDateFormat(filterDateTo),
      }
      try {
        const res = await getAllLogs(filters)
        setTotalPages(res.data.pages)
        setLogs(res.data.logs)
      } catch (error) {
        console.error('Failed to fetch logs:', error)
      }
    }

    fetchLogs()
  }, [page, limit, filterAction, filterEntity, filterDateFrom, filterDateTo])

  // Return everything needed to manage logs in a component
  return {
    logs,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    filterDateFrom,
    filterDateTo,
    handleFilterAction,
    handleFilterEntity,
    handleFilterDateFrom,
    handleFilterDateTo,
  }
}

const LogsList: React.FC<Props> = ({className}) => {
  const {
    logs,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    filterDateFrom,
    filterDateTo,
    handleFilterAction,
    handleFilterEntity,
    handleFilterDateFrom,
    handleFilterDateTo,
  } = useLogs()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedLog, setSelectedLog] = useState<data | null>(null)

  const handleRowClick = (log: data) => {
    setSelectedLog(log)
    setIsModalOpen(true)
  }

  const debouncedHandleFilterAction = debounce(handleFilterAction, 500)
  const debouncedHandleFilterEntity = debounce(handleFilterEntity, 500)
  const debouncedHandleFilterDateFrom = debounce(handleFilterDateFrom, 500)
  const debouncedHandleFilterDateTo = debounce(handleFilterDateTo, 500)

  return (
    <>
      <div className='logs'>
        <div className='logs__title'>
          <h1 className='card-title align-items-start flex-column mb-0'>Raport angajați</h1>
        </div>
        <div className='logs__separator' />
        <div className='filters'>
          <h2 className='filters-title'>Filtre</h2>
          <div className='filter-container'>
            <div className='filter'>
              <select
                defaultValue={'null'}
                name='filter-action'
                onChange={(event) => debouncedHandleFilterAction(event.target.value)}
              >
                <option value='null' disabled>
                  Tip acțiune
                </option>
                <option value='' className='p-5'>
                  Orice acțiune
                </option>
                <option value='add' className='p-5'>
                  Add
                </option>
                <option value='update' className='p-5'>
                  Update
                </option>
                <option value='delete' className='p-5'>
                  Delete
                </option>
                <option value='transfer' className='p-5'>
                  Transfer
                </option>
              </select>
            </div>
          </div>
          <div className='filter-container'>
            <div className='filter'>
              <select
                defaultValue={'null'}
                name='filter-action'
                onChange={(event) => debouncedHandleFilterEntity(event.target.value)}
              >
                <option value='null' disabled>
                  Tip entitate
                </option>
                <option value='' className='p-5'>
                  Orice entitate
                </option>
                <option value='Deposit' className='p-5'>
                  Depozit
                </option>
                <option value='Group' className='p-5'>
                  Grup
                </option>
                <option value='Location' className='p-5'>
                  Locație
                </option>
                <option value='Order' className='p-5'>
                  Comandă
                </option>
                <option value='PickingOrder' className='p-5'>
                  Preluare comandă
                </option>
                <option value='Product' className='p-5'>
                  Produs
                </option>
                <option value='Reception' className='p-5'>
                  Recepție
                </option>
                <option value='StockAvailable' className='p-5'>
                  Stoc
                </option>
                <option value='User' className='p-5'>
                  Utilizator
                </option>
              </select>
            </div>
          </div>
          <div className='filter-container'>
            <div className='filter'>
              <DatePicker
                className='reports__section--report-input'
                selected={filterDateFrom}
                placeholderText='Dată început'
                onChange={(date: any) => handleFilterDateFrom(date)}
              />
            </div>
          </div>
          <div className='filter-container'>
            <div className='filter'>
              <DatePicker
                className='reports__section--report-input'
                selected={filterDateTo}
                placeholderText='Dată sfârșit'
                onChange={(date: any) => handleFilterDateTo(date)}
              />
            </div>
          </div>
        </div>
        <div className='logs__separator' />
        <div className='logs__container'>
          <div className='logs__subtitle'>
            <h2>Listă intrări</h2>
          </div>
          <div className='logs__table'>
            {logs.length ? (
              <>
                <div className='logs__details--row logs__table--row'>
                  <p className='logs__details--row-header'>Utilizator</p>
                  <p className='logs__details--row-header'>Entitate</p>
                  <p className='logs__details--row-header'>Data</p>
                  <p className='logs__details--row-header'>Acțiune</p>
                </div>
                {logs.map((log: data, index) => {
                  return (
                    <div
                      key={`${index}-${log.createdAt}`}
                      className='logs__details--row logs__details--log logs__table--row'
                      onClick={() => handleRowClick(log)}
                    >
                      <p className='logs__details--row-info'>{log.user?.name}</p>
                      <p className='logs__details--row-info'>{log.entity}</p>
                      <p className='logs__details--row-info'>
                        {new Date(log.createdAt).toLocaleDateString()}
                      </p>
                      <div className='logs__details--row-info'>
                        <button
                          onClick={() => handleRowClick(log)}
                          className='btn btn-icon btn-light btn-sm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/eye.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <p style={{padding: '2rem', margin: '0'}}>Nu au fost găsite rezultate.</p>
            )}
          </div>
          <PaginationControls
            currentPage={page}
            totalPages={totalPages}
            limit={limit}
            setCurrentPage={(number: any) => setPage(number)}
          />
        </div>
        <LogsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} log={selectedLog} />
      </div>
    </>
  )
}

export {LogsList}
